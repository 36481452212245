import axios from "axios";
import strings from "../utils/strings";
import AccessTokenExp from "./AccessTokenExp";

let accessToken = AccessTokenExp.accessToken;

const BASE_URL = strings.API_ROUTE;

export const axiosPublic = axios.create({
  baseURL: BASE_URL,
});

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  // headers: { "Content-Type": "application/json", Athorization: accessToken },
  // headers: { Athorization: "Bearer" + accessToken },
  withCredentials: true,
});

//  axiosPrivate = axios.interceptors.response.use(resp=>resp, async error=>{
//     if (error.response.status === 401) {
//         const response = await axios.post('refresh', {}, {withCredentials:true});

//         if (response.status === 200){
//             axios.defaults.headers.common['Authorization'] = `Bearer ${response.data['token']}`

//             return axios(error.config);
//         }
//     }
//     return error;
// });
