import React from "react";
import ReactDOM from "react-dom/client";
import "../src/main.scss";
import App from "./App";
import { createStore } from "redux";
import { Provider } from "react-redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { CookiesProvider } from "react-cookie";
import store from "./Store/store";
const root = ReactDOM.createRoot(document.getElementById("root"));
//store

root.render(
  <CookiesProvider>
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>
  </CookiesProvider>
);
