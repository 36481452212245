import React, { useState } from "react";
import { useEffect } from "react";
import DataTable from "react-data-table-component";
import { coursePurchaseList } from "../../networkcalls/purchasedCourse";
import Invoice from "./Invoice";
import jspdf from "jspdf";
import { useSelector } from "react-redux";

const StudentCoursePurchased = () => {
  let userData = useSelector((state) => state.userData.userinfo);
  const [data, setData] = useState();
  const [invoice, setInvoiceData] = useState();
  let studentId = userData?._id;
  useEffect(() => {
    coursePurchaseList({ studentId: studentId }).then(
      (res) => {
        console.log(res.data);
        setData(res.data);
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);
  const handleDownload = async (
    reciptId,
    coursePrice,
    coursePriceGST,
    transactionDate,
    courseTitle,
    paymentStatus
  ) => {
    setInvoiceData({
      reciptId,
      coursePrice,
      coursePriceGST,
      transactionDate,
      courseTitle,
      paymentStatus,
    });

    const doc = new jspdf();
    var elementHTML = document.getElementById("content");
    doc.html(elementHTML, {
      callback: function (doc) {
        // Save the PDF
        doc.save("Invoice.pdf");
      },
      x: -20,
      y: 0,
      width: 193, //target width in the PDF document
      windowWidth: 730, //window width in CSS pixels
    });
  };

  const columns = [
    {
      name: "Course Title",
      selector: (row) => row.courseTitle,
    },
    {
      name: "Transaction Id",
      selector: (row) => row.receiptId,
    },
    {
      name: "Transaction date",
      selector: (row) => row.tranactionDate,
    },
    {
      name: "Course Price (incl. GST)",

      selector: (row) => row.coursePriceIncludingGST + row.coursePrice,
    },
    {
      name: "Download Invoice",
      selector: (row) => (
        <>
          {}
          <button
            className="btn btn-success btn-sm"
            onClick={() =>
              handleDownload(
                row.receiptId,
                row.coursePrice,
                row.coursePriceIncludingGST,
                row.tranactionDate,
                row.courseTitle,
                row.paymentStatus
              )
            }
          >
            {" "}
            Downoad
          </button>
        </>
      ),
    },
  ];

  return (
    <div className="mt-3">
      <div className="m-2">
        <h4 className="mb-3">Invoices (course purchased)</h4>
        <DataTable
          className="border border-dark"
          columns={columns}
          data={data}
          highlightOnHover
          pagination
          paginationServer
          paginationRowsPerPageOptions={[]}
        />
        <div className="d-none">
          <Invoice id="content" invoice={invoice} />
        </div>
      </div>
    </div>
  );
};

export default StudentCoursePurchased;
