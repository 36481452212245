import React, { useEffect, useState } from "react";
import { fetchAdmin, UpdateAdminProfile } from "../../networkcalls/UsersApi";
import { useCookies } from "react-cookie";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import userImage from "../../images/R.jfif";
import strings from "../../utils/strings";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function AdminEditProfile() {
  const [cookie, setCookie] = useCookies();
  let storedCookie = cookie["UserData"];
  let userData = useSelector((state) => state.userData.userinfo);
  let AdminId = userData._id;
  console.log(AdminId);
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    gender: "",
    dob: "",
    email: "",
    phone: "",
  });

  const [fnameErrorMessage, setFnameErrorMessage] = useState();
  const [lnameErrorMessage, setLnameErrorMessage] = useState();
  const [genderErrorMessage, setGenderErrorMessage] = useState();
  const [emailErrorMessage, setEmailErrorMessage] = useState();
  const [phoneErrorMessage, setPhoneErrorMessage] = useState();
  const [passwordErrorMessage, setPasswordErrorMessage] = useState();
  const [cpasswordErrorMessage, setCpasswordErrorMessage] = useState();
  const [imgErr, setImgErr] = useState();

  //profileImage hook to store image in database
  const [profileImage, setProfileImage] = useState();
  //profilePic hook to change state of image
  const image = userImage;
  const [profilePic, setProfilePic] = useState(image);

  const setImage = (e) => {
    const file = e.target.files[0];
    if ((file?.type?.slice(6) === "png") | "jpg" | "jpeg") {
      setImgErr("");
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (reader.result != null) {
          setProfilePic(reader.result.toString());
        }
      };
      setProfileImage(e.target.files[0]);
    } else {
      setImgErr("Please select image of png, jpg, jpeg format");
    }
  };

  function handleChange(e) {
    e.preventDefault();
    if (!/^[a-zA-Z ]*$/.test(data.firstName)) {
      setFnameErrorMessage("Only alphabets are allowed!");
    }
    if (!/^[a-zA-Z ]*$/.test(data.lastName)) {
      setLnameErrorMessage("Only alphabets are allowed!");
    }
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
  }

  const onUpdate = async (e) => {
    e.preventDefault();

    let validate = true;

    if (data.firstName === "") {
      setFnameErrorMessage("Please Enter First Name");
    }

    if (data.lastName === "") {
      setLnameErrorMessage("Please Enter Last Name");
    }
    if (data.gender === "") {
      setGenderErrorMessage("Please select Gender");
    }
    if (data.phone === "") {
      setPhoneErrorMessage("Please Enter Phone No.");
    }
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(data.email)) {
      setEmailErrorMessage("Please Enter valid Email");
      validate = false;
    }
    if (
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
        password
      )
    ) {
      setPasswordErrorMessage(
        "The password must be 8 characters long, at least one uppercase letter & lowercase letter & one digit & one special character"
      );
      validate = false;
    }
    if (
      !/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(data.phone)
    ) {
      setPhoneErrorMessage("Please Enter Valid Phone No!");
    }

    if (data.profileImage !== null || data.profilePic === null) {
      validate = true;
    }

    if (confirmPassword != password) {
      setCpasswordErrorMessage("Password Doesn't Match!!");
      validate = false;
    }

    if (validate) {
      setFnameErrorMessage("");
      setLnameErrorMessage("");
      setEmailErrorMessage("");
      setPasswordErrorMessage("");
      setPhoneErrorMessage("");
      setCpasswordErrorMessage("");
      setImgErr("");
      var formData = new FormData();
      formData.append("firstName", data.firstName);
      formData.append("lastName", data.lastName);
      formData.append("gender", data.gender);
      formData.append("dob", data.dob);
      formData.append("email", data.email);
      formData.append("phone", data.phone);
      formData.append("profilePic", profileImage);

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      UpdateAdminProfile(AdminId, formData, config).then((res) => {
        console.log("data updated" + JSON.stringify(res.data));
      });

      toast.success("Profile Updated Successfully!");
    }
  };

  useEffect(() => {
    fetchAdmin(AdminId).then(
      (res) => {
        console.log(res)
        setData(res.data);
        if (res.data.profilePic !== null) {
          setProfilePic(strings.IMG_URL + "users/" + res.data.profilePic);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }, [cookie["user"]]);

  return (
    <div>
      {/* <img src={data.profilePic} alt="profilepic" /> */}
      <div className="container">
        <div className="row">
          <div className="col-lg-6 ">
            <h4 className="mt-2 mb-2 ml-2">Admin Profile</h4>
            <form action="" className="form-group card p-4">
              <lable>First Name</lable>
              <input
                id="firstName"
                type="text"
                value={data.firstName}
                className="form-control"
                onChange={(e) => handleChange(e)}
                placeholder="Enter First Name"
                autoComplete="off"
              />
              <span className="text-danger">{fnameErrorMessage}</span>
              <br />
              <lable>Last Name</lable>
              <input
                id="lastName"
                type="text"
                value={data.lastName}
                className="form-control"
                onChange={(e) => handleChange(e)}
                placeholder="EnterLast Name"
                autoComplete="off"
              />
              <span className="text-danger">{lnameErrorMessage}</span>
              <br />
              <lable>Gender</lable>
              {/* <div>
                <input
                  type="radio"
                  value="male"
                  checked={data.gender === "male"}
                  onChange={(e) => handleChange(e)}
                  id="gender"
                />
                Male
              </div>
              <div>
                <input
                  type="radio"
                  value="female"
                  checked={data.gender === "female"}
                  onChange={(e) => handleChange(e)}
                  id="gender"
                />{" "}
                Female
              </div>
              <div>
                <input
                  type="radio"
                  value="transgender"
                  checked={data.gender === "transgender"}
                  onChange={(e) => handleChange(e)}
                  id="gender"
                />{" "}
                Transgender
              </div> */}
              <select
                className="form-control form-control-sm"
                value={data.gender}
                onChange={(e) => handleChange(e)}
                id="gender"
              >
                <option selsected>select</option>
                <option value="male" selsected>
                  Male
                </option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>
              <span className="text-danger">{genderErrorMessage}</span>
              <br />
              <lable>DOB</lable>
              <input
                id="dob"
                type="date"
                className="form-control text-box single-line"
                value={data.dob.slice(0, 10)}
                onChange={(e) => handleChange(e)}
                autoComplete="off"
              />{" "}
              <br />
              <lable>Email</lable>
              <input
                id="email"
                type="text"
                className="form-control"
                value={data.email}
                placeholder="Enter Email address"
                onChange={(e) => handleChange(e)}
                autoComplete="off"
              />
              <span className="text-danger">{emailErrorMessage}</span>
              <br />
              <lable>Phone</lable>
              <input
                id="phone"
                type="number"
                value={data.phone}
                onChange={(e) => handleChange(e)}
                className="form-control"
                placeholder="Enter Phone No."
                autoComplete="off"
              />
              <span className="text-danger">{phoneErrorMessage}</span>
              <br />
              {/* Password is out of data due to encryption */}
              <lable>Password</lable>
              <input
                id="password"
                type="password"
                className="form-control"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                placeholder="Enter Password"
                autoComplete="off"
              />
              <span className="text-danger">{passwordErrorMessage}</span>
              <br />
              <lable>Confirm Password</lable>
              <input
                id="confirmpasswod"
                type="password"
                className="form-control"
                value={confirmPassword}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                }}
                placeholder="Enter confirm Password"
                autocomplete="off"
              />
              <span className="text-danger">{cpasswordErrorMessage}</span>
              <br />
              <lable>Profile Pic</lable>&nbsp;
              <br />
              <br />
              <img
                src={profilePic}
                alt={profilePic}
                style={{ width: "150px", height: "150px" }}
              />
              <br />
              <br />
              <input
                id="profilePic"
                onChange={setImage}
                type="file"
                className="form-control"
                placeholder="choose file"
                accept="image/*"
              />
              <br />
              <span className="text-danger">{imgErr}</span>
              <div className=" mb-3">
                <input
                  id=""
                  type="button"
                  className="btn btn-primary text-center"
                  onClick={(e) => {
                    onUpdate(e);
                  }}
                  value="Update"
                />
                <ToastContainer />
              </div>
            </form>
          </div>
          <div className="col-lg-6"></div>
        </div>
      </div>
    </div>
  );
}

export default AdminEditProfile;
