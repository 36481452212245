import { axiosPrivate, axiosPublic } from "./axios";

export const fetchMyEvents = async (id) => {
  const res = await axiosPrivate.get(`/events/tutor/schedule/${id}`);
  return res.data;
};

export const fetchAllCalendarData = async () => {
  const res = await axiosPrivate.get(`/events/schedule/`);
  return res.data;
};

export const Book_a_class = async (postData) => {
  const res = await axiosPrivate.post(`/events/sudent/bookaclass`, postData);
  return res.data;
}