import React from 'react'
import { Link } from 'react-router-dom';
import BrandLogo from "../images/logo.png";

function HeaderWithLogo() {
    return (
        <header>
            <nav className='navbar navbar-expand-lg navbar-light'>
                <Link className="navbar-brand" to="/">
                    <img src={BrandLogo} alt="brand logo" style={{ height: "35px" }} />
                </Link>
            </nav>
        </header>
    )
}

export default HeaderWithLogo