import { axiosPrivate, axiosPublic } from "./axios";

export const Api_updateProfile = async (data) => {
    const res = await axiosPrivate.post(`/users/updateprofile`, data);
    return res.data;
};


export const Api_updatePassword = async (data) => {
    const res = await axiosPrivate.post(`/users/updatepassword`, data);
    return res.data;
};