import React, { useRef, useEffect } from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { IoMdNotificationsOutline } from "react-icons/io";
import strings from "../utils/strings";
// import useAuth from "../hooks/useAuth";
import { useCookies } from "react-cookie";
import profileImg from "../images/face.jpg";
import user from "../images/user.png";
import { useSelector } from "react-redux";

function Header({ handleActiveMenu, activeMenu }) {
  // const { auth, setAuth } = useAuth();
  let userData = useSelector((state) => state.userData.userinfo);
  const [cookie, setCookie] = useCookies();
  const dropDownMenu = useRef();
  const dropdown = useRef();
  const navigate = useNavigate();

  let firstName = userData?.firstName;
  let lastName = userData?.lastName;
  // console.log("header", firstName, lastName);

  let profileImg = userData?.profilePic;

  // console.log(profileImg);
  const toggleDropdownMenu = (event) => {
    dropDownMenu.current.classList.toggle("active");
    dropdown.current.focus();
  };
  const handleLogout = (e) => {
    e.preventDefault();
    localStorage.clear();
    sessionStorage.setItem("userRole", null);
    navigate("/login", { replace: true });
  };

  const handleDropdown = (e) => {
    if (e.target.matches("[data-link]"))
      e.target.closest("[data-dropdown]").classList.remove("active");

    const isDropdownBtn = e.target.matches("[data-dropdown-btn]");
    if (!isDropdownBtn && e.target.closest("[data-dropdown]") != null) {
      return;
    }

    let currentDropdown;
    if (isDropdownBtn) {
      currentDropdown = e.target.closest("[data-dropdown]");
      currentDropdown.classList.toggle("active");
    }
    document.querySelectorAll("[data-dropdown].active").forEach((dropdown) => {
      if (dropdown === currentDropdown) return;
      dropdown.classList.remove("active");
    });
  };

  useEffect(() => {
    window.addEventListener("click", handleDropdown);
    // cleanup this component
    return () => {
      window.removeEventListener("click", handleDropdown);
    };
  }, []);

  return (
    <header
      className="header x-wrapper d-flex align-items-center "
      style={{ backgroundColor: "#faf8ff" }}
    >
      <nav nav className="navbar w-100">
        <div className="d-flex align-items-center justify-content-center">
          <div className="menu-toggler">
            <span onClick={handleActiveMenu} className="">
              {activeMenu ? (
                <i className="bi bi-arrow-left"></i>
              ) : (
                <i className="bi bi-list"></i>
              )}
            </span>
          </div>
          <div className="welcome-text text d-none d-sm-block ml-3 ml-md-5 ">
            <h1 className=" welcome-text-main m-0">
              Good Morning,{" "}
              <span className="text-black fw-normal">
                {userData?.firstName}
              </span>
            </h1>
            <p className="welcome-text-sub m-0 ">
              Welcome to{" "}
              <span className="text-primary fw-bold">Atomic Brain</span>
            </p>
          </div>
        </div>
        <ul className="d-flex align-items-center">
          <li className="dropdown" data-dropdown>
            <a href="#" className="fs-4 mr-3 link" data-dropdown-btn>
              <IoMdNotificationsOutline />
            </a>
            <div className="dropdown_menu">Dropdown content</div>
          </li>
          <li className="dropdown" data-dropdown>
            <a
              href="#"
              className="avatar d-inline-block profile link"
              data-dropdown-btn
            >
              {profileImg ? (
                <>
                  <img
                    className="rounded-circle"
                    src={strings.IMG_URL + "users/" + profileImg}
                    style={{
                      width: "40px",
                      height: "40px",
                      borderRadius: "50%",
                    }}
                    alt="profile"
                  />
                </>
              ) : (
                <>
                  <img
                    className="rounded-circle"
                    src={user}
                    style={{
                      width: "40px",
                      height: "40px",
                      borderRadius: "50%",
                    }}
                    alt="profile"
                  />
                </>
              )}
            </a>
            <div
              className="dropdown_menu"
              style={{
                width: "200px",
              }}
            >
              <h6>
                {firstName}&nbsp;{lastName}
                <br />
                <span className="fw-normal">{userData?.role}</span>
              </h6>
              <ul>
                <li className="">
                  <i className="bi bi-person icon-md mr-2"></i>{" "}
                  <Link data-link to={`/dashboard/${userData?.role}/myprofile`}>
                    My Profile
                  </Link>
                </li>
                <li className="">
                  <i className="bi bi-pencil-square icon-md mr-2"></i>{" "}
                  <Link data-link to={`/dashboard/${userData?.role}/editprofile`}>
                    Edit Profile
                  </Link>
                </li>

                <li className="">
                  <div className="d-block">
                    <i className="bi bi-box-arrow-left icon-md mr-2"></i>
                    <Link onClick={handleLogout}>Logout</Link>
                  </div>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
