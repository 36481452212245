import React from "react";
import { useFormik } from "formik";
import { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
// import useAuth from "../hooks/useAuth";
import illustration_signin from "../images/illustration_signin.svg";
import HeaderWithLogo from "../components/HeaderWithLogo";
import AlertBox from "../utils/AlertBox";
import { Api_Signin } from "../networkcalls/AuthApi";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { checkLogin } from "../Store/reducer/userReducer";

function LoginPage() {
  const [cookies, setCookie] = useCookies(["access_token", "refresh_token"]);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [objAlert, setObjAlert] = useState({
    alertVariant: "",
    title: "",
    text: "",
  });

  const LOGIN_URL = "/login";

  const navigate = useNavigate();
  const location = useLocation();
  let from = location.state?.from?.pathname || "/";
  const dispatch = useDispatch();

  const loginUser = async (values) => {
    // console.log(values);
    setLoading(true);
    await Api_Signin({ username: values.email, password: values.password })
      .then(async (res) => {
        // console.log(res);
        setShow(true);
        if (res.err === 200) {
          setObjAlert({
            title: "Success",
            text: "You are logged in",
            alertVariant: "success",
          });
          const userData = res.userData;
          const accessToken = res.accessToken;
          await localStorage.setItem("accessToken", accessToken);
          await dispatch(checkLogin(accessToken));
          from =
            location.state?.from?.pathname ||
            `/dashboard/${userData.role}/main`;

          sessionStorage.setItem("userRole", JSON.stringify(userData.role));
          // setAuth({ userData, accessToken });
          let expires = new Date();
          expires.setTime(expires.getTime() + 24 * 60 * 60);
          setCookie("UserData", userData, { path: "/", expires });

          // setAuth({ accessToken, userData });
          navigate(from, { replace: true });
        } else if (res.err === 300) {
          setLoading(false);
          setObjAlert({
            title: "Failed",
            text: res.message,
            alertVariant: "warning",
          });
        } else {
          setLoading(false);
          setObjAlert({
            title: "Failed",
            text: res.message,
            alertVariant: "danger",
          });
        }
      })
      .catch((ex) => {
        setLoading(false);
        setShow(true);
        setObjAlert({
          title: "Failed",
          text: ex.message,
          alertVariant: "danger",
        });
      });
  };
  const validate = (values) => {
    const errors = {};
    if (!values.password) errors.password = "Required";
    else if (
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/i.test(
        values.password
      )
    )
      errors.password = "seq";
    if (!values.email) errors.email = "Required";
    else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email))
      errors.email = "Invalid email address";
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validate,
    onSubmit: loginUser,
  });

  return (
    <div className="container-fluid">
      <div className="container">
        <HeaderWithLogo />

        {/* login content */}
        <div className="login-main py-3 login-register">
          <div className="row align-items-center justify-content-between h-100">
            {/* Image/Fullfill */}
            <div className="col-md-5 col-lg-6 d-sm-none d-md-block pr-5">
              <img
                className="img-fluid"
                src={illustration_signin}
                alt="sign-up svg"
              />
            </div>

            {/* Login Form */}
            <div className="col-md-7 col-lg-6 py-3">
              <div className="pb-4">
                <h3 className="fs-3 fw-normal">Sign In</h3>
              </div>
              <form noValidate onSubmit={formik.handleSubmit}>
                {show ? (
                  <AlertBox
                    alertVariant={objAlert.alertVariant}
                    alertTitle={objAlert.title}
                    alertText={objAlert.text}
                  />
                ) : (
                  <></>
                )}

                <div className="form-floating mb-3">
                  <input
                    type="email"
                    name="email"
                    value={formik.values.email}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    className="form-control"
                    id="floatingInput"
                    placeholder="name@example.com"
                  />
                  <label htmlFor="floatingInput">Email address</label>
                  {formik.touched.email && formik.errors.email && (
                    <div className="form-error-msg text-danger">
                      <span>{formik.errors.email}</span>
                    </div>
                  )}
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="password"
                    name="password"
                    value={formik.values.password}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    className="form-control"
                    id="floatingPassword"
                    placeholder="Password"
                  />
                  <span className="text-danger">{errorMessage}</span>
                  <label htmlFor="floatingPassword">Password</label>
                  {formik.touched.password &&
                    formik.errors.password &&
                    formik.errors.password !== "seq" ? (
                    <div className="form-error-msg text-danger">
                      <span>{formik.errors.password}</span>
                    </div>
                  ) : null}
                  <span
                    className={` d-inline-block mx-1 ${formik.touched.password &&
                      formik.errors.password === "seq"
                      ? "text-danger"
                      : "text-muted"
                      }`}
                  >
                    Passwords must be combination of upper-case letter,
                    lower-case letter, numbers and special characters. Minimum 8
                    chraters long.
                  </span>
                </div>
                <div className="form-check mb-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckDefault"
                  >
                    Remember me
                  </label>
                </div>

                {/* submit btn */}
                <div className="mb-3">
                  <button
                    type="submit"
                    className="btn btn-primary d-block py-2 w-100"
                  >
                    {loading ? "Please wait " : "Sign-In"}
                  </button>
                </div>

                <div className="form-text text-center">
                  Dont have an account ? <Link to="/register">Create New</Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
