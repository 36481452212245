import { axiosPrivate } from "./axios";


export const fetchTutorChargesById = async(tutorId)=>{
    const res = await axiosPrivate.post("/admin/tutorcharges/fetchTutorCharges", {"tutorId":tutorId});
    return res.data;
}

export const saveAndUpdateTutorCharges = async(data)=>{
    const res =await axiosPrivate.post("/admin/tutorcharges/saveandupdate", data);
    return res.data;
}
