import {
  FaRegListAlt,
  FaUserGraduate,
  FaUserTie,
  FaUserCircle,
  FaCalendar,
  Fa,
  FaUserAltSlash,
  FaUserEdit,
  FaChalkboardTeacher,
} from "react-icons/fa";
import {
  MdClass,
  MdOutlineDashboard,
  MdOutlineDashboardCustomize,
  MdOutlineSubject,
} from "react-icons/md";
import { AiFillBank, AiOutlineSchedule } from "react-icons/ai";
import { BiPurchaseTagAlt } from "react-icons/bi";
import { CgProfile } from "react-icons/cg";
import { SiAccenture } from "react-icons/si";
import { BsCalendarCheck } from "react-icons/bs";
export const AdminLinks = [
  {
    key: "Dashboard",
    title: "Dashboard",
    path: "/admin/main",
    icon: <MdOutlineDashboardCustomize />,
  },
  {
    key: "Calendar",
    title: "Calendar",
    path: "/admin/calendar",
    icon: <AiOutlineSchedule />,
  },
  {
    key: "Courses",
    title: "Courses",
    path: "/admin/course/list",
    icon: <MdOutlineSubject />,
  },
  {
    key: "Tutors",
    title: "Tutors",
    path: "/admin/tutor/list",
    icon: <FaChalkboardTeacher />,
  },
  {
    key: "Students",
    title: "Students",
    path: "/admin/student/list",
    icon: <FaUserGraduate />,
  },
  {
    key: "Course Tutor List",
    title: "Course Tutor List",
    path: "/admin/coursetutor/list",
    icon: <FaUserEdit />,
  },
  {
    key: "Courses Purchased",
    title: "Course Purchased",
    path: "/admin/coursepurchasedbystudent",
    icon: <BiPurchaseTagAlt />,
  },
];

export const TutorLinks = [
  {
    key: "Dashboard",
    title: "Dashboard",
    path: "/tutor/main",
    icon: <MdOutlineDashboardCustomize />,
  },
  {
    key: "RecursiveCalendar",
    title: "Schedule Calendar",
    path: "/tutor/schedule",
    icon: <AiOutlineSchedule />,
  },
  {
    key: "MyCalendar",
    title: "My Calendar",
    path: "/tutor/mycalendar",
    icon: <BsCalendarCheck />,
  },
  {
    key: "Profile",
    title: "Profile",
    path: "/tutor/myprofile",
    icon: <CgProfile />,
  },
  {
    key: "CourseSold",
    title: "Course Sold",
    path: "/tutor/coursesold",
    icon: <BiPurchaseTagAlt />,
  },
  {
    key: "Banking Details",
    title: "Banking Details",
    path: "/tutor/bankingdetails",
    icon: <AiFillBank />,
  },
];

export const StudentLinks = [
  {
    key: "Dashboard",
    title: "Dashboard",
    path: "/student/main",
    icon: <MdOutlineDashboardCustomize />,
  },
  {
    key: "Schedule",
    title: "Class Schedule",
    path: "/student/schedule",
    icon: <AiOutlineSchedule />,
  },
  {
    key: "Courses",
    title: "Courses",
    path: "/student/course/list",
    icon: <MdOutlineSubject />,
  },
  {
    key: "Profile",
    title: "Profile",
    path: "/student/myprofile",
    icon: <CgProfile />,
  },
  {
    key: "Course Purchased",
    title: "Course Purchased",
    path: "/student/coursepurchased",
    icon: <BiPurchaseTagAlt />,
  },
  {
    key: "Banking Details",
    title: "Banking Details",
    path: "/student/bankingdetails",
    icon: <AiFillBank />,
  },
  {
    key: "Class Booking",
    title: "Class Booking",
    path: "/student/classbooking",
    icon: <MdClass />,
  },
];
