import React, { Fragment } from "react";
import Divider from "../Divider";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Autoplay, EffectFade } from "swiper";
import { Image } from "react-bootstrap";
import { importAddImages } from "../../utils/importImages";
import { Link } from "react-router-dom";
import { FreeMode } from "swiper";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import 'swiper/css/effect-fade';



const images = importAddImages(require.context('../../images/student', false, /\.(png|jpe?g|svg)$/));
const addLinks = Object.entries(images).map((image, key) => {
    let fimg = {
        key: key,
        name: image[0],
        path: image[1],
    }
    return fimg;
})

const classesData = [
    {
        classCount: 101,
        classTitle: "Physics",
        classDesc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Tempora enim in, corrupti est esse sed atque quo sint voluptatum soluta."
    }, {
        classCount: 102,
        classTitle: "Chemistry",
        classDesc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Tempora enim in, corrupti est esse sed atque quo sint voluptatum soluta."
    }, {
        classCount: 103,
        classTitle: "Math",
        classDesc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Tempora enim in, corrupti est esse sed atque quo sint voluptatum soluta."
    }, {
        classCount: 104,
        classTitle: "100 days of Code",
        classDesc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Tempora enim in, corrupti est esse sed atque quo sint voluptatum soluta."
    }, {
        classCount: 105,
        classTitle: "C/CPP",
        classDesc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Tempora enim in, corrupti est esse sed atque quo sint voluptatum soluta."
    }, {
        classCount: 106,
        classTitle: "Social Sciense",
        classDesc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Tempora enim in, corrupti est esse sed atque quo sint voluptatum soluta."
    }
];
function StudentDashboard() {

    return (
        <>
            <div className="student-banner mt-3">
                {/* <h2 className="fs-4 text-muted py-5">Dashboard</h2> */}
                <div className=" mx-0 row gap-3 flex-wrap-reverse flex-lg-nowrap">
                    <div className="border rounded-3 d-flex flex-column justify-content-between col-lg-5 md-shadow p-3">
                        <div>
                            <h3 className="fs-3 text-dark">Session Code</h3>
                            <small className="text-muted d-inline-block mb-3">Monday, 30 April 2022 | 10:00 AM - 11:00 AM</small>
                            <h4 className="fs-4 mb-2">Inwrad Management</h4>
                            <p className="text-wrap mb-4 text-brand">E-commerce App Development in React JS - Full Course</p>
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="">
                                <small className="text-primary">Tutor</small>
                                <h6 className="fs-6">Jonny Biaze</h6>
                            </div>
                            <Link className="btn-sm btn-primary" to={'#'}>Join Class</Link>
                        </div>
                    </div>

                    <div className="adds-wrapper col-lg-7 rounded-3">
                        <Swiper
                            // spaceBetween={30}
                            centeredSlides={true}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: true,
                            }}
                            pagination={{
                                clickable: true,
                            }}
                            // navigation={true}
                            modules={[Autoplay, Pagination, Navigation, EffectFade]}
                            effect="fade"
                            className="adds-slider"
                        >
                            {
                                addLinks.map((link, key) => {
                                    return <SwiperSlide key={key}>
                                        <div className="h-100 w-100 rounded-3" key={key}>
                                            {/* <Image src={link.path} alt={link.name} rounded /> */}
                                            <img src={link.path} alt={link.name} className="rounded-3" />
                                        </div>
                                    </SwiperSlide>
                                })
                            }
                        </Swiper>
                    </div>
                </div>
            </div>
            <div className="d-flex align-items-center mt-5 justify-content-between">
                <h2 className="fs-4 text-muted">Classes</h2>
                <Link className="text-primary">Book Now ?</Link>
            </div>
            <div className="classes">
                <div className="classes-desc border-bottom p-3">
                    <div className="row gap-3">
                        <div className=" col d-flex align-items-center">
                            <h1 className="fs-3 mr-2">300</h1>
                            <small className="text-muted">Total classes</small>
                        </div>
                        <div className=" col d-flex align-items-center">
                            <h1 className="fs-3 mr-2">145</h1>
                            <div>
                                <small className="text-muted d-block">Completed</small>
                                <small className="text-primary fw-bold d-block">Earned 2020 points</small>
                            </div>
                        </div>
                        <div className=" col d-flex align-items-center">
                            <h1 className="fs-3 mr-2">145</h1>
                            <div>
                                <small className="text-muted d-block">Remaining</small>
                                <small className="text-primary fw-bold d-block">Earned 127 points</small>
                            </div>
                        </div>
                        <div className=" col-auto d-flex align-items-center">
                            <h1 className="fs-3 mr-2">14</h1>
                            <small className="text-muted">Credit lefts</small>
                        </div>
                    </div>
                </div>
                <div className="py-3 ">
                    <div className="mb-3 d-flex d-flex align-items-center justify-content-between">
                        <h2 className="fs-4 text-muted">Upcoming classes</h2>
                        <div className="ctc-slider d-flex align-items-center">
                            <button className="link-btn">Prev</button>
                            <span className="d-inline-block mx-1">|</span>
                            <button className="link-btn">Next</button>
                        </div>
                    </div>

                    <Swiper
                        slidesPerView={4}
                        spaceBetween={20}
                        slidesPerGroup={1}
                        loop={true}
                        loopFillGroupWithBlank={true}
                        pagination={{
                            clickable: true,
                        }}
                        navigation={true}
                        modules={[Pagination, Navigation, Autoplay]}
                        className="classes-slider"
                        breakpoints={
                            {
                                0: {
                                    slidesPerView: 1,
                                    spaceBetween: 10
                                },
                                660: {
                                    slidesPerView: 2,
                                    spaceBetween: 10
                                },

                                1024: {
                                    slidesPerView: 3,
                                    spaceBetween: 15
                                },

                                1200: {
                                    slidesPerView: 4,
                                    spaceBetween: 20
                                },
                            }
                        }
                    >
                        {
                            classesData.map((classItem, key) => {
                                return <SwiperSlide>
                                    <div className="h-100 w-100 rounded-3 p-2 class md-shadow bg-blue-off">
                                        <div className="class-head  mb-2 p-2">
                                            <h6 className="fs-5 mb-1">Session Code</h6>
                                            <small className="text-muted d-inline-block mb-2">30th Nov, Tuesday <br />07:00pm - 08:30pm</small>

                                        </div>
                                        <div className="class-body p-2">
                                            <div className="text-primary">
                                                <p>Inwrad return <br /> Manegement...</p>
                                            </div>
                                        </div>
                                        <button className="btn w-100 mt-3 p-2">Class details</button>
                                    </div>
                                </SwiperSlide>

                            })
                        }
                    </Swiper>
                </div>
            </div>
        </>

    );
}

export default StudentDashboard;
