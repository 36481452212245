import React, { useEffect } from "react";
import Sidebar from "../components/sidebar/Sidebar";
import MainContent from "../components/MainContent";
// import useAuth from "../hooks/useAuth";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

function Dashboard() {
  // const { auth } = useAuth();
  let userData = useSelector((state) => state.userData.userinfo);
  const location = useLocation();
  const navigate = useNavigate();
  const from = location.state?.from?.pathname || "dashboard";

  useEffect(() => {
    // console.log("in Dashboard useEffect")
    if (!userData) navigate("/login", { replace: true });
  }, []);

  return (
    <>
      <Sidebar />
      <MainContent />
    </>
  );
}

export default Dashboard;
