import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "bootstrap-icons/font/bootstrap-icons.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "swiper/css";
import "./App.css";
import { LayoutPrivider } from "./context/LayoutContext";
import ProtectedRoutes from "./utils/ProtectedRoute";
import LoginPage from "./pages/LoginPage";
import LandingPage from "./pages/LandingPage";
import Dashboard from "./pages/Dashboard";
import NotFoundPage from "./pages/NotFoundPage";
import UnautherizedPage from "./pages/UnautherizedPage";
import MainBody from "./pages/MainBody";
import Main from "./components/Admin/dashboard/Main.jsx";
import CourseList from "./components/Admin/courses/CourseList";
import CourseAdd from "./components/Admin/courses/CourseAdd";
import StudentList from "./components/Admin/students/StudentList";
import Calendar from "./components/Admin/calendar/Calendar";
import UpdatePassword from "./components/UpdatePassword";
import TutorList from "./components/Admin/tutors/TutorList";
import CourceView from "./components/Admin/courses/CourseView";
import SyllabusList from "./components/Admin/syllabus/SyllabusList";
import CourseEdit from "./components/Admin/courses/CourseEdit";
import TutorDashboard from "./components/tutor/TutorDashboard";
import StudentDashboard from "./components/student/StudentDashboard";
import StudentClassSchedule from "./components/student/StudentClassSchedule";
import TutorCalendar from "./components/tutor/TutorCalendar";
import TutorSchedule from "./components/tutor/TutorSchedule";
import TutorProfile from "./components/tutor/TutorEditProfile";
import RegisterPage from "./pages/RegisterPage";
import Verify from "./pages/Verify";
import StudentCourseList from "./components/student/StudentCourseList";
import StudentCourseDetail from "./components/student/StudentCourseDetail";
import { useDispatch, useSelector } from "react-redux";
// import useAuth from "./hooks/useAuth";
import TutorPenaltyCharges from "./components/Admin/tutors/TutorPenaltyCharges";
import CourseTutorList from "./components/Admin/course tutor list/CourseTutorList";
import EditCourseTutor from "./components/Admin/course tutor list/EditCourseTutor";
import AddCourseTutor from "./components/Admin/course tutor list/AddCourseTutor";
import StudentCoursePurchased from "./components/student/StudentCoursePurchased";
import SoldCourses from "./components/tutor/SoldCourses";
import CoursePurchasedByStudent from "./components/Admin/CoursePurchasedByStudent";
import TutorView from "./components/Admin/tutors/TutorView";
import TutorEdit from "./components/Admin/tutors/TutorEdit";
import StudentEdit from "./components/Admin/students/StudentEdit";
import StudentView from "./components/Admin/students/StudentView";
import Bankinformation from "./components/student/BankingDetailes";
import RequireAuth from "./components/requireAuth";

import { checkLogin } from "./Store/reducer/userReducer";
import ClassBooking from "./components/student/ClassBooking";
import StudentMyProfile from "./components/student/StudentMyProfile";
import StudentEditProfile from "./components/student/StudentEditProfile";
import TutorMyProfile from "./components/tutor/TutorMyProfile";
import TutorEditProfile from "./components/tutor/TutorEditProfile";
import AdminEditProfile from "./components/Admin/AdminEditProfile";

function App({ allowedRoles }) {
  return (
    <div className="App">
      <BrowserRouter>
        {/* <Route path="updatepassword" element={<UpdatePassword />} /> */}{" "}
        <Routes>
          {/* public pages */}
          <Route path="/" element={<LandingPage />} />
          <Route path="login" element={<LoginPage />} />
          <Route path="register" element={<RegisterPage />} />
          <Route path="verify" element={<Verify />} />
          <Route path="unautherized" element={<UnautherizedPage />} />

          {/* pages needs to be protected */}
          <Route
            path="dashboard"
            element={
              // <RequireAuth>
              <LayoutPrivider>
                <Dashboard />
              </LayoutPrivider>
              // </RequireAuth>
            }
          >
            <Route element={<ProtectedRoutes allowedRoles={["admin"]} />}>
              <Route
                path="admin/main"
                element={
                  <RequireAuth>
                    <MainBody>
                      <Main />
                    </MainBody>
                  </RequireAuth>
                }
              />
              <Route
                path="admin/editprofile"
                element={
                  <RequireAuth>
                    <MainBody>
                      <AdminEditProfile />
                    </MainBody>
                  </RequireAuth>
                }
              />
              <Route
                path="admin/calendar"
                element={
                  <RequireAuth>
                    <MainBody>
                      <Calendar />
                    </MainBody>
                  </RequireAuth>
                }
              />
              <Route
                path="admin/updatepassword"
                element={
                  <RequireAuth>
                    <MainBody>
                      <UpdatePassword />
                    </MainBody>
                  </RequireAuth>
                }
              />
              <Route
                path="admin/student/list"
                element={
                  <RequireAuth>
                    <MainBody>
                      <StudentList />
                    </MainBody>
                  </RequireAuth>
                }
              />
              <Route
                path="admin/course/list"
                element={
                  <RequireAuth>
                    <MainBody>
                      <CourseList />
                    </MainBody>
                  </RequireAuth>
                }
              />
              <Route
                path="admin/course/add"
                element={
                  <RequireAuth>
                    <MainBody>
                      <CourseAdd />
                    </MainBody>
                  </RequireAuth>
                }
              />
              <Route
                path="admin/course/edit/:id"
                element={
                  <RequireAuth>
                    <MainBody>
                      <CourseEdit />
                    </MainBody>
                  </RequireAuth>
                }
              />
              <Route
                path="admin/course/view/:id"
                element={
                  <RequireAuth>
                    <MainBody>
                      <CourceView />
                    </MainBody>
                  </RequireAuth>
                }
              />
              <Route
                path="admin/course/:id/syllabus"
                element={
                  <RequireAuth>
                    <MainBody>
                      <SyllabusList />
                    </MainBody>
                  </RequireAuth>
                }
              />
              <Route
                path="admin/tutor/list"
                element={
                  <RequireAuth>
                    <MainBody>
                      <TutorList />
                    </MainBody>
                  </RequireAuth>
                }
              />
              <Route
                path="admin/tutor/charges"
                element={
                  <RequireAuth>
                    <MainBody>
                      <TutorPenaltyCharges />
                    </MainBody>
                  </RequireAuth>
                }
              />
              <Route
                path="admin/coursetutor/list"
                element={
                  <RequireAuth>
                    <MainBody>
                      <CourseTutorList />
                    </MainBody>
                  </RequireAuth>
                }
              />
              <Route
                path="admin/coursetutor/edit"
                element={
                  <RequireAuth>
                    <MainBody>
                      <EditCourseTutor />
                    </MainBody>
                  </RequireAuth>
                }
              />
              <Route
                path="admin/coursetutor/add"
                element={
                  <RequireAuth>
                    <MainBody>
                      <AddCourseTutor />
                    </MainBody>
                  </RequireAuth>
                }
              />
              <Route
                path="/dashboard/admin/tutor/view/:tutorid"
                element={
                  <RequireAuth>
                    <MainBody>
                      <TutorView />
                    </MainBody>
                  </RequireAuth>
                }
              />
              <Route
                path="/dashboard/admin/tutor/edit/:tutorid"
                element={
                  <RequireAuth>
                    <MainBody>
                      <TutorEdit />
                    </MainBody>
                  </RequireAuth>
                }
              />
              <Route
                path="/dashboard/admin/student/view/:studentid"
                element={
                  <RequireAuth>
                    <MainBody>
                      <StudentView />
                    </MainBody>
                  </RequireAuth>
                }
              />
              <Route
                path="/dashboard/admin/student/edit/:studentid"
                element={
                  <RequireAuth>
                    <MainBody>
                      <StudentEdit />
                    </MainBody>
                  </RequireAuth>
                }
              />
              <Route
                path="admin/coursepurchasedbystudent"
                element={
                  <RequireAuth>
                    <MainBody>
                      <CoursePurchasedByStudent />
                    </MainBody>
                  </RequireAuth>
                }
              />
              {/* <Route path="admin/update" element={<UpdateProfile />} /> */}
            </Route>
            <Route element={<ProtectedRoutes allowedRoles={["tutor"]} />}>
              {/* <Route path="tutor/main" element={<MainBody><Main /></MainBody>} />
                            <Route path="tutor/mycalendar" element={<MainBody><Calender /></MainBody>} />
                            <Route path="tutor/schedule" element={<MainBody><Schedule /></MainBody>} />
                            <Route path="tutor/profile" element={<MainBody><TutorProfile /></MainBody>} /> */}

              <Route
                path="tutor/main"
                element={
                  <RequireAuth>
                    <MainBody>
                      <TutorDashboard />
                    </MainBody>
                  </RequireAuth>
                }
              />
              <Route
                path="tutor/mycalendar"
                element={
                  <RequireAuth>
                    <MainBody>
                      <TutorCalendar />
                    </MainBody>
                  </RequireAuth>
                }
              />
              <Route
                path="tutor/schedule"
                element={
                  <RequireAuth>
                    <MainBody>
                      <TutorSchedule />
                    </MainBody>
                  </RequireAuth>
                }
              />
              <Route
                path="tutor/editprofile"
                element={
                  <RequireAuth>
                    <MainBody>
                      <TutorEditProfile />
                    </MainBody>
                  </RequireAuth>
                }
              />
              <Route
                path="tutor/myprofile"
                element={
                  <RequireAuth>
                    <MainBody>
                      <TutorMyProfile />
                    </MainBody>
                  </RequireAuth>
                }
              />
              {/* <Route path="update" element={<UpdateProfile />} /> */}
              {/* <Route path="updatepassword" element={<UpdatePassword />} /> */}

              <Route
                path="tutor/coursesold"
                element={
                  <RequireAuth>
                    <MainBody>
                      <SoldCourses />
                    </MainBody>
                  </RequireAuth>
                }
              />
              <Route
                path="tutor/bankingdetails"
                element={
                  <RequireAuth>
                    <MainBody>
                      <Bankinformation />
                    </MainBody>
                  </RequireAuth>
                }
              />
              {/* <Route path="update" element={<UpdateProfile />} /> */}
              {/* <Route path="updatepassword" element={<UpdatePassword />} /> */}
            </Route>
            <Route element={<ProtectedRoutes allowedRoles={["student"]} />}>
              <Route
                path="student/main"
                element={
                  <RequireAuth>
                    <MainBody>
                      <StudentDashboard />
                    </MainBody>
                  </RequireAuth>
                }
              />
              <Route
                path="student/editprofile"
                element={
                  <RequireAuth>
                    <MainBody>
                      <StudentEditProfile />
                    </MainBody>
                  </RequireAuth>
                }
              />
              <Route
                path="student/myprofile"
                element={
                  <RequireAuth>
                    <MainBody>
                      <StudentMyProfile />
                    </MainBody>
                  </RequireAuth>
                }
              />
              <Route
                path="student/schedule"
                element={
                  <RequireAuth>
                    <MainBody>
                      <StudentClassSchedule />
                    </MainBody>
                  </RequireAuth>
                }
              />
              <Route
                path="student/course/list"
                element={
                  <RequireAuth>
                    <MainBody>
                      <StudentCourseList />
                    </MainBody>
                  </RequireAuth>
                }
              />
              <Route
                path="student/course/list/:_id"
                element={
                  <RequireAuth>
                    <MainBody>
                      <StudentCourseDetail />
                    </MainBody>
                  </RequireAuth>
                }
              />
              <Route
                path="student/coursepurchased"
                element={
                  <RequireAuth>
                    <MainBody>
                      <StudentCoursePurchased />
                    </MainBody>
                  </RequireAuth>
                }
              />
              <Route
                path="student/bankingdetails"
                element={
                  <RequireAuth>
                    <MainBody>
                      <Bankinformation />
                    </MainBody>
                  </RequireAuth>
                }
              />
              <Route
                path="student/classbooking"
                element={
                  <RequireAuth>
                    <MainBody>
                      <ClassBooking />
                    </MainBody>
                  </RequireAuth>
                }
              />
            </Route>
          </Route>

          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
