import React from "react";
import {
  FaCalendar,
  FaRegListAlt,
  FaUserGraduate,
  FaUserTie,
} from "react-icons/fa";
import "./Main.css";
function Main() {
  return (
    <>
      <div>
        <div className="container" style={{ paddingTop: "50px" }}>
          <div className="row">
            <div className="col-lg-3 col-xl-3 col-md-6 col-sm-6 col-12">
              <div className="card carddash bg-carddash-shadow">
                <div className="card-body">
                  <h5 className="card-title">Student</h5>
                  <div className="icon-bg">
                    <FaUserGraduate className="text-white" />{" "}
                  </div>
                  <p className="card-text">
                    Count <span>1</span>
                  </p>
                  <a href="#" className="btn btn-link card-button">
                    Go somewhere
                  </a>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-xl-3 col-md-6 col-sm-6 col-12">
              <div className="card carddash bg-carddash-shadow">
                <div className="card-body">
                  <h5 className="card-title">Tutor</h5>
                  <div
                    className="icon-bg"
                    style={{ backgroundColor: " #ab8788" }}
                  >
                    <FaUserTie className="text-white" />
                  </div>
                  <p className="card-text">
                    Count <span>2</span>
                  </p>
                  <a href="#" className="btn btn-link card-button">
                    Go somewhere
                  </a>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-xl-3 col-md-6 col-sm-6 col-12">
              <div className="card carddash bg-carddash-shadow">
                {" "}
                <div className="card-body">
                  <h5 className="card-title">Course</h5>
                  <div
                    className="icon-bg"
                    style={{ backgroundColor: "#d1aa89" }}
                  >
                    <FaRegListAlt className="text-white" />
                  </div>
                  <p className="card-text">
                    Count <span>5</span>
                  </p>
                  <a href="#" className="btn btn-link card-button">
                    Go somewhere
                  </a>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-xl-3 col-md-6 col-sm-6 col-12">
              <div className="card carddash bg-carddash-shadow">
                {" "}
                <div className="card-body">
                  <h5 className="card-title">Newtutor</h5>
                  <div
                    className="icon-bg"
                    style={{ backgroundColor: "#70a8ae" }}
                  >
                    <FaUserTie className="text-white" />{" "}
                  </div>
                  <p className="card-text">
                    Count <span>2</span>
                  </p>
                  <a href="#" className="btn btn-link card-button">
                    Go somewhere
                  </a>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 col-xl-6 col-md-6 col-sm-6 col-12">
                <div className="card carddash bg-carddash-shadow">
                  {" "}
                  <div className="card-body">
                    <h5 className="card-title">Student Table</h5>
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">
                            <i
                              className="fa fa-graduation-cap"
                              aria-hidden="true"
                            ></i>{" "}
                            Student
                          </th>
                          <th scope="col">
                            <i
                              className="fa fa-list-alt"
                              aria-hidden="true"
                            ></i>{" "}
                            Course
                          </th>
                          <th scope="col">
                            <i className="fa fa-laptop" aria-hidden="true"></i>{" "}
                            class
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">1</th>
                          <td>Mark</td>
                          <td>Javascript</td>
                          <td>@1 st</td>
                        </tr>
                        <tr>
                          <th scope="row">2</th>
                          <td>anil</td>
                          <td>react</td>
                          <td>@2 nd</td>
                        </tr>
                        <tr>
                          <th scope="row">3</th>
                          <td>aniket</td>
                          <td>@html</td>
                          <td>@3 rd</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Main;
