import React, { useEffect, useState } from "react";
import { IoIosArrowDropdown } from "react-icons/io";
import { Link, useLocation } from "react-router-dom";
import swal from "sweetalert";
import { getAllCourse } from "../../../networkcalls/CourseApi";
import { updateCourseTutor } from "../../../networkcalls/courseTutorApi";
import { fetchUsers } from "../../../networkcalls/UsersApi";
import { useNavigate } from "react-router-dom";
const EditCourseTutor = () => {
  const { state } = useLocation();
  const { id, tutorId, courseId, tutorName, courseTitle, isActive } = state;
  const [tutorData, setTutorData] = useState();
  const [courseData, setcourseData] = useState();
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState({
    _id: id,
    tutorId: tutorId,
    courseId: courseId,
    isActive: isActive,
  });

  let navigate = useNavigate();
  const role = "tutor";

  useEffect(() => {
    fetchUsers(role)
      .then((res) => {
        setTutorData(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
    getAllCourse().then((res) => {
      setcourseData(res.data);
    });
  }, []);
  const handleChange = (e) => {
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
  };

  const submit = (e) => {
    updateCourseTutor(data).then(
      (res) => {
        if (res.data.err === 200) {
          swal(res.data.message, "check List", "success").then((willOk) => {
            if (willOk) {
              navigate("/dashboard/admin/coursetutor/list");
            }
          });
        } else if (res.data.err === 300) {
          swal(res.data.message, "wait", "warning");
        } else {
          swal("Please try agin later", "wait", "warning");
        }
      },
      (err) => {
        swal("err", "internal server error", "danger");
      }
    );
  };

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-lg-3"></div>
          <div className="col-lg-6 p-2">
            <div className="border border-dark container align-items-center pt-3 mt-5">
              <h4>Course Tutor</h4>
              <br />
              <hr />
              <div className="container">
                <form className="">
                  <div className="row">
                    <div className="form-outline pl-3">
                      <label className="form-label">Select Tutor</label>
                      <select
                        className="form-control form-control-sm"
                        id="tutorId"
                        onClick={(e) => handleChange(e)}
                      >
                        <option selected value={data.tutorId}>
                          {tutorName}
                        </option>
                        {tutorData ? (
                          tutorData.map((data, i) => {
                            return (
                              <option value={data._id}>
                                {data.firstName} {data.lastName}
                              </option>
                            );
                          })
                        ) : (
                          <div>loading...</div>
                        )}
                      </select>
                    </div>

                    <div className="form-outline pl-3 mt-2">
                      <label className="form-label">Select Course</label>
                      <select
                        className="form-control form-control-sm"
                        id="courseId"
                        onClick={(e) => handleChange(e)}
                      >
                        <option selected value={data.courseId}>
                          {courseTitle}
                        </option>
                        {courseData ? (
                          courseData.map((data, i) => {
                            return (
                              <option value={data._id}>{data.title}</option>
                            );
                          })
                        ) : (
                          <div>loading...</div>
                        )}
                      </select>
                    </div>

                    <div className="col-md-6 mt-3 mb-4">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value={data.isActive}
                          checked={data.isActive}
                          onChange={(e) =>
                            setData({ ...data, isActive: e.target.checked })
                          }
                          id="flexCheckDefault"
                        />
                        <label class="form-check-label" for="flexCheckDefault">
                          Active{" "}
                        </label>
                      </div>
                      <div className="form-outline mt-3 ">
                        {loading ? (
                          <button className="btn btn-success mr-3">
                            Please Wait..
                          </button>
                        ) : (
                          <button
                            className="btn btn-success mr-3"
                            type="button"
                            onClick={(e) => submit(e)}
                          >
                            {" "}
                            update{" "}
                          </button>
                        )}
                        <Link to={`/dashboard/admin/coursetutor/list`}>
                          <button className="btn btn-secondary">Back</button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-lg-3"></div>
        </div>
      </div>
    </div>
  );
};

export default EditCourseTutor;
