import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { fetchStudent } from '../../networkcalls/UsersApi';
import strings from '../../utils/strings';
import "./StudentMyprofile.css"
const StudentMyProfile = () => {
    const [data, setData] = useState();
    const [profilePic, setProfilePic] = useState();

    let userData = useSelector((state) => state.userData.userinfo);
    let studentId = userData._id;
    useEffect((e) => {
        fetchStudent(studentId).then(
            (res) => {
                console.log(res.data);
                setData(res.data);
                if (res.data.profilePic !== null) {
                    setProfilePic(strings.IMG_URL + "users/" + res.data.profilePic);
                }
            },
            (err) => {
                console.log(err);
            }
        );
    }, []);


    return (
        <div><div className="container mt-5">
            <div className="row border-dark p-3" style={{ backgroundColor: "#fbfbfb" }}>
                <div className="col-md-6 text-center">
                    <br />
                    <br />
                    <img src={profilePic} alt="My Rectangular Image" className="profile-img" />
                    <br />
                    <br />
                    <h4>{data?.firstName} {data?.lastName}</h4>
                    <label>{data?.role}</label>
                    <br />
                </div>
                <div className="col-md-6 Profile-Details">
                    <br />

                    <h5 className="Details-heading" >Profile Details</h5>
                    <br />

                    <div className='row'>
                        <div className='col-lg-3 col-md-4 label'>FirstName</div>
                        <div className='col-lg-9 col-md-8 user-text'>{data?.firstName}</div>
                    </div>
                    <br />
                    <div className='row'>
                        <div className='col-lg-3 col-md-4 label'>LasttName</div>
                        <div className='col-lg-9 col-md-8 user-text'>{data?.lastName}</div>
                    </div>
                    <br />
                    <div className='row'>
                        <div className='col-lg-3 col-md-4 label'>Email</div>
                        <div className='col-lg-9 col-md-8 user-text'>{data?.email}</div>
                    </div>
                    <br />
                    <div className='row'>
                        <div className='col-lg-3 col-md-4 label'>ParentName</div>
                        <div className='col-lg-9 col-md-8 user-text'>{data?.parentName}</div>
                    </div>
                    <br />
                    <div className='row'>
                        <div className='col-lg-3 col-md-4 label'>Gender</div>
                        <div className='col-lg-9 col-md-8 user-text'>{data?.gender ?? "-"}</div>
                    </div>
                    <br />
                    <div className='row'>
                        <div className='col-lg-3 col-md-4 label'>Dob</div>
                        <div className='col-lg-9 col-md-8 user-text'>{moment(data?.dob).format("DD-MM-YYYY") ?? "-"}</div>
                    </div>
                    <br />
                    <div className='row'>
                        <div className='col-lg-3 col-md-4 label'>Phone</div>
                        <div className='col-lg-9 col-md-8 user-text'>{data?.phone}</div>
                    </div>
                    <br />
                    <div className='row'>
                        <div className='col-lg-3 col-md-4 label'>ParentContact</div>
                        <div className='col-lg-9 col-md-8 user-text'>{data?.parentContact}</div>
                    </div>
                </div>
            </div>
        </div></div>
    )
}

export default StudentMyProfile;