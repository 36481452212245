import React from "react";
import { Tab, Tabs, Accordion } from "react-bootstrap";

function StudentClassSchedule() {
  return (
    <div className="class-schedule">
      <div className="d-flex align-items-center justify-content-between mt-3 mb-2">
        <h1 className="fs-3 ">Class-Schedule</h1>
        <button className="btn btn-primary">Add</button>
      </div>
      <div className="py-3">
        <Tabs
          defaultActiveKey="upcoming"
          id="classes-schedules-tab"
          className="mb-3"
        >
          <Tab eventKey="upcoming" title="Upcoming (8)">
            <Accordion defaultActiveKey="0" flush>
              <div className="d-flex mb-3">
                <div className="date text-nowrap mr-3 p-1">
                  <h6 className="fs-6">Mar 21</h6>
                  <small className="text-muted">MON</small>
                </div>
                <div className="topic w-100">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Accordion Item #1</Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.
                    </Accordion.Body>
                  </Accordion.Item>
                </div>
              </div>
              <div className="d-flex mb-3">
                <div className="date text-nowrap mr-3 p-1">
                  <h6 className="fs-6">Mar 21</h6>
                  <small className="text-muted">MON</small>
                </div>
                <div className="topic w-100">
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Accordion Item #1</Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.
                    </Accordion.Body>
                  </Accordion.Item>
                </div>
              </div>
            </Accordion>
          </Tab>
          <Tab eventKey="completed" title="Completed (123)">
            completed
          </Tab>
          <Tab eventKey="not_completed" title="Not Completed (32)">
            Not completed
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}

export default StudentClassSchedule;
