import React, { useEffect, useState } from "react";
import { IoIosArrowDropdown } from "react-icons/io";
import { Link, useLocation, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { getAllCourse } from "../../../networkcalls/CourseApi";
import { createCourseTutor } from "../../../networkcalls/courseTutorApi";
import { fetchTutor, fetchUsers } from "../../../networkcalls/UsersApi";

const AddCourseTutor = () => {
  //   const { state } = useLocation();
  //   const { tutorId } = state;
  const [loading, setLoading] = useState(false);
  const [tutordata, setTutorData] = useState();
  const [courseData, setcourseData] = useState();
  const [data, setData] = useState({
    tutorId: "",
    courseId: "",
    isActive: 0,
  });
  let navigate = useNavigate();
  const role = "tutor";

  useEffect(() => {
    fetchUsers(role)
      .then((res) => {
        setTutorData(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
    getAllCourse().then((res) => {
      setcourseData(res.data);
    });
  }, []);

  const handleChange = (e) => {
    let newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
  };

  const submit = () => {
    createCourseTutor(data).then(
      (res) => {
        if (res.data.err === 200) {
          swal(res.data.message, "check List!", "success").then((willOk) => {
            if (willOk) {
              navigate("/dashboard/admin/coursetutor/list");
            }
          });
        } else if (res.data.err === 500) {
          swal("Server Error", "Wait", "warning");
        }
      },
      (err) => {
        swal(err.response.data.message, "Choose Another Tutor!", "warning");
      }
    );
  };

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-lg-3"></div>
          <div className="col-lg-6 p-2">
            <div className="border border-dark container align-items-center pt-3 mt-5">
              <h4>Course Tutor</h4>
              <br />
              <hr />
              <div className="container">
                <form className="">
                  <div className="row">
                    <div className="form-outline pl-3">
                      <label className="form-label">Select Tutor</label>
                      <select
                        className="form-control form-control-sm"
                        id="tutorId"
                        onChange={(e) => handleChange(e)}
                      >
                        <option selected>select</option>
                        {tutordata ? (
                          tutordata.map((data, i) => {
                            return (
                              <option value={data._id}>
                                {data.firstName} {data.lastName}
                              </option>
                            );
                          })
                        ) : (
                          <div>loading...</div>
                        )}
                        <option></option>
                      </select>
                    </div>

                    <div className="form-outline pl-3 mt-2">
                      <label className="form-label">Select Course</label>
                      <select
                        className="form-control form-control-sm"
                        id="courseId"
                        onChange={(e) => handleChange(e)}
                      >
                        <option selected>select</option>
                        {courseData ? (
                          courseData.map((data, i) => {
                            return (
                              <option value={data._id}>{data.title}</option>
                            );
                          })
                        ) : (
                          <div>loading...</div>
                        )}
                      </select>
                    </div>

                    <div className="col-md-6 mt-3 mb-4">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value={data.isActive}
                          onChange={(e) =>
                            setData({ ...data, isActive: e.target.checked })
                          }
                          id="flexCheckDefault"
                        />
                        <label class="form-check-label" for="flexCheckDefault">
                          Active{" "}
                        </label>
                      </div>
                      <div className="form-outline mt-3 ">
                        {loading ? (
                          <button className="btn btn-success mr-3">
                            Please Wait..
                          </button>
                        ) : (
                          <button
                            className="btn btn-success mr-3"
                            type="button"
                            onClick={(e) => submit(e)}
                          >
                            {" "}
                            Add{" "}
                          </button>
                        )}
                        <Link to={`/dashboard/admin/coursetutor/list`}>
                          <button className="btn btn-secondary">Back</button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-lg-3"></div>
        </div>
      </div>
    </div>
  );
};

export default AddCourseTutor;
