import moment from "moment/moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { fetchStudent } from "../../../networkcalls/UsersApi";
import strings from "../../../utils/strings";

const StudentView = () => {
  const { studentid } = useParams();
  const [data, setData] = useState();

  const getStudent = () => {
    fetchStudent(studentid).then((res) => {
      if (res.err === 200) {
        setData(res.data);
      }
      console.log(res);
    });
  };

  useEffect(() => {
    getStudent();
  }, []);

  return (
    <div>
      {" "}
      <div>
        <div className="container">
          <ToastContainer />
          <div className="row">
            <div className="col-md-4 mt-2 p-3 card rounded-3">
              <h5 className="mb-2">Profile Details</h5>
              <div className="">
                <div className="col-md-12">
                  {" "}
                  <b>Name</b>{" "}
                </div>
                <div className="col-md-12">
                  {" "}
                  {data?.firstName} {data?.lastName}
                </div>
              </div>
              <div className="mt-3">
                <div className="col-md-12">
                  {" "}
                  <b>Email</b>{" "}
                </div>
                <div className="col-md-12"> {data?.email}</div>
              </div>
              <div className="mt-3">
                <div className="col-md-12">
                  {" "}
                  <b>Phone</b>{" "}
                </div>
                <div className="col-md-12"> {data?.phone}</div>
              </div>
              <div className="mt-3">
                <div className="col-md-12">
                  {" "}
                  <b>Parent Phone</b>{" "}
                </div>
                <div className="col-md-12"> {data?.parentContact}</div>
              </div>
              <div className="mt-3">
                <div className="col-md-12">
                  {" "}
                  <b>Parent Name</b>{" "}
                </div>
                <div className="col-md-12"> {data?.parentName}</div>
              </div>
              <div className="mt-3">
                <div className="col-md-12">
                  {" "}
                  <b>Gender</b>{" "}
                </div>
                <div className="col-md-12">{data?.gender}</div>
              </div>
              <div className="mt-3">
                <div className="col-md-12">
                  {" "}
                  <b>DOB</b>{" "}
                </div>
                <div className="col-md-12">
                  {moment(data?.dob).format("YYYY-MM-DD")}
                </div>
              </div>

              <div className="mt-3">
                <div className="col-md-12">
                  {" "}
                  <b>Profile Pic</b>{" "}
                </div>
                <div className="col-md-12">
                  <div className="col-md-3">
                    <img
                      src={
                        data?.profilePic ? (
                          strings.IMG_URL + "users/" + data.profilePic
                        ) : (
                          <i className="bi bi-person"></i>
                        )
                      }
                    />
                  </div>
                </div>
                <div className="col-md-12 mt-3">
                  <div className="col-md-12">
                    <div className="col-md-12">
                      {" "}
                      <b>Created At</b>{" "}
                    </div>
                    <div className="col-md-12">{data?.createdAt}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentView;
