import { importAddImages } from '../../utils/importImages';

const images = importAddImages(require.context('../../images/student', false, /\.(png|jpe?g|svg)$/));
const courseImagesLinks = Object.entries(images).map((image, key) => {
    let fimg = {
        key: key,
        name: image[0],
        path: image[1],
    }
    return fimg;
})

export const courses = [
    {
        id: "1",
        title: "JS for begginers",
        tutor: "one",
        short_desc: "very begginer friendly.. 🙂",
        desc: "3 months course totally for begginers nksdn sds sdfs sufb s sdb ssud sdjfs fsfs fsf sjs bfs ",
        img_path: courseImagesLinks[0].path
    }, {
        id: "2",
        title: "Python for begginers",
        tutor: "two",
        short_desc: "very begginer friendly.. 🙂",
        desc: "3 months course totally for begginers nksdn sds sdfs sufb s sdb ssud sdjfs fsfs fsf sjs bfs ",
        img_path: courseImagesLinks[1].path
    }, {
        id: "3",
        title: "React tutorial",
        tutor: "three",
        short_desc: "very begginer friendly.. 🙂",
        desc: "3 months course totally for begginers nksdn sds sdfs sufb s sdb ssud sdjfs fsfs fsf sjs bfs ",
        img_path: courseImagesLinks[2].path

    }, {
        id: "4",
        tutor: "one",
        title: "100 days of code",
        short_desc: "very begginer friendly.. 🙂",
        desc: "3 months course totally for begginers nksdn sds sdfs sufb s sdb ssud sdjfs fsfs fsf sjs bfs ",
        img_path: courseImagesLinks[3].path
    }, {
        id: "5",
        title: "dsa from scratch",
        short_desc: "very begginer friendly.. 🙂",
        tutor: "one",
        desc: "3 months course totally for begginers nksdn sds sdfs sufb s sdb ssud sdjfs fsfs fsf sjs bfs ",
        img_path: courseImagesLinks[4].path
    }, {
        id: "6",
        title: "JS for beg to adv",
        short_desc: "very begginer friendly.. 🙂",
        tutor: "one",
        desc: "3 months course totally for begginers nksdn sds sdfs sufb s sdb ssud sdjfs fsfs fsf sjs bfs ",
        img_path: courseImagesLinks[5].path
    }, {
        id: "7",
        title: "JS and Ts",
        short_desc: "very begginer friendly.. 🙂",
        tutor: "three",
        desc: "3 months course totally for begginers nksdn sds sdfs sufb s sdb ssud sdjfs fsfs fsf sjs bfs ",
        img_path: courseImagesLinks[1].path
    }, {
        id: "8",
        title: "py for ai",
        short_desc: "very begginer friendly.. 🙂",
        tutor: "two",
        desc: "3 months course totally for begginers nksdn sds sdfs sufb s sdb ssud sdjfs fsfs fsf sjs bfs ",
        img_path: courseImagesLinks[0].path
    }
]