import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import { Row, Col, Button } from "react-bootstrap";

const PageBreadcrumb = ({ pageTitle, actionButtons }) => {
	return (
		<Fragment>
			<div className="page-header d-flex justify-content-between align-items-center py-3">
				<div sm={5}>
					<h4 className="pageTitle">{pageTitle}</h4>
				</div>
				<div sm={7} className="text-end">
					{actionButtons.map((actionButton, key) => {
						return actionButton.isLink ? (
							<NavLink to={actionButton.navigateLink} className="ms-1" key={actionButton.key}>
								<Button type="button" variant={actionButton.variant} size="sm">
									{actionButton.text}
								</Button>
							</NavLink>
						) : (
							<Button variant={actionButton.variant} className="ms-1" size="sm" key={actionButton.key}>
								{actionButton.text}
							</Button>
						);
					})}
				</div>
			</div>
		</Fragment>
	);
};

export default PageBreadcrumb;
