export const weekDays = [
	{ key: "1", text: "MONDAY" },
	{ key: "2", text: "TUESDAY" },
	{ key: "3", text: "WEDNESDAY" },
	{ key: "4", text: "THURSDAY" },
	{ key: "5", text: "FRIDAY" },
	{ key: "6", text: "SATURDAY" },
	{ key: "7", text: "SUNDAY" },
];

export const timeSlots = [
	{ key: "1", ftime: "12:00 AM", ttime: "01:00 AM" },
	{ key: "2", ftime: "01:00 AM", ttime: "02:00 AM" },
	{ key: "3", ftime: "02:00 AM", ttime: "03:00 AM" },
	{ key: "4", ftime: "03:00 AM", ttime: "04:00 AM" },
	{ key: "5", ftime: "04:00 AM", ttime: "05:00 AM" },
	{ key: "6", ftime: "05:00 AM", ttime: "06:00 AM" },
	{ key: "7", ftime: "06:00 AM", ttime: "07:00 AM" },
	{ key: "8", ftime: "07:00 AM", ttime: "08:00 AM" },
	{ key: "9", ftime: "08:00 AM", ttime: "09:00 AM" },
	{ key: "10", ftime: "09:00 AM", ttime: "10:00 AM" },
	{ key: "11", ftime: "10:00 AM", ttime: "11:00 AM" },
	{ key: "12", ftime: "11:00 AM", ttime: "12:00 PM" },
	{ key: "13", ftime: "12:00 PM", ttime: "01:00 AM" },
	{ key: "14", ftime: "01:00 PM", ttime: "02:00 PM" },
	{ key: "15", ftime: "02:00 PM", ttime: "03:00 PM" },
	{ key: "16", ftime: "03:00 PM", ttime: "04:00 PM" },
	{ key: "17", ftime: "04:00 PM", ttime: "05:00 PM" },
	{ key: "18", ftime: "05:00 PM", ttime: "06:00 PM" },
	{ key: "19", ftime: "06:00 PM", ttime: "07:00 PM" },
	{ key: "20", ftime: "07:00 PM", ttime: "08:00 PM" },
	{ key: "21", ftime: "08:00 PM", ttime: "09:00 PM" },
	{ key: "22", ftime: "09:00 PM", ttime: "10:00 PM" },
	{ key: "23", ftime: "10:00 PM", ttime: "11:00 PM" },
	{ key: "24", ftime: "11:00 PM", ttime: "11:59 PM" },
];
