import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import swal from "sweetalert";
import {
  fetchTutorSlots,
  removeTimeSlot,
} from "../../networkcalls/WeekScheduleApi";

const TimeSlotBlock = ({ dataId, timeStart, timeEnd }) => {
  const [modalShow, setModalShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const mdlTitle = "Remove Time-Slot";
  const mdlText = "Do you want to remove the selected time slot?";

  const handleTimeSlotClick = (e) => {
    setModalShow(!modalShow);
  };

  let userData = useSelector((state) => state.userData.userinfo);
  const tutorid = userData._id;

  const handleModalClose = () => setModalShow(!modalShow);
  const fetchDataFillCalendar = async () => {
    await fetchTutorSlots({ tutorId: tutorid })
      .then((res) => {
        console.log(res.data);
        return res.data;
      })
      .catch((ex) => {
        console.log(ex.message);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    removeTimeSlot(dataId)
      .then((res) => {
        if (res.err === 200) {
          fetchDataFillCalendar();
          setTimeout(() => {
            setIsLoading(false);
            setModalShow(false);
            toast.success(res.message);
            setTimeout(() => {
              window.location.reload();
            }, 100);
          }, 2000);
        } else {
          toast.error(res.message);
        }
      })
      .catch((ex) => {
        swal("warning", "something went wrong", "warning");
      });
  };

  return (
    <>
      <button
        type="button"
        data-id={dataId}
        onClick={handleTimeSlotClick}
        className="bg-white"
      >
        <span style={{ pointerEvents: "none" }}>{timeStart}</span>
        <span
          style={{
            pointerEvents: "none",
            margin: "2px 0",
            width: "100%",
            display: "block",
          }}
        >
          To
        </span>
        <span style={{ pointerEvents: "none" }}>{timeEnd}</span>
      </button>
      <Modal
        show={modalShow}
        onHide={handleModalClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h4 className="pageTitle">{mdlTitle}</h4>
        </Modal.Header>
        <Modal.Body>
          <p>{mdlText}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-danger" onClick={handleModalClose}>
            Cancel
          </Button>
          <Button variant="outline-success" onClick={handleSubmit}>
            {isLoading ? "Please wait.." : "Confirm"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TimeSlotBlock;
