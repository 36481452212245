import React from "react";
import logo from "../../images/logo.png";
import "./Invoice.css";
import moment from "moment";
const Invoice = ({ invoice }) => {
  console.log(invoice);
  return (
    <div>
      <div id="content" className="invoice_container">
        <img className="img-size pl-3 mb-3" src={logo} alt="logo" />

        <div>
          <div className="invoice-header">
            <div>
              <b style={{ fontSize: "20px" }}>Tax Invoice </b> <br />
              <br />
              <b className="">
                Automic Brain <br />
              </b>{" "}
              <b> GSTIN : </b> 27AACCW2956J1ZY <br />
              <p>
                <b>Address</b> : 2A 2nd floor Wework chromium <br />
                Milind nagar, L & T flyover, Andheri(E) <br />
                Mumbai mharashtra, <br />
                In 400072 <br />
                Call: 022-489-33955 <br />
                Contact Email: support@automic.com
              </p>
            </div>
            <div style={{ marginTop: "70px", marginLeft: "70px" }}>
              <b> Invoice Date </b> <br />
              {moment(invoice?.transactionDate).format("l")} <br />
              <br />
              <b> Invoice No </b>
              <br />
              {invoice?.reciptId} <br />
              <br />
              <b> Transaction Id </b>
              <br />
              <br />
              <br />
            </div>
          </div>
        </div>
        <br />
        <div className="table-layout">
          <table id="customers">
            <thead>
              <tr>
                <th>Course Title</th>

                <th>Quantity</th>

                <th>
                  Amount <br />
                  (In INR)
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{invoice?.courseTitle} (online one to one classes)</td>
                <td>1</td>

                <td>{invoice?.coursePrice}</td>
              </tr>

              <tr>
                <td></td>
                <td>IGST @ 18%</td>
                <td>{invoice?.coursePriceGST}</td>
              </tr>
              <tr>
                <td></td>
                <td>
                  <b>Total Amount </b> <br />
                  <b>(in INR)</b>
                </td>
                <td>
                  <b>{invoice?.coursePrice + invoice?.coursePriceGST}</b>
                </td>
              </tr>
            </tbody>
          </table>
          <br />

          <p className="text-secondary">
            This is computer generated invoice no need signature
          </p>
        </div>
      </div>
    </div>
  );
};

export default Invoice;
