import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BsFilter } from "react-icons/bs";
import { courses } from "./courses";
import { getAllCourse } from "../../networkcalls/CourseApi";

function StudentCourseList() {
  const [queryByTopic, setQueryByTopic] = useState("");
  const [queryByTutor, setQueryByTutor] = useState("");
  const [courseData, setCourseData] = useState();
  let navigate = useNavigate();
  const search = (data) => {
    if (queryByTopic.length < 3 && queryByTutor === "") return data;
    return data.filter(
      (item) =>
        item.title.toLowerCase().includes(queryByTopic) &&
        item.tutor.toLowerCase().includes(queryByTutor)
    );
  };

  const resetForm = (e) => {
    setQueryByTopic("");
    setQueryByTutor("");
  };

  useEffect(() => {
    getAllCourse().then(
      (res) => {
        console.log("get all course", res.data);
        setCourseData(res.data);
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);

  return (
    <div className="student-course-list mt-3">
      {/* <h1 className="fs-4 mb-3">
                Courses
            </h1> */}
      <form>
        <div className="filter row mx-0  align-items-center justify-content-center">
          {/* <div className="filter-icon col-lg-2 mb-1">
            <div className="icon-sm">
              <span>{<BsFilter />}</span>
              <span className="ml-2">Filters</span>
            </div>
          </div> */}
          {/* <div className="d-flex justify-content-between align-items-center flex-column flex-md-row col-lg-8">
            <div className="filter-by_name w-100 mb-2 mb-md-0">
              <div class="form-floating mr-md-1">
                <input
                  onChange={(e) => setQueryByTopic(e.target.value)}
                  value={queryByTopic}
                  name="topicname"
                  type="text"
                  class="form-control"
                  id="floatingTopicname"
                  placeholder="topic"
                />
                <label for="floatingTopicname">By topic name</label>
              </div>
            </div>
            <div class="form-floating w-100">
              <select
                onChange={(e) => setQueryByTutor(e.target.value)}
                class="form-select ml-md-1"
                id="floatingSelect"
                aria-label="Floating label select example"
              >
                <option value="" selected>
                  Select tutor
                </option>
                <option value="one">Tutor one</option>
                <option value="two">Tutor two</option>
                <option value="three">Tutor three</option>
              </select>
              <label for="floatingSelect">By tutor name</label>
            </div>
          </div> */}
          {/* <div className="cta-buttons text-center col-lg-2">
            <button className="link-btn" type="submit">
              Search
            </button>

            <button
              className="link-btn col-auto "
              type="reset"
              onClick={resetForm}
            >
              Clear
            </button>
          </div> */}
        </div>
      </form>

      {/* filtered course list */}
      <div className="filtered-list courses mt-3 mb-3">
        <h4 className="mb-3">Courses</h4>
        <div className="grid">
          {courseData ? (
            courseData.map((data) => {
              return (
                <div
                  onClick={() =>
                    navigate(`/dashboard/student/course/list/${data._id}`, {
                      state: { id: data._id },
                    })
                  }
                  style={{ cursor: "pointer" }}
                  key={data.key}
                  className="course bg-white rounded-3 md-shadow"
                >
                  <div className="course-img rounded-3 bg-white">
                    <img src={data.image} alt="topic-image" className="h-100" />
                  </div>
                  <div className="course-body p-2 ">
                    <h6 className="fs-5 mb-1 course-body-title">
                      {data.title}
                    </h6>
                    <small className="course-body-desc text-muted text-muted d-inline-block mb-1">
                      {data.description.split("", 100)}
                    </small>
                    <div className="d-flex">
                      <div>
                        <button className="btn btn-brand btn-sm mt-3">
                          view details
                        </button>
                      </div>
                      <div></div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </div>
  );
}
export default StudentCourseList;
