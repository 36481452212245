import React from 'react'
import { Link } from 'react-router-dom'
function LandingPage() {
  return (
    <div className='d-flex align-items-center justify-content-center flex-column vh-100'>
      <h3>Online Courses</h3>
      <div className='mb-3'> Please click on the below button to access the application</div>
      <Link to="login">
        <button className='btn btn-primary' type='button'>
          Login
        </button>
      </Link>
      {/* <Link to="dashboard" >Dashboard</Link> */}
    </div>
  )
}

export default LandingPage