import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Card, Modal, Button } from "react-bootstrap";
import PageBreadcrumb from "../../../components/PageBreadcrumb";
import DataTable from "react-data-table-component";
import { toast, ToastContainer } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import {
  fetchUsers,
  approveRequest,
  deleteUser,
} from "../../../networkcalls/UsersApi";
import { FaEdit, FaEye, FaTrash } from "react-icons/fa";
import swal from "sweetalert";

const actionButtons = [];

function TutorList() {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [filterUsers, setfilterUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [tutorId, setTutorId] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isApprove, setIsApprove] = useState(0);
  const role = "tutor";
  let navigate = useNavigate();

  useEffect(() => {
    fetchTutors();
  }, []);

  const fetchTutors = () => {
    fetchUsers(role)
      .then((res) => {
        setUsers(res.data);
        setfilterUsers(res.data);
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  const handleBlock = (userId) => {
    console.log(userId);

    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover Tutor!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteUser(userId)
          .then((result) => {
            fetchTutors();
            toast.error("Tutor record has been deleted!");
            setLoading(!loading);
          })
          .catch((err) => {
            console.log(err.message);
          });
      } else {
        toast.success("Tutor record is safe!");
      }
    });
  };

  const handleModalClose = () => setIsOpen(!isOpen);

  const handleChange = (e) => {
    const value = e.target.value;
    setIsApprove(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isApprove !== "") {
      approveRequest(tutorId, { isApproved: isApprove })
        .then((res) => {
          if (res.msg) {
            toast.success(res.msg);
            fetchTutors();
            setIsOpen(false);
          }
        })
        .catch((ex) => {
          setIsOpen(false);
          console.log();
        });
    }
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => row.firstName + " " + row.lastName,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
    },
    {
      name: "Phone",
      selector: (row) => row.phone,
    },
    {
      name: "Request Status",
      selector: (row) => [
        row.isApproved === 0 ? (
          <div>
            <span className="bg-warning badge text-dark pl-3 pr-3 pt-2 pb-2">
              Pending
            </span>
          </div>
        ) : row.isApproved === 1 ? (
          <span className="badge bg-success pl-3 pr-3 pt-2 pb-2">Approved</span>
        ) : (
          <span className="badge bg-danger pl-3 pr-3 pt-2 pb-2">Rejected</span>
        ),
      ],
    },
    {
      name: "Penalty Charges",
      selector: (row) => [
        <div>
          <button
            className="btn btn-sm btn-primary mb-1"
            onClick={() => {
              navigate(`/dashboard/admin/tutor/charges`, {
                state: { tutorId: row._id },
              });
            }}
          >
            Charges
          </button>
        </div>,
      ],
    },
    {
      name: "Action",
      cell: (row) => [
        row.isApproved === 0 ? (
          <>
            <Link to={`/dashboard/admin/tutor/view/${row._id}`}>
              <button className="btn btn-outline-success mx-1 btn-sm">
                <FaEye />
              </button>
            </Link>
            <button
              className="btn btn-outline-danger mx-1 btn-sm"
              onClick={() => handleBlock(row._id)}
            >
              <FaTrash />
            </button>
          </>
        ) : row.isApproved === 1 ? (
          <>
            <Link to={`/dashboard/admin/tutor/edit/${row._id}`}>
              <button className="btn btn-outline-primary mx-1 btn-sm">
                <FaEdit />
              </button>
            </Link>
            <button
              className="btn btn-outline-danger mx-1 btn-sm"
              onClick={() => handleBlock(row._id)}
            >
              <FaTrash />
            </button>
          </>
        ) : (
          <>
            <button
              className="btn btn-outline-danger mx-1 btn-sm"
              onClick={() => handleBlock(row._id)}
            >
              <FaTrash />
            </button>
          </>
        ),
      ],
    },
  ];

  useEffect(() => {
    const result = users.filter((c) => {
      return c?.firstName?.toLowerCase().match(search.toLowerCase());
    });
    setfilterUsers(result);
  }, [search, users]);

  return (
    <Fragment>
      <ToastContainer />
      <PageBreadcrumb pageTitle={"Tutors"} actionButtons={actionButtons} />
      <Row>
        <Col sm={12}>
          <Card>
            <Card.Body>
              <DataTable
                columns={columns}
                data={filterUsers}
                pagination
                fixedHeader
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Modal
        show={isOpen}
        onHide={handleModalClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h4 className="pageTitle">Tutor Request?</h4>
        </Modal.Header>
        <Modal.Body>
          <div>What do you want to do with this tutor request?</div>
          <small className="">
            Select the option from below and click submit.
          </small>
          <div className="form-group mt-3">
            <label>Action</label>
            <select className="form-select" onChange={handleChange}>
              <option value="">Select option</option>
              <option value="1">Approve</option>
              <option value="2">Reject</option>
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-danger" onClick={handleModalClose}>
            Cancel
          </Button>
          <Button variant="outline-success" onClick={handleSubmit}>
            {loading ? "Please wait.." : "Submit"}
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
}

export default TutorList;
