import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import DataTable from "react-data-table-component";
import { fetchListOfPurchasedCourses } from "../../networkcalls/adminApi";
import Select, { components } from "react-select";
const CoursePurchasedByStudent = () => {
  const [data, setData] = useState();
  const [courselist, setCourseList] = useState();
  const [selectedCourse, setSelectedCourse] = useState();

  useEffect(() => {
    fetchListOfPurchasedCourses().then((res) => {
      console.log(res);
      setData(res.data);
      var result = res.data;
      var courses = [];
      result.forEach((element) => {
        element.coursename.toLowerCase();
        courses.push({
          value: element.coursename,
          label: element.coursename,
        });
      });
      setCourseList(courses);
    });
  }, []);

  const columns = [
    {
      name: "Course Title",
      selector: (row) => row.coursename,
    },
    {
      name: "Student Name",
      selector: (row) => row.studentname,
    },
    {
      name: "Transaction date",
      selector: (row) => row.tranactionDate,
    },
    {
      name: "Course Price (incl. GST)",
      selector: (row) => row.coursePrice + row.coursePriceIncludingGST,
    },
    {
      name: "Payment Status",
      selector: (row) => row.paymentStatus,
    },
  ];

  const handleCourse = (e) => {
    console.log(e.value);
  };

  const handleSearch = (e) => {
    e.preventDefault();
  };
  const handleClear = (e) => {
    e.preventDefault();
  };
  return (
    <div>
      <div className="container-fluid">
        <div className="row card p-3">
          <h5>Filter</h5>
          <hr />
          <form className="form-horizontal">
            <div className="row form-row mb-4 g-0">
              <div className="col-md-3 mb-1 form-group">
                <label>Course Title</label>
                <Select
                  options={courselist}
                  placeholder={
                    <div className="select-placeholder-text">Search Course</div>
                  }
                  className="text-start"
                  value={courselist?.find(
                    (obj) => obj.value === selectedCourse
                  )}
                  onChange={handleCourse}
                />
              </div>
              <div className="col-md-1"></div>
              {/* <div className="col-md-3 form-group mb-1">
                <label>Mobile Number :</label>
                <input
                  type="number"
                  id="mobile"
                  placeholder="search by Mobile Number"
                  className="form-control"
                />
              </div> */}
              <div className="col-md-1"></div>
              {/* <div className="col-md-3 form-group mb-1">
                <label>Recharge Status :</label>
                <input
                  type="text"
                  id="rechargestatus"
                  placeholder="search by Recharge Status"
                  className="form-control"
                />
              </div> */}
              <div className="col-md-1"></div>
            </div>
            <hr />
            <div className="d-flex App">
              <button
                className="btn btn-sm  btn-outline-success mr-2"
                onClick={handleSearch}
              >
                Search
              </button>
              <button
                className="btn btn-sm btn-outline-danger "
                onClick={handleClear}
              >
                Clear
              </button>
            </div>
          </form>
        </div>
      </div>

      <div className="mt-3">
        <div className="m-2">
          <h4 className="mb-3">Course Purchased</h4>
          <DataTable
            className="border border-dark"
            columns={columns}
            data={data}
            highlightOnHover
            pagination
            paginationServer
            paginationRowsPerPageOptions={[]}
          />
        </div>
      </div>
    </div>
  );
};

export default CoursePurchasedByStudent;
