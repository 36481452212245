import React, { useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { checkLogin } from "../Store/reducer/userReducer";

const RequireAuth = ({ children }) => {
  const dispatch = useDispatch();
  const [isLogin, setIsLogin] = useState(true);
  const [loading, setLoading] = useState(true);
  let navigate = useNavigate();

  const checkIsLogin = async () => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      const data = await dispatch(checkLogin(token));
      if (data.payload?.info) {
        setIsLogin(true);
      } else {
        setIsLogin(false);
      }
    } else {
      setIsLogin(false);
    }
    setLoading(false);
  };
  useEffect(() => {
    checkIsLogin();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }
  if (isLogin === false) {
    return <Navigate to="/login" replace />;
  }
  return <div>{children}</div>;
};

export default RequireAuth;
