import { axiosPrivate } from "./axios";

export const coursePurchased = async (data) => {
  let result = await axiosPrivate.post("/student/coursepurchase", data);
  return result.data;
};

export const coursePurchaseList = async (studentId) => {
  let result = await axiosPrivate.post("/student/coursepurchaselist", studentId);
  return result.data;
};
