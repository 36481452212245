import React, { useContext } from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function ProtectedRoutes({ allowedRoles }) {
  // const { auth } = useAuth();
  let navigate = useNavigate();
  let userRole = JSON.parse(sessionStorage.getItem("userRole"));
  const location = useLocation();

  return allowedRoles.includes(userRole) ? (
    <Outlet />
  ) : (
    // navigate("/unautherized")
    <Navigate to="/unautherized" state={{ from: location }} replace />
  );
}

export default ProtectedRoutes;
