import React, { useEffect, useState, Fragment } from "react";
import PageBreadcrumb from "../../../components/PageBreadcrumb";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Col, Row } from "react-bootstrap";
import { getCourse, updateCourse } from "../../../networkcalls/CourseApi";
import { useNavigate, useParams } from "react-router-dom";
import { Card, InputGroup } from "react-bootstrap";
import strings from "../../../utils/strings";

const actionButtons = [
  {
    key: "backButton",
    variant: "outline-dark",
    text: "Back",
    navigateLink: "/dashboard/admin/course/list",
    isLink: true,
  },
];

const courseType = ["Basic", "Intermidiate", "Expert", "All"];

const CourseEdit = ({ history, match, index }) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [type, setType] = useState("");
  const [coverimage, setImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [price, setPrice] = useState(0);
  const [course, setCourse] = useState([]);
  const [srcPic, setSrcPic] = useState(coverimage);
  const navigate = useNavigate();
  const { id } = useParams();
  const [imgErr, setImgErr] = useState();
  
  const handleTypeChange = (e, index) => {
    const newTypes = [...type];
    newTypes[index] = e.target.value;
    setType(newTypes);
  };

  useEffect(() => {
    getCourse(id)
      .then((result) => {
        if (result.err === 200) {
          console.log(result);
          if (result.data.coverimage !== null) {
            setSrcPic(result.data.image);
          }
          setTitle(result.data.title);
          setDescription(result.data.description);
          setType(result.data.type);
          setPrice(result.data.price);
        }
      })
      .catch((err) => {
        // error ocurred
      });
  }, [id]);

  const setdata3 = (e) => {
    const file = e.target.files[0];
    if ((file.type.slice(6) === "png") | "jpg" | "jpeg") {
      setImgErr("");
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (reader.result != null) {
          setSrcPic(reader.result.toString());
        }
      };
      setImage(e.target.files[0]);
    } else {
      setImgErr("Please select image of png,jpg,jpeg format");
    }
  };

  const handleChange = (e) => {
    setType(e.target.value);
  };

  const updateCourseData = async (e) => {
    e.preventDefault();
    setLoading(true);
    var formData = new FormData();
    formData.append("id", id);
    formData.append("title", title);
    formData.append("description", description);
    formData.append("type", type);
    formData.append("coverimage", coverimage);
    formData.append("price", price);
    console.log(strings.IMG_URL + "courses/" + coverimage);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    updateCourse(id, formData, config)
      .then((result) => {
        console.log(result);
        toast.success(`Updated successful`, { autoClose: 3000 });
        setTimeout(() => {
          setLoading(false);
          navigate("/dashboard/admin/course/list");
        }, 3500);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  function updatePrice(e) {
    setPrice(e.target.value);
  }
  const courseForm = () => (
    <>
      <form onSubmit={updateCourseData}>
        <Card className="p-3">
          <Card.Body>
            <div className="mb-3 row">
              <label className="col-sm-4 col-form-label">Course Title</label>
              <div className="col-sm-8">
                <input
                  type="text"
                  name="title"
                  className="form-control"
                  onChange={(e) => setTitle(e.target.value)}
                  value={title}
                />
              </div>
            </div>
            <div className="mb-3 row">
              <label className="col-sm-4 col-form-label">Description</label>
              <div className="col-sm-8">
                <textarea
                  className="form-control"
                  name="description"
                  rows="3"
                  onChange={(e) => setDescription(e.target.value)}
                  value={description}
                ></textarea>
              </div>
            </div>
            <div className="mb-3 row">
              <label className="col-sm-4 col-form-label">
                Course Price <b className="text-danger">*</b>
              </label>
              <div className="col-sm-8">
                <InputGroup className="col-sm-8">
                  <InputGroup.Text>₹</InputGroup.Text>
                  <input
                    value={price}
                    type="number"
                    className="form-control"
                    rows="3"
                    name="price"
                    onChange={updatePrice}
                  />
                </InputGroup>
              </div>
            </div>
            <div className="mb-3 row">
              <label className="col-sm-4 col-form-label">Course Type</label>
              <div className="col-sm-8">
                <select
                  className="form-select"
                  value={type}
                  onChange={handleChange}
                >
                  {courseType.map((c) => {
                    return <option value={c}>{c}</option>;
                  })}
                </select>
              </div>
            </div>
            <div className="mb-3 row">
              <label className="col-sm-4 col-form-label">Cover Image</label>
              <div className="col-sm-8">
                <img
                  src={srcPic}
                  alt={srcPic}
                  style={{ width: "150px", height: "150px" }}
                />
                <br />
                <br />
                <input
                  type="file"
                  className="form-control"
                  name="coverimage"
                  onChange={setdata3}
                />
                <span className="text-danger">{imgErr}</span>
              </div>
            </div>
            <div className="col-sm-12 mb-3">
              <button className="btn btn-success">
                {loading ? "Please wait..." : "Submit"}
              </button>
            </div>
          </Card.Body>
        </Card>
      </form>
    </>
  );

  return (
    <Fragment>
      <ToastContainer />
      <PageBreadcrumb
        pageTitle={"Update course"}
        actionButtons={actionButtons}
      />
      <Row>
        <Col md={6}>{courseForm()}</Col>
      </Row>
    </Fragment>
  );
};

export default CourseEdit;
