import { axiosPrivate, axiosPublic } from "./axios";
import { Cookies } from "react-cookie";

export const Api_Signin = async (requestData) => {
  const res = await axiosPublic.post("/users/signin", requestData, {
    headers: { "Content-Type": "application/json" },
    withCredentials: false,
  });
  return res.data;
};

export const Api_Signup = async (requestData) => {
  const res = await axiosPublic.post("/users/signup", requestData, {
    headers: { "Content-Type": "application/json" },
    withCredentials: false,
  });
  return res.data;
};

export const Api_Fetch_Details_By_Id = async (user_id) => {
  const res = await axiosPrivate.get(`/users/fetch/details/${user_id}`, {
    headers: { "Content-Type": "application/json", Authorization: Cookies },
    withCredentials: true,
  });
  return res.data;
};
