import { axiosPrivate, axiosPublic } from "./axios";
// import userIDCookie from "./userIDCookie";

export const fetchUsers = async (role) => {
  const res = await axiosPrivate.get(`/users/fetch/users?role=${role}`);
  return res.data;
};

export const approveRequest = async (tutorId, data) => {
  const res = await axiosPrivate.post(`users/approve/${tutorId}`, data);
  return res.data;
};

export const fetchAdmin = async (AdminId) => {
  const res = await axiosPrivate.get(`admin/fetch/details/${AdminId}`);
  return res.data;
};

export const UpdateAdminProfile = async (AdminId, data) => {
  const res = await axiosPrivate.post(`admin/update/${AdminId}`, data);
  return res.data;
};

export const fetchTutor = async (TutorId) => {
  const res = await axiosPrivate.get(`tutor/fetch/details/${TutorId}`);
  return res.data;
};

export const UpdateTutorProfile = async (TutorId, data) => {
  const res = await axiosPrivate.post(`tutor/update/${TutorId}`, data);
  return res.data;
};

export const fetchStudent = async (StudentId) => {
  const res = await axiosPrivate.get(`student/fetch/details/${StudentId}`);
  return res.data;
};

export const UpdateStudentProfile = async (StudentId, data) => {
  const res = await axiosPrivate.post(`student/update/${StudentId}`, data);
  return res.data;
};
export const deleteUser = async (UserId) => {
  const res = await axiosPrivate.post(`users/delete/${UserId}`);
  return res.data;
};
