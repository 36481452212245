import { axiosPrivate } from "./axios";

export const fetchsyllyabusByCourseId = async (courseId) => {
  let result = await axiosPrivate.post("student/syllabuspurchase", courseId)
  return result.data;
}

export const fetchTutorBySyllabusCodeandCourseId = async (data) => {
  let result = await axiosPrivate.post("student/fetchtutorbysyllabus", data)
  return result.data;
}
