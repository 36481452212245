import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Card } from "react-bootstrap";
import PageBreadcrumb from "../../../components/PageBreadcrumb";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useParams, Link } from "react-router-dom";
import {
  deleteUser,
  fetchStudent,
  fetchUsers,
} from "../../../networkcalls/UsersApi";
import { FaSearch, FaEdit, FaEye, FaTrash } from "react-icons/fa";
import swal from "sweetalert";

const actionButtons = [];

function StudentList() {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [filterUsers, setfilterUsers] = useState([]);
  const [search, setSearch] = useState("");
  const role = "student";

  const fetchStudent = () => {
    fetchUsers(role)
      .then((res) => {
        setUsers(res.data);
        setfilterUsers(res.data);
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  useEffect(() => {
    fetchStudent();
  }, []);

  const handleBlock = (userId) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteUser(userId).then((res) => {
          console.log(res);
          fetchStudent();
          toast.error("Student record has been deleted!");
        });
      } else {
        toast.success("Student record is safe");
      }
    });
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => row.firstName + " " + row.lastName,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
    },
    {
      name: "Phone",
      selector: (row) => row.phone,
    },
    {
      name: "Action",
      cell: (row) => [
        <Link to={`/dashboard/admin/student/view/${row._id}`}>
          <button className="btn btn-outline-success mx-1 btn-sm">
            <FaEye />
          </button>
        </Link>,
        <Link to={`/dashboard/admin/student/edit/${row._id}`}>
          <button className="btn btn-outline-primary mx-1 btn-sm">
            <FaEdit />
          </button>
        </Link>,
        <button
          className="btn btn-outline-danger mx-1 btn-sm"
          onClick={() => handleBlock(row._id)}
        >
          <FaTrash />
        </button>,
      ],
    },
  ];

  useEffect(() => {
    const result = users.filter((c) => {
      return c.firstName?.toLowerCase().match(search?.toLowerCase());
    });
    setfilterUsers(result);
  }, [search, users]);

  return (
    <Fragment>
      <ToastContainer />
      <PageBreadcrumb pageTitle={"Students"} actionButtons={actionButtons} />
      <Row>
        <Col sm={12}>
          <Card>
            <Card.Body>
              <DataTable
                columns={columns}
                data={filterUsers}
                pagination
                fixedHeader
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
}

export default StudentList;
