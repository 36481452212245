import { axiosPrivate, axiosPublic } from "./axios";

export const getAllCourse = async () => {
  const res = await axiosPrivate.get(`/course/list`);
  return res.data;
};

export const getCourse = async (id) => {
  const res = await axiosPrivate.get(`/course/${id}`);
  return res.data;
};

export const deleteCourse = async (id) => {
  const res = await axiosPrivate.delete(`/course/remove/${id}`);
  return res.data;
};

export const updateCourse = async (id, course) => {
  const res = await axiosPrivate.post(`/course/update/${id}`, course);
  return res.data;
};

export const AddCourse = async (course) => {
  const res = await axiosPrivate.post(`/course/create`, course);
  return res.data;
};

export const getAllClassList = async (id) => {
  const res = await axiosPrivate.get(`/course/class/list/${id}`);
  return res.data;
};
