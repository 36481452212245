import react, { useState } from "react";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import userImage from "../../../images/R.jfif";
import {
  fetchStudent,
  UpdateStudentProfile,
} from "../../../networkcalls/UsersApi";
import strings from "../../../utils/strings";
const StudentEdit = () => {
  const [cookie, setCookie] = useCookies(["user"]);
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [fnameErrorMessage, setFnameErrorMessage] = useState();
  const [lnameErrorMessage, setLnameErrorMessage] = useState();
  const [genderErrorMessage, setGenderErrorMessage] = useState();
  const [emailErrorMessage, setEmailErrorMessage] = useState();
  const [phoneErrorMessage, setPhoneErrorMessage] = useState();
  const [passwordErrorMessage, setPasswordErrorMessage] = useState();
  const [cpasswordErrorMessage, setCpasswordErrorMessage] = useState();
  const [imageErrorMessage, setImageErrorMessage] = useState();
  const [parentContacterr, setParetContactErr] = useState();
  const [parentNameErr, setparentNameErr] = useState();

  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    gender: "",
    dob: "",
    email: "",
    phone: "",
    // linkedinUrl: "",
    // youtubeUrl: "",
    // instagramUrl: "",
    // facebookUrl: "",
    about: "",
    parentName: "",
    parentContact: "",
  });

  const { studentid } = useParams();
  let navigate = useNavigate();
  //profileImage hook to store image in database
  const [profileImage, setProfileImage] = useState();
  //profilePic hook to change state of image
  const [profilePic, setProfilePic] = useState(userImage);
  const setImage = (e) => {
    const file = e.target.files[0];
    if ((file.type.slice(6) === "png") | "jpg" | "jpeg") {
      setImageErrorMessage("");
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (reader.result != null) {
          setProfilePic(reader.result.toString());
        }
      };
      setProfileImage(e.target.files[0]);
    } else {
      setImageErrorMessage("Please select image type png, jpge, jpg only");
    }
  };

  useEffect((e) => {
    fetchStudent(studentid).then(
      (res) => {
        console.log(res);
        setData(res.data);
        if (res.data.profilePic !== null) {
          setProfilePic(strings.IMG_URL + "users/" + res.data.profilePic);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);

  function handleChange(e) {
    e.preventDefault();

    if (!/^[a-zA-Z ]*$/.test(data.firstName)) {
      setFnameErrorMessage("Only alphabets are allowed!");
    }
    if (!/^[a-zA-Z ]*$/.test(data.lastName)) {
      setLnameErrorMessage("Only alphabets are allowed!");
    }
    if (!/^[a-zA-Z ]*$/.test(data.parentName)) {
      setparentNameErr("Only alphabets are allowed!");
    }
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
  }

  function onUpdate(e) {
    e.preventDefault();
    let validate = true;

    if (data.firstName.trim() === "") {
      setFnameErrorMessage("Please Enter First Name");
    }

    if (data.lastName.trim() === "") {
      setLnameErrorMessage("Please Enter Last Name");
    }
    if (data.gender === "") {
      setGenderErrorMessage("Please select Gender");
    }
    if (data.phone === "") {
      setPhoneErrorMessage("Please Enter Phone No.");
    }
    if (data.parentName === "") {
      setparentNameErr("Please Enter Name");
    }
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(data.email)) {
      setEmailErrorMessage("Please Enter valid Email");
      validate = false;
    }

    if (
      !/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(data.phone)
    ) {
      setPhoneErrorMessage("Please Enter Valid Phone No!");
      validate = false;
    }
    if (
      !/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(
        data.parentContact
      )
    ) {
      setParetContactErr("Please Enter Valid Phone No!");
      validate = false;
    }

    if (validate) {
      setFnameErrorMessage("");
      setLnameErrorMessage("");
      setPhoneErrorMessage("");
      setEmailErrorMessage("");
      setparentNameErr("");
      setGenderErrorMessage("");
      setParetContactErr("");

      var formData = new FormData();
      formData.append("firstName", data.firstName);
      formData.append("lastName", data.lastName);
      formData.append("gender", data.gender);
      formData.append("dob", data.dob);
      formData.append("email", data.email);
      formData.append("phone", data.phone);
      formData.append("profilePic", profileImage);
      formData.append("about", data.about);
      formData.append("parentName", data.parentName);
      formData.append("parentContact", data.parentContact);

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      UpdateStudentProfile(studentid, formData, config).then((res) => {
        if (res.err === 200) {
          toast.success("Profile Updated Successfully!");
          setTimeout(() => {
            navigate("/dashboard/admin/student/list");
          }, 3000);
        }
      });
    }
  }

  return (
    <div className="">
      <div className="container ">
        <div className="row mt-4">
          <div className="col-lg-4 card rounded-3 p-3">
            <h5 className="p-2">Edit Profile</h5>
            <form className="form-group">
              <lable>First Name</lable>
              <input
                id="firstName"
                type="text"
                value={data.firstName}
                className="form-control form-control-sm"
                onChange={(e) => handleChange(e)}
                placeholder="Enter First Name"
                autoComplete="off"
              />
              <span className="text-danger">{fnameErrorMessage}</span>
              <br />
              <lable>Last Name</lable>
              <input
                id="lastName"
                type="text"
                value={data.lastName}
                className="form-control form-control-sm"
                onChange={(e) => handleChange(e)}
                placeholder="EnterLast Name"
                autoComplete="off"
              />
              <span className="text-danger">{lnameErrorMessage}</span>
              <br />
              <lable>Gender</lable>
              <select
                className="form-control form-control-sm"
                value={data.gender}
                onChange={(e) => handleChange(e)}
                id="gender"
              >
                <option selsected>select</option>
                <option value="male" selsected>
                  Male
                </option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>
              <span className="text-danger">{genderErrorMessage}</span>
              <br />
              <lable>DOB</lable>
              <input
                id="dob"
                type="date"
                className="form-control form-control-sm text-box single-line"
                value={data.dob?.slice(0, 10)}
                onChange={(e) => handleChange(e)}
                autoComplete="off"
              />{" "}
              <br />
              <lable>Email</lable>
              <input
                id="email"
                type="text"
                className="form-control form-control-sm"
                value={data.email}
                placeholder="Enter Email address"
                onChange={(e) => handleChange(e)}
                autoComplete="off"
              />
              <span className="text-danger">{emailErrorMessage}</span>
              <br />
              {/* <lable>Phone</lable>
              <input
                id="phone"
                type="text"
                className="form-control form-control-sm"
                value={data.phone}
                placeholder="Enter Phone number"
                onChange={(e) => handleChange(e)}
                autoComplete="off"
              />
              <span className="text-danger">{phoneErrorMessage}</span>
              <br /> */}
              {/* <lable>Parent Name</lable>
              <input
                id="parentName"
                type="text"
                className="form-control form-control-sm"
                value={data.parentName}
                placeholder="Enter Parent Name"
                onChange={(e) => handleChange(e)}
                autoComplete="off"
              />
              <span className="text-danger">{parentNameErr}</span>
              <br />
              <lable>Parent Contact</lable>
              <input
                id="parentContact"
                type="number"
                value={data.parentContact}
                onChange={(e) => handleChange(e)}
                className="form-control form-control-sm"
                placeholder="Enter Parent Contact No."
                autoComplete="off"
              /> */}
              {/* <span className="text-danger">{parentContacterr}</span>
              <br /> */}
              <label>About</label>
              <textarea
                id="about"
                value={data.about}
                onChange={(e) => {
                  handleChange(e);
                }}
                className="form-control form-control-sm"
              ></textarea>{" "}
              <br />
              {/* Password is out of data due to encryption */}
              <lable>Profile Pic</lable>&nbsp;
              <br />
              <br />
              <img
                src={profilePic}
                alt="Profile pic"
                style={{ width: "150px", height: "150px" }}
              />
              <br />
              <br />
              {/* Profile Pic */}
              <input
                id="profilePic"
                onChange={setImage}
                type="file"
                className="form-control"
                placeholder="choose file"
                accept="image/*"
              />
              <span className="text-danger">{imageErrorMessage}</span>
              {/* submit */}
              <div className=" mb-3 mt-3">
                <input
                  id=""
                  type="button"
                  className="btn btn-primary text-center"
                  onClick={(e) => {
                    onUpdate(e);
                  }}
                  value="Update"
                />
                <ToastContainer />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentEdit;
