import { axiosPrivate, axiosPublic } from "./axios";

export const createTimeSlot = async (data) => {
  const res = await axiosPrivate.post(`/weekschedule/createslot`, data);
  return res.data;
};

export const updateTimeSlot = async (id, data) => {
  const res = await axiosPrivate.post(`/weekschedule/updateslot/${id}`, data);
  return res.data;
};

export const removeTimeSlot = async (id) => {
  const res = await axiosPrivate.delete(`/weekschedule/removeslot/${id}`);
  return res.data;
};

export const readTimeSlot = async (id) => {
  const res = await axiosPrivate.get(`/weekschedule/readslot/${id}`);
  return res.data;
};

export const fetchTutorSlots = async (data) => {
  const res = await axiosPrivate.post(`/weekschedule/fetch/tutor/slots`, data);
  return res.data;
};
// fetching slots of todays by using sessionType, tutorId, sessionDate
export const fetchTodaysScheduleOfTutor = async (pdata) => {
  const res = await axiosPrivate.post(`/weekschedule/fetch/todaysschedule`, pdata);
  return res.data;
}

// fetching dates from now Date to onwards by sessionType and tutorId
export const fetch_SessionDates = async (data) => {
  const res = await axiosPrivate.post(`/weekschedule/fetch/fetchsessiondates`, data);
  return res.data;
}
