import React, { useContext } from "react";
import LayoutContext from "../context/LayoutContext";
import Header from "../components/Header";
import { Outlet } from "react-router-dom";
function MainContent() {
  const { activeMenu, handleActiveMenu } = useContext(LayoutContext);
  return (
    <main
      className={activeMenu ? "right-content active" : "right-content"}
      
    >
      <Header  handleActiveMenu={handleActiveMenu} activeMenu={activeMenu} />
      <Outlet />
    </main>
  );
}

export default MainContent;
