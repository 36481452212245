import { createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const LayoutContext = createContext();

export const LayoutPrivider = ({ children }) => {
  const [activeMenu, setActive] = useState(false);
  const handleActiveMenu = () => {
    setActive((pre) => !pre);
  };

  let contextData = {
    activeMenu,
    handleActiveMenu,
  };

  return (
    // <></>
    <LayoutContext.Provider value={contextData}>
      {children}
    </LayoutContext.Provider>
  );
};


export default LayoutContext;
