import moment from 'moment/moment';
import React, { useEffect } from 'react'
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { coursePurchaseList } from '../../networkcalls/purchasedCourse';
import { fetchsyllyabusByCourseId, fetchTutorBySyllabusCodeandCourseId } from '../../networkcalls/purchasedSyllabus';
import { fetchTodaysScheduleOfTutor, fetch_SessionDates } from '../../networkcalls/WeekScheduleApi';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import { Book_a_class } from '../../networkcalls/Events';
import swal from 'sweetalert';
import { toast, ToastContainer } from 'react-toastify';

const ClassBooking = () => {
    const [selectedSlot, setSelectedSlot] = useState([]);
    const [courseList, setCourseList] = useState();
    const [date, setDate] = useState();
    const [syllabusList, setSyllabusList] = useState();
    const [selectedDate, setSelectedDate] = useState();
    const [courseId, setCourseId] = useState();
    const [tutorId, setTutorId] = useState();
    const [selectedCourse, setSelectedCourse] = useState();
    const [sessionDates, setSessionDates] = useState();
    const [selectedDateSchedule, setSelectedDateSchedule] = useState();
    const [classCode, setClassCode] = useState();
    const [selectedDateScheduleErr, setSelectedDateScheduleErr] = useState(false);


    // Error hooks - 
    const [selectcourseErr, setSelectCourseErr] = useState();
    const [syllabusErr, setSyllabusErr] = useState();
    const [selectedSlotErr, setSelectedSlotErr] = useState();
    const [dateErr, setDateErr] = useState();
    let studentId = useSelector(state => state.userData.userinfo._id);
    console.log("studentId", studentId);
    useEffect(() => {
        coursePurchaseList({ studentId: studentId }).then(
            (res) => {
                console.log("courseList", res)
                setCourseList(res.data);
            },
            (err) => {
                console.log(err);
            }
        );
    }, [selectedCourse]);

    const handleCourse = async (e) => {
        setSelectCourseErr("");
        // courseId === e.target.value
        if (e.target.value !== null) {
            //by the courseId syllabus is fetched
            await fetchsyllyabusByCourseId({ courseId: e.target.value }).then((res) => {
                setSyllabusList(res.data)
                setCourseId(e.target.value)
            }, (err) => {
                console.log(err)
            })
        }
    }

    let dates = [];

    const handleSyllabus = (e) => {
        setSyllabusErr("");
        // by the syllabusCode and courseId tutorid is fetched
        // classCode === syllabusCode
        setClassCode(e.target.value)
        const syllabusCode = e.target.value;
        if (syllabusCode !== null && courseId !== null) {
            let data = {
                syllabusCode: syllabusCode, courseId: courseId
            }
            fetchTutorBySyllabusCodeandCourseId(data).then((res) => {
                if (res.err === 200) {
                    console.log("For syllabus info", res)
                    setTutorId(res.data[0].tutorId)
                    let tutorId = res.data[0].tutorId
                    if (tutorId !== null) {
                        let data = {
                            tutorId: tutorId,
                            sessionType: "FREE",
                            // tutorId: "6305e70f33c0a5921f9c0b58",
                            // sessionType: "FREE"
                        }
                        fetch_SessionDates(data).then((res) => {
                            console.log("session date => ", res)
                            dates = res.data?.map((res) => res.sessionDate)
                            setSessionDates(dates)
                        })
                    }
                } else {
                    console.log("Error in fetching totor")
                }
            })
        }
    }

    const handleDate = (e) => {
        setSelectedSlotErr("")
        let selectedDate = new Date(e.target.value)
        const inputDate = moment(selectedDate, "ddd MMM DD YYYY HH:mm:ss z", "Asia/Kolkata")
        const outputDate = inputDate.utc().format("YYYY-MM-DDT18:30:00.000[Z]")
        console.log("outputDate", outputDate)
        console.log("selectedDate", selectedDate)
        // by selection of session date, tutor id and sessiiontype data will receive of time slots of tutor of that that day
        let pdata = {
            sessionDate: outputDate,
            tutorId: tutorId,    // use this id to check slots are available or not 6305e70f33c0a5921f9c0b58
            sessionType: "FREE" // BOTH or PAID
            // sessionDate: outputDate,
            // tutorId: "6305e70f33c0a5921f9c0b58",
            // sessionType: "FREE"
        }

        fetchTodaysScheduleOfTutor(pdata).then((res) => {
            console.log("Available slots for that day=>", res)
            if (res.err === 200) {
                setSelectedDateSchedule(res.data)
            }
            if (res.length === 0) {
                console.log("No data available")
                setSelectedDateScheduleErr(true)
            } else {
                setSelectedDateScheduleErr("");
            }
        }, (err) => {
            console.log(err)
        })
    }
    // console.log("selected courseId", courseId)
    // handle this function onclick of submit
    const handleBookAClass = (e) => {
        e.preventDefault();
        let validate = true;
        let postData = {
            courseId: courseId,
            classCode: classCode,
            tutorId: tutorId,
            eventId: selectedSlot,
            studentId: studentId
        }
        console.log("eventId", postData.eventId)
        if (postData.courseId === undefined) {
            setSelectCourseErr("Please select course")
            validate = false;

        }
        if (postData.classCode === undefined) {
            validate = false;
            setSyllabusErr("Please select syllabus")
        }
        if (postData.eventId === undefined || postData.eventId.length === 0) {
            validate = false;
            setSelectedSlotErr("Please select date and available slot")
        }

        if (validate) {
            Book_a_class(postData).then((res) => {
                if (res.err === 200) {
                    toast.success("Slot Booked successfully")
                    setTimeout(() => {
                        window.location.reload()
                    }, 3000)
                    // swal("success", "Slot Booked successfully", "success")
                } else {
                    swal("warning", "Class is not available", "warning")
                }
            }, (err) => {
                console.log(err)
            })
            console.log(postData)
        }
    }
    console.log(selectedSlotErr, "selectedSlotErr")
    // slot selection
    // select the slot
    const handleSlotClick = (eventId) => {
        setSelectedSlotErr("")
        if (eventId === selectedSlot) {
            setSelectedSlot(null);
        } else {
            setSelectedSlot(eventId);
            console.log(eventId)
        }
    };
    return (
        <div>
            <div className="container">
                <div className="row mt-3">
                    <h4>Class Booking</h4>
                    <div className="col-md-4 mt-2">
                        <span className='badge text-dark  bg-danger'>Need to change tutor Id to dynamic</span>
                        <form className="card p-4">
                            <div className='    '>
                                <label className=''>Select Course</label>
                                <select name="" id="" className='form-select' onChange={handleCourse}>
                                    <option value="">Select course</option>
                                    {
                                        courseList?.map((data, key) => {

                                            return <option key={key} value={data.courseId}>{data.courseTitle}</option>
                                        })
                                    }
                                </select>
                                <span className='text-danger'>{selectcourseErr}</span>
                            </div>
                            <div className='mt-2'>
                                <label className=''>Select Class</label>
                                <select name="" id="" className='form-select' onChange={handleSyllabus}>
                                    <option value="">Select class</option>
                                    {
                                        syllabusList?.map((data, key) => {
                                            return <option key={key} value={data.syllabusId}>{data.topicName}</option>
                                        })
                                    }
                                </select>
                                <span className='text-danger'>{syllabusErr}</span>
                            </div>
                            <div className='mt-2'>
                                <div className='mt-2'>
                                    <label htmlFor="">Choose Date</label>
                                    <input type="date" placeholder='Choose Date' className="form-control" onChange={e => handleDate(e)} />
                                </div>
                            </div>
                            <div className='mt-2'>
                                {
                                    selectedDateSchedule ? (<label htmlFor=''>Available tutor slots</label>) : (<></>)
                                }

                                <div className='row px-3'>
                                    {selectedDateSchedule?.map((data, index) => (
                                        <div className='col-3 m-1' style={{ cursor: 'pointer' }} key={index}>
                                            <div
                                                className={'card p-2 ' + (data._id === selectedSlot ? ' bg-primary text-white border border-danger' : 'border border-success')}
                                                onClick={() => handleSlotClick(data._id)}
                                            >
                                                <p><b>Start Class</b>  {data.timeStart}</p>
                                                <p><b>End Class  </b> {data.timeEnd}</p>
                                            </div>
                                        </div>
                                    ))}
                                    {
                                        selectedDateScheduleErr ? (<>
                                            <div className="card">
                                                <div className="card-body text-center">
                                                    <b className='text-danger '>Tutor is not available</b>
                                                </div>
                                            </div>
                                        </>) : (<></>)
                                    }
                                </div>
                                <span className='text-danger'>{selectedSlotErr}</span>
                            </div>
                            <div className='mt-2'>
                                <button className='btn btn-primary w-100' onClick={handleBookAClass}>Book a class</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}

export default ClassBooking;