import React, { useEffect, useState } from "react";
import { IoIosArrowDropdown } from "react-icons/io";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {fetchTutorChargesById,saveAndUpdateTutorCharges} from "../../../networkcalls/TutorChargesApi";
import { fetchTutor } from "../../../networkcalls/UsersApi";
import swal from "sweetalert";

const TutorPenaltyCharges = () => {
  let navigate = useNavigate();
  const { state } = useLocation();
  const { tutorId } = state;

  const [tutordata, setTutorData] = useState();
  const [loading, setLoading] = useState(false);

  const FreeSkipCount = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30,
  ];
  const [chargesData, setChargesData] = useState({
    tutorId: tutorId,
    paidClassCharge: "",
    freeClassCharge: "",
    paidClassPenalty: "",
    freeClassPenalty: "",
    maxPaidSkipCount: "",
    maxFreeSkipCount: "",
  });

  const [ errMsg,setErrorMsg]=useState();

  const handleChange = (e) => {
    // if(chargesData.freeClassCharge.toFixed(2)){
    //   setErrorMsg("Only Two decimals are allowed!!");
    //   // validate(false)
    // }else{
    //   setErrorMsg("");
    // }
    const newData = { ...chargesData };
    newData[e.target.id] = e.target.value;
    setChargesData(newData);
  };

  // to show user Data from userApi - Tutor - Every Tutor's info - email, phone, name.
  useEffect(() => {
    let TutorId = tutorId;
    fetchTutor(TutorId).then(
      (res) => {
        setTutorData(res.data);
      },
      (err) => {}
    );
  }, [tutorId]);

  // To show Tutor Charges Data in the field

  useEffect(() => {
    fetchTutorChargesById(tutorId).then(
      (res) => {
        setChargesData(res.data);
      },
      (err) => {
        console.log(err);
      }
    );
  }, [tutorId]);

  // Submit ChargesData -
  const submit = (e) => {
    e.preventDefault();
    
    let validate = true;

    
    if(validate){
      setLoading(true);
      saveAndUpdateTutorCharges(chargesData).then(
        (res) => {
          if (res.err === 200) {
            setLoading(false);
            swal(res.message, "Done!", "success").then((willdelete) => {
              if (willdelete) {
                setLoading(false);
                navigate("/dashboard/admin/tutor/list");
              }
            });
          }
          else{
            swal("Failure!!", res.message, "warning").then(setLoading(false));
          }
        },
        (err) => {
          swal("Server Error!!", "Something went wrong!..", "error").then(setLoading(false))
        }
      );  
    }
    
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-3"></div>
        <div className="col-lg-6 p-2">
          <div className="border border-dark container align-items-center pt-3 mt-5">
            <h4>Penalty Charges</h4>
            <br />
            <div className="row">
              <div className="col-lg-6 col-sm-6">
                <strong>
                  Tutor Name : {tutordata?.firstName} {tutordata?.lastName}
                </strong>
                <br />
                <strong>Tutor Phone No. : {tutordata?.phone}</strong>
              </div>
              <div className="col-lg-6 col-sm-6">
                <strong>Tutor Email : {tutordata?.email}</strong>
              </div>
            </div>
            <hr />
            <div className="container">
              <form className="">
                <div className="row">
                  <div className="col-md-6 mb-4">
                    <div className="form-outline pl-3">
                      <label className="form-label">Paid Class Charge</label>
                      <input
                        type="number"
                        className="form-control form-control-sm"
                        id="paidClassCharge"
                        onChange={(e) => handleChange(e)}
                        value={chargesData.paidClassCharge}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 mb-4">
                    <div className="form-outline pl-3">
                      <label className="form-label">Free Class Charge</label>
                      <input
                        type="number"
                        className="form-control form-control-sm"
                        id="freeClassCharge"
                        onChange={(e) => handleChange(e)}
                        value={chargesData.freeClassCharge}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 mb-4">
                    <div className="form-outline pl-3">
                      <label className="form-label">
                        Paid Class Penalty Charge
                      </label>
                      <input
                        type="number"
                        className="form-control form-control-sm"
                        id="paidClassPenalty"
                        onChange={(e) => handleChange(e)}
                        value={chargesData.paidClassPenalty}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 mb-4">
                    <div className="form-outline pl-3">
                      <label className="form-label">
                        Free Class Penalty Charge
                      </label>
                      <input
                        type="number"
                        className="form-control form-control-sm"
                        id="freeClassPenalty"
                        onChange={(e) => handleChange(e)}
                        value={chargesData.freeClassPenalty}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 mb-4">
                    <div className="form-outline pl-3">
                      <label className="form-label">
                        Paid Monthly Skip Count
                      </label>
                      <select
                        className="form-control form-control-sm"
                        value={chargesData.maxPaidSkipCount}
                        id="maxPaidSkipCount"
                        onChange={(e) => handleChange(e)}
                      >
                        <option selsected>
                          select <IoIosArrowDropdown />
                        </option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6 mb-4">
                    <div className="form-outline pl-3">
                      <label className="form-label">
                        Free Monthly Skip Count
                      </label>
                      <select
                        className="form-control form-control-sm"
                        value={chargesData.maxFreeSkipCount}
                        id="maxFreeSkipCount"
                        onChange={(e) => handleChange(e)}
                      >
                        <option selsected>
                          select <IoIosArrowDropdown />
                        </option>
                        {FreeSkipCount.map((num) => {
                          return <option value={num}>{num}</option>;
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6 mb-4">
                    <div className="form-outline pl-3">
                      {loading ? (
                     <button className="btn btn-success mr-3">
                      Please Wait..
                     </button>
                      ) : (
                        <button
                          className="btn btn-success mr-3"
                          type="button"
                          onClick={(e) => submit(e)}
                        >
                          {" "}
                          Submit{" "}
                        </button>
                      )}
                      <Link to={`/dashboard/admin/tutor/list`}>
                        <button className="btn btn-secondary">Back</button>
                      </Link>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="col-lg-3"></div>
      </div>
    </div>
  );
};

export default TutorPenaltyCharges;
