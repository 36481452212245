import React, { useState } from "react";
import Alert from "react-bootstrap/Alert";

const AlertBox = (props) => {
	const [show, setShow] = useState(true);

	setTimeout(() => {
		setShow(false);
	}, 3000);

	if (show) {
		return (
			<Alert variant={props.alertVariant} onClose={() => setShow(false)} dismissible>
				<h5>{props.alertTitle}</h5>
				<div dangerouslySetInnerHTML={{ __html: props.alertText }}></div>
			</Alert>
		);
	}
};

export default AlertBox;
