import { string } from "i/lib/util";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import { fetchTutor, UpdateTutorProfile } from "../../../networkcalls/UsersApi";
import strings from "../../../utils/strings";
import { ToastContainer, toast } from "react-toastify";

const TutorView = () => {
  let { tutorid } = useParams();
  const [approval, setApproval] = useState();
  const navigate = useNavigate();

  const [data, setData] = useState({
    isApproved: "",
  });
  const info = (id) => {
    fetchTutor(id)
      .then((res) => {
        if (res.err === 200) {
          setData(res.data);
          console.log(res.data);
        } else {
          swal("Error", "Network Error", "warning");
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    info(tutorid);
  }, []);

  const handleChange = (e) => {
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    console.log(e.target.value);
    setData(newData);
  };

  const handleClick = (e) => {
    let postData = {
      isApproved: data.isApproved,
    };
    UpdateTutorProfile(tutorid, postData)
      .then(
        (res) => {
          if (res.err === 200) {
            toast.success("Status Updated");
            setTimeout(() => {
              navigate("/dashboard/admin/tutor/list");
            }, 3000);
          } else {
            toast.error(`Error!`);
          }
        },
        (err) => {
          console.log(err);
        }
      )
      .catch();
  };

  return (
    <div>
      <div className="container">
        <ToastContainer />

        <div className="row">
          <div className="col-md-4 p-3 card rounded-3">
            <div className="">
              <div className="col-md-12">
                {" "}
                <b>Name</b>{" "}
              </div>
              <div className="col-md-12">
                {" "}
                {data?.firstName} {data?.lastName}
              </div>
            </div>
            <div className="mt-3">
              <div className="col-md-12">
                {" "}
                <b>Email</b>{" "}
              </div>
              <div className="col-md-12"> {data?.email}</div>
            </div>
            <div className="mt-3">
              <div className="col-md-12">
                {" "}
                <b>Phone</b>{" "}
              </div>
              <div className="col-md-12">{data?.phone}</div>
            </div>
            <div className="mt-3">
              <div className="col-md-12">
                {" "}
                <b>UpdatedAt</b>{" "}
              </div>
              <div className="col-md-12"> {data?.updatedAt}</div>
            </div>
            <div className="mt-3">
              <div className="col-md-12">
                {" "}
                <b>Gender</b>{" "}
              </div>
              <div className="col-md-12">{data?.gender}</div>
            </div>
            <div className="mt-3">
              <div className="col-md-12">
                {" "}
                <b>Facebook Url</b>{" "}
              </div>
              <div className="col-md-12">{data?.facebookUrl}</div>
            </div>
            <div className="mt-3">
              <div className="col-md-12">
                {" "}
                <b>Instagram Url</b>{" "}
              </div>
              <div className="col-md-12"> {data?.instagramUrl}</div>
            </div>
            <div className="mt-3">
              <div className="col-md-12">
                {" "}
                <b>Youtube Url</b>{" "}
              </div>
              <div className="col-md-12"> {data?.youtubeUrl}</div>
            </div>
            <div className="mt-3">
              <div className="col-md-12">
                {" "}
                <b>Linkedin Url</b>{" "}
              </div>
              <div className="col-md-12"> {data?.linkedinUrl}</div>
            </div>
            <div className="mt-3">
              <div className="col-md-12">
                {" "}
                <b>Last Login Date</b>{" "}
              </div>
              <div className="col-md-12"> {data?.lastLoginDate}</div>
            </div>
            <div className="mt-3">
              <div className="col-md-12">
                {" "}
                <b>Provile Pic</b>{" "}
              </div>
              <div className="col-md-12">
                <div className="col-md-3">
                  <img
                    src={
                      data?.profilePic ? (
                        strings.IMG_URL + "users/" + data.profilePic
                      ) : (
                        <i className="bi bi-person"></i>
                      )
                    }
                  />
                </div>
              </div>
              <div className="mt-3">
                <div className="col-md-12">
                  {" "}
                  <b>Request Status</b>{" "}
                </div>
                <div className="col-md-12">
                  <select
                    className="form-control form-control-sm"
                    name=""
                    value={data?.isApproved}
                    onChange={(e) => handleChange(e)}
                    id="isApproved"
                  >
                    <option value="1">Approved</option>
                    <option value="0">Pending</option>
                    <option value="2">Rejected</option>
                  </select>
                </div>
              </div>
              <div className="col-md-12 mt-3">
                <div className="col-md-12">
                  <div className="col-md-12">
                    {" "}
                    <b>Created At</b>{" "}
                  </div>
                  <div className="col-md-12">{data?.createdAt}</div>
                </div>
              </div>
            </div>
            <div className="mt-3">
              <div className="col-md-12">
                <button onClick={handleClick} className="btn btn-primary">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TutorView;
