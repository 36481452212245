import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";


const AccessTokenExp = () => {
    let navigate = useNavigate()
    const [cookie,setCookie] = useCookies(['accessToken']);

    let AccessToken = cookie.accessToken;

  return AccessToken;
}

export default AccessTokenExp;
