import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import swal from "sweetalert";
import {
  getBankDetailsByIfsc,
  submitDetails,
} from "../../networkcalls/BankApi";

const BankingDetailes = () => {
  const [bankName, setBankName] = useState();
  const [accountName, setAccountName] = useState();
  const [ifsc, setIfsc] = useState();
  const [accountNumber, setAccountNumber] = useState();
  const [bankDetails, setBakDetails] = useState();

  //errors
  const [accountNameErr, setAccountNameErr] = useState();
  const [accountNumberErr, setAccountNumberErr] = useState();
  const [ifscErr, setIfscErr] = useState();

  let userData = useSelector((state) => state.userData.userinfo);
  let userId = userData._id;
  const handleBankDetails = async (e) => {
    e.preventDefault();
    let validate = true;
    if (ifsc === "") {
      setIfscErr("Please enter IFSC code");
      validate = false;
    }

    if (validate) {
      await getBankDetailsByIfsc({ ifsc })
        .then(
          (res) => {
            if (res.err === 200) {
              setIfscErr("");
              setBakDetails(res);
              setBankName(res.data.BANK);
            } else {
              swal("Warning", res.data, "warning");
            }
          },
          (err) => {
            console.log(err);
          }
        )
        .catch();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let validate = true;

    if (accountName?.length < 3) {
      setAccountNameErr("Please enter valid Name");
      validate = false;
    }
    if (!/^\d{9,18}$/.test(accountNumber)) {
      setAccountNumberErr("Please enter valid account Number");
      validate = false;
    }

    let data = {
      userId: userId,
      bankName: bankName,
      ifsc: ifsc,
      accountName: accountName,
      accountNumber: accountNumber,
    };
    if (validate) {
      setAccountNameErr("");
      setAccountNumberErr("");
      submitDetails(data).then(
        (res) => {
          console.log(res);
          if (res.err === 200) {
            toast.success(res.message);
            setTimeout(() => {
              setBakDetails(!bankDetails);
              setIfsc("");
              setAccountName("");
              setAccountNumber("");
            }, 3000);
          } else {
            toast.error(res.message);
          }
        },
        (err) => {}
      );
    }
  };
  return (
    <div className="container mt-5">
      <div className="col-lg-6 col-xl-6 col-md-6 col-sm-6 col-12">
        <div className="card bg-card-shadow">
          <div className="card-body" style={{ backgroundColor: "white" }}>
            <h5 className="card-title text-start">Bank Details</h5>
            <form className="row g-3 text-start">
              {!bankDetails ? (
                <>
                  <div className="col-12">
                    <label for="inputNumber" className="form-label">
                      Enter IFSC code
                    </label>
                    <input
                      type="Text"
                      value={ifsc}
                      onChange={(e) => setIfsc(e.target.value)}
                      className="form-control"
                      id="inputNumber"
                      placeholder="IFSC"
                    />
                    <span className="text-danger">{ifscErr}</span>
                  </div>
                  <div className="text-start">
                    <button
                      type="submit"
                      onClick={handleBankDetails}
                      className="btn btn-primary "
                    >
                      Get Bank details
                    </button>{" "}
                  </div>
                </>
              ) : (
                <>
                  <div className="col-12">
                    <label for="inputNumber" className="form-label">
                      Enter IFSC code
                    </label>
                    <input
                      type="Text"
                      disabled
                      value={ifsc}
                      onChange={(e) => setIfsc(e.target.value)}
                      className="form-control"
                      id="inputNumber"
                      placeholder="IFSC"
                    />
                    <span className="text-danger">{ifscErr}</span>
                  </div>{" "}
                  <div className="col-12">
                    <label for="inputNanme4" className="form-label text-start">
                      Bank Name
                    </label>
                    <input
                      type="text"
                      value={bankName}
                      disabled
                      onChange={(e) => setBankName(e.target.value)}
                      className="form-control"
                      id="inputNanme4"
                      placeholder="Bank Name"
                    />
                  </div>
                  <div className="col-12">
                    <label for="inputAccountName" className="form-label">
                      Account Name
                    </label>
                    <input
                      type="text"
                      value={accountName}
                      className="form-control"
                      onChange={(e) => setAccountName(e.target.value)}
                      id="inputAccountName"
                      placeholder="Account Name"
                    />
                    <span className="text-danger">{accountNameErr}</span>
                  </div>
                  <div className="col-12">
                    <label for="inputAccountNumber" className="form-label">
                      Account Number
                    </label>
                    <input
                      type="text"
                      value={accountNumber}
                      className="form-control"
                      onChange={(e) => setAccountNumber(e.target.value)}
                      id="inputAccountNumber"
                      placeholder="Account Number"
                    />
                    <span className="text-danger">{accountNumberErr}</span>
                  </div>
                  {/* <div className="col-12"> 
            <input className="form-check-input" type="radio" name="gridRadios" id="gridRadios2" value="option2"/>&nbsp;&nbsp;&nbsp;
            <label for="inputAddress" className="form-label">Savings Account </label>&nbsp;&nbsp;&nbsp;
            <input className="form-check-input" type="radio" name="gridRadios" id="gridRadios2" value="option2"/>&nbsp;&nbsp;&nbsp;
            <label for="inputAddress" className="form-label">Current Account </label>
          </div> */}
                  <div className="text-start">
                    <button
                      type="submit"
                      onClick={handleSubmit}
                      className="btn btn-primary "
                    >
                      Submit
                    </button>{" "}
                    &nbsp;&nbsp;&nbsp;
                    {/* <button type="reset" className="btn btn-secondary">Reset</button> */}
                  </div>
                </>
              )}
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
      <br />
      <div className="col-lg-6 col-xl-6 col-md-6 col-sm-6 col-12"></div>
    </div>
  );
};

export default BankingDetailes;
