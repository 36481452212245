import React from "react";
//import useAuth from "../hooks/useAuth";
import { axiosPublic } from "../networkcalls/axios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const Verify = () => {
  // const { auth } = useAuth();
  const [buttonMsg, setButtonMsg] = React.useState("Send Verification Email");
  const [isDisabled, setIsDisabled] = React.useState(false);
  // console.log(auth);
  let userData = useSelector((state) => state.userData.userinfo);
  function notify(msg) {
    toast(msg, { position: toast.POSITION.TOP_CENTER });
  }

  function showVerficationButton() {
    return (
      <button
        disabled={isDisabled}
        onClick={sendVerificationEmail}
        type="button"
        className="btn btn-outline-success"
      >
        {buttonMsg}
      </button>
    );
  }

  function sendVerificationEmail() {
    setButtonMsg("Sending...");
    setIsDisabled(true);
    console.log("Sending email...");

    axiosPublic
      .post(
        "/users/sendVerificationEmail",
        JSON.stringify({ email: userData.email }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: false,
        }
      )
      .then((res) => {
        if (res.status === 200) {
          notify("Verification email sent");
        } else if (res.status === 400) {
          notify("User not Found");
        } else if (res.status === 409) {
          notify("Email already verified");
        } else {
          notify("Something went wrong");
        }
        setButtonMsg("Send Verification Email");
        setIsDisabled(false);
      })
      .catch((err) => {
        console.log(err);
        setButtonMsg("Send Verification Email");
        setIsDisabled(false);
      });
  }

  return (
    <div
      style={{
        flexDirection: "column",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <h1>Click on the button to Send Email Verfication</h1>
      {showVerficationButton()}
    </div>
  );
};

export default Verify;
