import React, { Fragment, useState, useEffect } from "react";
import { Calendar, DateLocalizer, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import PageBreadcrumb from "../../components/PageBreadcrumb";
import { fetchMyEvents } from "../../networkcalls/Events";
import { Row, Col, Card } from "react-bootstrap";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useSelector } from "react-redux";
const localizer = momentLocalizer(moment);

function TutorCalendar() {
  const [events, setEvents] = useState([]);
  let userData = useSelector((state) => state.userData.userinfo);
  const tutorId = userData._id;

  const fetchTutorCalendar = () => {
    fetchMyEvents(tutorId).then((res) => {
      if (res.err === 200) {
        let tutorSheet = [];
        const serverData = res.data;
        for (let index = 0; index < serverData.length; index++) {
          const d = serverData[index];
          console.log(serverData);
          const date = moment(d.sessionDate).format("YYYY-MM-DD");
          const startDate = date + " " + d.timeStart;
          const endDate = date + " " + d.timeEnd;
          const event = {
            id: d._id,
            title: d.sessionType,
            start: new Date(startDate),
            end: new Date(endDate),
            allDay: false,
          };
          tutorSheet.push(event);
        }
        setEvents(tutorSheet);
      }
    });
  };

  useEffect(() => {
    fetchTutorCalendar();
  }, []);

  return (
    <Fragment>
      <PageBreadcrumb pageTitle={"My Calendar"} actionButtons={[]} />
      <Row>
        <Col sm={12}>
          <Card>
            <Card.Body>
              <Calendar
                events={events}
                defaultDate={new Date()}
                localizer={localizer}
                defaultView="week"
                views={["week", "day"]}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
}

export default TutorCalendar;
