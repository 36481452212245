import React, { useRef } from "react";
import { useFormik } from "formik";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import useAuth from "../hooks/useAuth";
import illustration_signin from "../images/illustration_signin.svg";
import HeaderWithLogo from "../components/HeaderWithLogo";
import { FaCircleNotch } from "react-icons/fa";

import { Api_Signup } from "../networkcalls/AuthApi";
import swal from "sweetalert";
import { toast, ToastContainer } from "react-toastify";

const emailRegex =
  /^[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)*@[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)*$/;
const nameRegex = /^[a-zA-z].{3,20}$/;
const pwdRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const phoneRegex = /^[0-9]{10}$/;

function RegisterPage() {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [termsConds, setTermsAndConditions] = useState(false);
  const [role, setRole] = useState("student");
  const [objAlert, setObjAlert] = useState({
    alertVariant: "",
    title: "",
    text: "",
  });
  // const { setAuth } = useAuth();
  const navigate = useNavigate();
  const handleRole = (e) => {
    setRole(e.target.value);
  };

  const validate = (values) => {
    const errors = {};

    // firstName
    if (!values.firstName) errors.firstName = "Required";
    else if (!nameRegex.test(values.firstName))
      errors.firstName = "Only characters are allowed";
    // lastName
    if (!values.lastName) errors.lastName = "Required";
    else if (!nameRegex.test(values.lastName))
      errors.lastName = "Only characters are allowed";

    // email
    if (!values.email) errors.email = "Required";
    else if (!emailRegex.test(values.email))
      errors.email = "Invalid email address";

    // spacific to student
    if (role === "student") {
      // parent name
      if (!values?.parentName) errors.parentName = "Required";
      else if (!nameRegex.test(values.parentName))
        errors.parentName = "Only characters are allowed";

      // phone number
      if (!values.phone) errors.phone = "Required";
      else if (!phoneRegex.test(values.phone)) errors.phone = "Invalid number";

      // parent phone number
      if (!values.parentContact) errors.parentContact = "Required";
      else if (!phoneRegex.test(values.parentContact))
        errors.parentContact = "Invalid number";
    }
    // password
    if (!values.password) errors.password = "Required";
    else if (!pwdRegex.test(values.password)) errors.password = "seq";
    // conf-password
    if (!values.conf_password) errors.conf_password = "Required";
    else if (!pwdRegex.test(values.conf_password)) errors.conf_password = "seq";
    // passwords matching
    if (values.password !== values.conf_password)
      errors.password = "Password wont match. Please enter correct passwords";

    return errors;
  };

  const signUp = async (values) => {
    values["role"] = role;
    setLoading(true);
    await Api_Signup(values)
      .then((res) => {
        setShow(true);
        console.log(res);
        if (res.err === 200) {
          setLoading(false);
          toast.success("Registration successful!");
          setTimeout(() => {
            navigate("/login", { replace: true });
          }, 3000);

          // setAuth({ userData, accessToken });
        } else if (res.err === 300) {
          setLoading(false);
          setObjAlert({
            title: "Failed",
            text: res.message,
            alertVariant: "warning",
          });
        } else {
          setLoading(false);
          setObjAlert({
            title: "Failed",
            text: res.message,
            alertVariant: "danger",
          });
        }
      })
      .catch((ex) => {
        setLoading(false);
        console.log(ex);
      });
  };

  const student_formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      parentName: "",
      phone: "",
      parentContact: "",
      password: "",
      conf_password: "",
    },
    validate,
    onSubmit: signUp,
  });

  const tutor_formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      conf_password: "",
    },
    validate,
    onSubmit: signUp,
  });

  return (
    <div className="container-fluid">
      <div className="container">
        <HeaderWithLogo />
        <ToastContainer />

        {/* login content */}
        <div className="login-main  login-register">
          <div className="row align-items-center justify-content-between h-100">
            {/* Image/Fullfill */}
            <div className="col-md-5 col-lg-6 pr-5">
              <img
                className="img-fluid"
                src={illustration_signin}
                alt="sign-up svg"
              />
            </div>

            {/* Register Form */}
            <div className="col-md-7 col-lg-6 py-3">
              <div className="pb-4">
                <h3 className="fs-3 fw-normal">Sign Up</h3>
              </div>

              <div className="text-center mb-2 d-flex gap-2 align-items-center">
                <small className="text-muted ">
                  Sign-up for <strong className="text-danger">*</strong>
                </small>
                <div className="student-role ml-2" onClick={handleRole}>
                  <label htmlFor="student">Student </label>
                  <input
                    type="radio"
                    value="student"
                    name="student"
                    id="student"
                    label="Student"
                    checked={role === "student" ? true : false}
                    className="d-inline-block ml-1"
                  />
                </div>
                <div className="tutor-role" onClick={handleRole}>
                  <label htmlFor="tutor">Tutor</label>
                  <input
                    type="radio"
                    value="tutor"
                    name="tutor"
                    label="Tutor"
                    id="tutor"
                    checked={role === "tutor" ? true : false}
                    className="d-inline-block ml-1"
                  />
                </div>
              </div>

              <form
                noValidate
                onSubmit={
                  role === "student"
                    ? student_formik.handleSubmit
                    : tutor_formik.handleSubmit
                }
              >
                <div className="row gap-3  mb-3">
                  {/* first name */}
                  <div className="form-floating col-md">
                    <input
                      type="text"
                      name="firstName"
                      value={
                        role === "student"
                          ? student_formik.values.firstName
                          : tutor_formik.values.firstName
                      }
                      onBlur={
                        role === "student"
                          ? student_formik.handleBlur
                          : tutor_formik.handleBlur
                      }
                      onChange={
                        role === "student"
                          ? student_formik.handleChange
                          : tutor_formik.handleChange
                      }
                      className="form-control"
                      id="firstName"
                      placeholder="firstName"
                    />
                    <label htmlFor="">First name</label>
                    {(role === "student"
                      ? student_formik.touched.firstName
                      : tutor_formik.touched.firstName) &&
                      (role === "student"
                        ? student_formik.errors.firstName
                        : tutor_formik.errors.firstName) && (
                        <div className="form-error-msg text-danger">
                          <span>
                            {role === "student"
                              ? student_formik.errors.firstName
                              : tutor_formik.errors.firstName}
                          </span>
                        </div>
                      )}
                  </div>
                  <div className="form-floating col-md">
                    <input
                      type="text"
                      name="lastName"
                      value={
                        role === "student"
                          ? student_formik.values.lastName
                          : tutor_formik.values.lastName
                      }
                      onBlur={
                        role === "student"
                          ? student_formik.handleBlur
                          : tutor_formik.handleBlur
                      }
                      onChange={
                        role === "student"
                          ? student_formik.handleChange
                          : tutor_formik.handleChange
                      }
                      className="form-control"
                      id="lastName"
                      placeholder="lastName"
                    />
                    <label htmlFor="">lastName</label>
                    {(role === "student"
                      ? student_formik.touched.lastName
                      : tutor_formik.touched.lastName) &&
                      (role === "student"
                        ? student_formik.errors.lastName
                        : tutor_formik.errors.lastName) && (
                        <div className="form-error-msg text-danger">
                          <span>
                            {role === "student"
                              ? student_formik.errors.lastName
                              : tutor_formik.errors.lastName}
                          </span>
                        </div>
                      )}
                  </div>
                  <div className="form-floating ">
                    <input
                      type="email"
                      name="email"
                      value={
                        role === "student"
                          ? student_formik.values.email
                          : tutor_formik.values.email
                      }
                      onBlur={
                        role === "student"
                          ? student_formik.handleBlur
                          : tutor_formik.handleBlur
                      }
                      onChange={
                        role === "student"
                          ? student_formik.handleChange
                          : tutor_formik.handleChange
                      }
                      className="form-control"
                      id="email"
                      placeholder="name@example.com"
                    />
                    <label htmlFor="">Email address</label>
                    {(role === "student"
                      ? student_formik.touched.email
                      : tutor_formik.touched.email) &&
                      (role === "student"
                        ? student_formik.errors.email
                        : tutor_formik.errors.email) && (
                        <div className="form-error-msg text-danger">
                          <span>
                            {role === "student"
                              ? student_formik.errors.email
                              : tutor_formik.errors.email}
                          </span>
                        </div>
                      )}
                    {/* {formik.touched.email && formik.errors.email && <div className="form-error-msg text-danger"><span>{formik.errors.email}</span></div>} */}
                  </div>
                </div>
                <div
                  className={`row gap-3 mb-3 ${
                    role === "tutor" ? "d-none" : ""
                  }`}
                >
                  <div className="form-floating">
                    <input
                      type="text"
                      name="parentName"
                      value={student_formik.values.parentName}
                      onBlur={student_formik.handleBlur}
                      onChange={student_formik.handleChange}
                      className="form-control"
                      id="temail"
                      placeholder="name@example.com"
                    />
                    <label htmlFor="">Parent name</label>
                    {student_formik.touched.parentName &&
                      student_formik.errors.parentName && (
                        <div className="form-error-msg text-danger">
                          <span>{student_formik.errors.parentName}</span>
                        </div>
                      )}
                  </div>
                  <div className="form-floating col-md">
                    <input
                      type="text"
                      name="parentContact"
                      value={student_formik.values.parentContact}
                      onBlur={student_formik.handleBlur}
                      onChange={student_formik.handleChange}
                      className="form-control"
                      id="pphoneno"
                      placeholder="Parent phone number"
                    />
                    <label htmlFor="">Phone number</label>
                    {student_formik.touched.parentContact &&
                      student_formik.errors.parentContact && (
                        <div className="form-error-msg text-danger">
                          <span>{student_formik.errors.parentContact}</span>
                        </div>
                      )}
                  </div>
                  <div className="form-floating col-md">
                    <input
                      type="text"
                      name="phone"
                      value={student_formik.values.phone}
                      onBlur={student_formik.handleBlur}
                      onChange={student_formik.handleChange}
                      className="form-control"
                      id="pemail"
                      placeholder="name@example.com"
                    />
                    <label htmlFor="">Parent phone number</label>
                    {student_formik.touched.phone &&
                      student_formik.errors.phone && (
                        <div className="form-error-msg text-danger">
                          <span>{student_formik.errors.phone_number}</span>
                        </div>
                      )}
                  </div>
                </div>
                <div className="form-group mb-3">
                  <div className="row gap-3">
                    <div className="form-floating col-md">
                      <input
                        type="password"
                        name="password"
                        value={
                          role === "student"
                            ? student_formik.values.password
                            : tutor_formik.values.password
                        }
                        onBlur={
                          role === "student"
                            ? student_formik.handleBlur
                            : tutor_formik.handleBlur
                        }
                        onChange={
                          role === "student"
                            ? student_formik.handleChange
                            : tutor_formik.handleChange
                        }
                        className="form-control"
                        id="pphone"
                        placeholder="Parent phone number"
                      />
                      <label htmlFor="">Passwords</label>
                      {(role === "student"
                        ? student_formik.touched.password
                        : tutor_formik.touched.password) &&
                        (role === "student"
                          ? student_formik.errors.password
                          : tutor_formik.errors.password) &&
                        student_formik.errors.password !== "seq" &&
                        tutor_formik.errors.password !== "seq" && (
                          <div className="form-error-msg text-danger">
                            <span>
                              {role === "student"
                                ? student_formik.errors.password
                                : tutor_formik.errors.password}
                            </span>
                          </div>
                        )}
                      {/* {(role === "student" ? student_formik.touched.password : tutor_formik.touched.password) && (role === "student" ? student_formik.errors.password : tutor_formik.errors.password) && <div className="form-error-msg text-danger"><span>{(role === "student" ? student_formik.errors.password : tutor_formik.errors.password)}</span></div>} */}
                    </div>
                    <div className="form-floating col-md">
                      <input
                        type="password"
                        name="conf_password"
                        value={
                          role === "student"
                            ? student_formik.values.conf_password
                            : tutor_formik.values.conf_password
                        }
                        onBlur={
                          role === "student"
                            ? student_formik.handleBlur
                            : tutor_formik.handleBlur
                        }
                        onChange={
                          role === "student"
                            ? student_formik.handleChange
                            : tutor_formik.handleChange
                        }
                        className="form-control"
                        id="password"
                        placeholder="name@example.com"
                      />
                      <label htmlFor="">Confirm password</label>
                      {(role === "student"
                        ? student_formik.touched.conf_password
                        : tutor_formik.touched.conf_password) &&
                        (role === "student"
                          ? student_formik.errors.conf_password
                          : tutor_formik.errors.conf_password) &&
                        student_formik.errors.conf_password !== "seq" &&
                        tutor_formik.errors.conf_password !== "seq" && (
                          <div className="form-error-msg text-danger">
                            <span>
                              {role === "student"
                                ? student_formik.errors.conf_password
                                : tutor_formik.errors.conf_password}
                            </span>
                          </div>
                        )}
                      {/* {(formik.touched.password && formik.errors.password) && formik.errors.password !== "seq" ? <div className="form-error-msg text-danger"><span>{formik.errors.password}</span></div> : null} */}
                    </div>
                  </div>
                  <span
                    className={` d-inline-block  ${
                      (role === "student"
                        ? student_formik.touched.password
                        : tutor_formik.touched.password) &&
                      (role === "student"
                        ? student_formik.errors.password
                        : tutor_formik.errors.password) === "seq"
                        ? "text-danger"
                        : "text-muted"
                    }`}
                  >
                    Passwords must be combination of upper-case letter,
                    lower-case letter, numbers and special characters. Minimum 8
                    chraters long.
                  </span>
                </div>
                {/* <div className="row mb-3">
                  <div className="form-check d-flex gap-1 terms-and-condition flex-wrap">
                    <input
                      name="terms_and_conditions"
                      className="form-check-input"
                      onChange={
                        role === "student"
                          ? student_formik.handleChange
                          : tutor_formik.handleChange
                      }
                      onBlur={
                        role === "student"
                          ? student_formik.handleBlur
                          : tutor_formik.handleBlur
                      }
                      value={
                        role === "student"
                          ? student_formik.values.terms_and_conditions
                          : tutor_formik.values.terms_and_conditions
                      }
                      type="checkbox"
                      id="checkbox"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      I do here by accept all the terms and conditions and read
                      the privacy policy.
                    </label>
                    {(role === "student"
                      ? student_formik.errors.terms_and_conditions
                      : tutor_formik.errors.terms_and_conditions) && (
                      <div className="form-error-msg text-danger">
                        <span>
                          {role === "student"
                            ? student_formik.errors.terms_and_conditions
                            : tutor_formik.errors.terms_and_conditions}
                        </span>
                      </div>
                    )}
                  </div>
                </div> */}

                <div className="row mb-3 gap-3">
                  {/* submit btn */}
                  <div className="">
                    <button
                      disabled={loading ? true : false}
                      type="submit"
                      className="btn btn-primary d-block py-2 w-100"
                    >
                      {loading ? (
                        <FaCircleNotch className="icon_pulse mx-2" />
                      ) : (
                        ""
                      )}
                      <span className="d-inline-block mx-2">Signup</span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegisterPage;
