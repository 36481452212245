import React, { Fragment, useState, useEffect, useCallback } from "react";
import PageBreadcrumb from "../../../components/PageBreadcrumb";
import { Card, Row, Col, Modal } from "react-bootstrap";
import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
import { fetchAllCalendarData } from "../../../networkcalls/Events";
import moment from "moment";

const localizer = momentLocalizer(moment);

const Calendar = () => {
	const [events, setEvents] = useState([]);
	const [eventCollection, setEventCollection] = useState([]);
	const fetchCalendar = () => {
		fetchAllCalendarData().then((res) => {
			if (res.err === 200) {
				let calendarSheet = [];
				const serverData = res.data;
				setEventCollection(serverData);
				for (let index = 0; index < serverData.length; index++) {
					const d = serverData[index];
					const date = moment(d.sessionDate).format("YYYY-MM-DD");
					const startDate = date + " " + d.timeStart;
					const endDate = date + " " + d.timeEnd;
					const tutor = d.tutor[0];
					const event = {
						id: d._id,
						title: d.sessionType,
						start: new Date(startDate),
						end: new Date(endDate),
						allDay: false,
					};
					calendarSheet.push(event);
				}
				setEvents(calendarSheet);
			}
		});
	};

	useEffect(() => {
		fetchCalendar();
	}, []);

	const handleSelectEvent = useCallback((currentEvent) => {
		const filterData = eventCollection.filter((event) => event._id === currentEvent.id);
		if (filterData.length > 0) {
			const eventDetails = filterData.at(0);
			const tutor = eventDetails.tutor.at(0);
			console.log(eventDetails);
		}
	}, []);

	return (
		<Fragment>
			<PageBreadcrumb pageTitle={"Calendar"} actionButtons={[]} />
			<Row>
				<Col sm={12}>
					<Card>
						<Card.Body>
							<BigCalendar className="custom-calender" events={events} defaultDate={new Date()} localizer={localizer} defaultView="week" views={["week", "day"]} onSelectEvent={handleSelectEvent} />
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<Modal>
				<Modal.Header></Modal.Header>
				<Modal.Body></Modal.Body>
				<Modal.Footer></Modal.Footer>
			</Modal>
		</Fragment>
	);
};

export default Calendar;
