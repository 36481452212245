import React, { useRef, useEffect, useContext } from "react";
import { Navigate, NavLink } from "react-router-dom";
import LayoutContext from "../../context/LayoutContext";
import { AdminLinks, TutorLinks, StudentLinks } from "./SidebarData";
// import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
function Sidebar() {
  const { activeMenu } = useContext(LayoutContext);
  const sub_btn = useRef();
  const role = JSON.parse(sessionStorage.getItem("userRole"));
  let links = null;

  // console.log("in Sidebar");
  if (role) {
    links =
      role === "admin"
        ? AdminLinks
        : role === "student"
          ? StudentLinks
          : TutorLinks;
  }

  // -------  used for nested sidebar menu
  // const handleSubButton = (e) => {
  //     const subMenu = e.target.nextElementSibling
  //     e.target.classList.toggle('active');
  //     console.log(subMenu)
  //     subMenu.classList.toggle("show");
  //     e.target.getElementsByClassName('dropdown-icon')[0].classList.toggle('rotate');
  // }

  return (
    <aside
      className={`${activeMenu ? "active sidebar" : "sidebar"}`}
      style={{ backgroundColor: "#faf8ff" }}
    >
      <div className="sidebar-head p-3 d-flex justify-content-center align-items-center">
        <div
          className="brand-logo text-center"
          // style={{ backgroundColor: "#faf8ff" }}
        >
          <img
            src={activeMenu ? "/logo.png" : "/logo-sym.png"}
            alt="Atomic-Brain App Logo"
            style={{ height: "35px" }}
          />
        </div>
      </div>
      <div className="sidebar-menu">
        <div className={`menu ${activeMenu ? "" : "hide-menu"}`}>
          {links ? (
            links.map((link) => {
              return (
                <div className="item" key={link.key}>
                  <NavLink
                    className={(navData) => (navData.isActive ? "active" : "")}
                    to={`/dashboard${link.path}`}
                  >
                    {link.icon}
                    {activeMenu ? link.title : null}
                  </NavLink>
                </div>
              );
            })
          ) : (
            <Navigate to="/login" replace />
          )}

          {/* ====================  Nested sidebar menu ============== */}

          {/* <div className="item">
                        <NavLink className={(navData) => (navData.isActive ? 'active' : '')} to="main">
                            <MdOutlineDashboard />
                            {activeMenu ? "Dashboard" : null}
                        </NavLink>
                    </div>

                    <div className="item">
                        <NavLink className={(navData) => (navData.isActive ? 'active' : '')} to="calender"> <FaCalendar /> {activeMenu ? "Calender" : null}</NavLink>
                    </div> */}
          {/* <div className="item">
                        <a className="sub-btn" onClick={handleSubButton}> <FaCalendar />  {activeMenu ? ["Calender", <AiOutlineRight className='dropdown-icon' />] : null}</a>
                        <div className="sub-menu">
                            <a href="#" className="sub-item">Sub Item 01</a>
                            <a href="#" className="sub-item">Sub Item 02</a>
                            <a href="#" className="sub-item">Sub Item 03</a>
                        </div>
                    </div> */}

          {/* <div className="item">
                        <NavLink className={(navData) => (navData.isActive ? 'active' : '')} to="cources"><FaRegListAlt /> {activeMenu ? "Courses" : null} </NavLink>
                    </div> */}
          {/* <div className="item">
                        <a className="sub-btn" onClick={handleSubButton}><FaRegListAlt /> {activeMenu ? ["Courses", <AiOutlineRight className='dropdown-icon' />] : null} </a>
                        <div className="sub-menu">
                            <a href="#" className="sub-item">Sub Item 01</a>
                            <a href="#" className="sub-item">Sub Item 02</a>
                        </div>
                    </div> */}
          {/* <div className="item"><NavLink className={(navData) => (navData.isActive ? 'active' : '')} to="tutors"><FaUserTie /> {activeMenu ? "Tutors" : null}</NavLink></div>
                    <div className="item"><NavLink className={(navData) => (navData.isActive ? 'active' : '')} to="students"><FaUserGraduate /> {activeMenu ? "Students" : null}</NavLink></div> */}
        </div>
      </div>
    </aside>
  );
}

export default Sidebar;
