import React, { useEffect, useState, Fragment } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Col, Row, Card, Image } from "react-bootstrap";
import PageBreadcrumb from "../../../components/PageBreadcrumb";
import { Link } from "react-router-dom";
import { getCourse } from "../../../networkcalls/CourseApi";
import { useNavigate, useParams } from "react-router-dom";

const actionButtons = [
  {
    key: "backButton",
    variant: "outline-dark",
    text: "Back",
    navigateLink: "/dashboard/admin/course/list",
    isLink: true,
  },
];
const pageTitle = [
  {
    text: "View Course",
    fontWeight: 600,
    isLink: false,
  },
];

const CourseView = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [type, setType] = useState("");
  const [image, setImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [course, setCourse] = useState([]);
  const [price, setPrice] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();
  useEffect(() => {
    loadCourse();
  }, []);

  const loadCourse = () =>
    getCourse(id)
      .then((result) => {
        if (result.err === 200) {
          setTitle(result.data.title);
          setDescription(result.data.description);
          setType(result.data.type);
          setImage(result.data.image);
          setPrice(result.data.price);
        }
      })
      .catch((err) => {
        // error ocurred
      });
  const courseForm = () => (
    <Card>
      <Card.Body>
        <Row>
          <Col sm={12} className="mb-3">
            <label>Course Title</label>
            <input
              type="text"
              name="title"
              className="form-control"
              value={title}
            />
          </Col>
          <Col sm={12} className="mb-3">
            <label>Course Price</label>
            <input
              type="text"
              name="price"
              className="form-control"
              value={"₹ " + (price ? price : 0)}
            />
          </Col>
          <Col sm={12} className="mb-3">
            <label>Description</label>
            <textarea
              className="form-control"
              name="description"
              rows="3"
              value={description}
            ></textarea>
          </Col>
          <Col sm={12} className="mb-3">
            <label>Course Type</label>
            <select disabled={true} className="form-select" value={type}>
              <option value="Basic">Basic</option>
              <option value="Intermidiate">Intermidiate</option>
              <option value="Expert">Expert</option>
              <option value="All">All</option>
            </select>
          </Col>
          <Col sm={12} className="mb-3">
            <label>Course Image</label>
            <br />
            <Image
              src={image}
              style={{ height: "250px", width: "auto" }}
              alt="course image"
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );

  return (
    <Fragment>
      <ToastContainer />
      <PageBreadcrumb pageTitle={"View Course"} actionButtons={actionButtons} />
      <Row>
        <Col md={6}>{courseForm()}</Col>
      </Row>
    </Fragment>
  );
};

export default CourseView;
