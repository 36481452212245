import react, { useState } from "react";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import userImage from "../../images/R.jfif";
import {
  fetchStudent,
  UpdateStudentProfile,
} from "../../networkcalls/UsersApi";
import strings from "../../utils/strings";
const StudentEditProfile = () => {
  const [cookie, setCookie] = useCookies(["user"]);
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [fnameErrorMessage, setFnameErrorMessage] = useState();
  const [lnameErrorMessage, setLnameErrorMessage] = useState();
  const [genderErrorMessage, setGenderErrorMessage] = useState();
  const [emailErrorMessage, setEmailErrorMessage] = useState();
  const [phoneErrorMessage, setPhoneErrorMessage] = useState();
  const [passwordErrorMessage, setPasswordErrorMessage] = useState();
  const [cpasswordErrorMessage, setCpasswordErrorMessage] = useState();
  const [imageErrorMessage, setImageErrorMessage] = useState();
  const [parentContacterr, setParetContactErr] = useState();
  const [parentNameErr, setparentNameErr] = useState();

  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    gender: "",
    dob: "",
    email: "",
    phone: "",
    about: "",
    parentName: "",
    parentContact: "",
  });
  let userData = useSelector((state) => state.userData.userinfo);
  let studentId = userData._id;

  //profileImage hook to store image in database
  const [profileImage, setProfileImage] = useState();
  //profilePic hook to change state of image
  const [profilePic, setProfilePic] = useState(userImage);
  const setImage = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (reader.result != null) {
        setProfilePic(reader.result.toString());
      }
    };
    setProfileImage(e.target.files[0]);
  };
  console.log(profilePic);

  useEffect((e) => {
    fetchStudent(studentId).then(
      (res) => {
        console.log(res.data);
        setData(res.data);
        if (res.data.profilePic !== null) {
          setProfilePic(strings.IMG_URL + "users/" + res.data.profilePic);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);

  function handleChange(e) {
    e.preventDefault();
    if (!/^[a-zA-Z ]*$/.test(data.firstName)) {
      setFnameErrorMessage("Only alphabets are allowed!");
    }
    if (!/^[a-zA-Z ]*$/.test(data.lastName)) {
      setLnameErrorMessage("Only alphabets are allowed!");
    }
    if (!/^[a-zA-Z ]*$/.test(data.parentName)) {
      setparentNameErr("Only alphabets are allowed!");
    }
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
  }

  function onUpdate(e) {
    e.preventDefault();
    let validate = true;

    if (data.firstName.trim() === "") {
      setFnameErrorMessage("Please Enter First Name");
    }

    if (data.lastName.trim() === "") {
      setLnameErrorMessage("Please Enter Last Name");
    }
    if (data.gender === "") {
      setGenderErrorMessage("Please select Gender");
    }
    if (data.phone === "") {
      setPhoneErrorMessage("Please Enter Phone No.");
    }
    if (data.parentName === "") {
      setparentNameErr("Please Enter Phone No.");
    }
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(data.email)) {
      setEmailErrorMessage("Please Enter valid Email");
      validate = false;
    }
    if (
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
        password
      )
    ) {
      setPasswordErrorMessage(
        "The password must be 8 characters long, at least one uppercase letter & lowercase letter & one digit & one special character"
      );
      validate = false;
    }
    if (
      !/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(data.phone)
    ) {
      setPhoneErrorMessage("Please Enter Valid Phone No!");
    }
    if (
      !/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(
        data.parentContact
      )
    ) {
      setParetContactErr("Please Enter Valid Phone No!");
    }
    if (!profileImage.name.match(/\.(jpg|jpeg|png|gif)$/)) {
      setImageErrorMessage("Please select Only image.");
      validate = false;
    }
    if (!profilePic) {
      setImageErrorMessage("Please select image.");
      validate = false;
    }

    if (confirmPassword != password) {
      setCpasswordErrorMessage("Password Doesn't Match!!");
      validate = false;
    }

    if (validate) {
      var formData = new FormData();
      formData.append("firstName", data.firstName);
      formData.append("lastName", data.lastName);
      formData.append("gender", data.gender);
      formData.append("dob", data.dob);
      formData.append("email", data.email);
      formData.append("phone", data.phone);
      formData.append("profilePic", profileImage);
      formData.append("about", data.about);
      //   formData.append("linkedinUrl", data.linkedinUrl);
      //   formData.append("youtubeUrl", data.youtubeUrl);
      //   formData.append("instagramUrl", data.instagramUrl);
      //   formData.append("facebookUrl", data.facebookUrl);
      formData.append("parentName", data.parentName);
      formData.append("parentContact", data.parentContact);

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      UpdateStudentProfile(studentId, formData, config).then((res) => {
        console.log("data updated" + JSON.stringify(res.data));
      });

      toast("Profile Updated Successfully!");
    }
  }

  return (
    <div className="">
      <div className="container ">
        <div className="row mt-4">
          <div className="col-lg-6 card rounded-3 p-4">
            <h4 className="text-center p-2">Student Profile</h4>
            <form action="" className="form-group">
              <lable>First Name</lable>
              <input
                id="firstName"
                type="text"
                value={data.firstName}
                className="form-control form-control-sm"
                onChange={(e) => handleChange(e)}
                placeholder="Enter First Name"
                autoComplete="off"
              />
              <span className="text-danger">{fnameErrorMessage}</span>
              <br />
              <lable>Last Name</lable>
              <input
                id="lastName"
                type="text"
                value={data.lastName}
                className="form-control form-control-sm"
                onChange={(e) => handleChange(e)}
                placeholder="EnterLast Name"
                autoComplete="off"
              />
              <span className="text-danger">{lnameErrorMessage}</span>
              <br />
              <lable>Gender</lable>
              {/* <div>
                <input
                  type="radio"
                  value="male"
                  checked={data.gender === "male"}
                  onChange={(e) => handleChange(e)}
                  id="gender"
                />
                Male
              </div>
              <div>
                <input
                  type="radio"
                  value="female"
                  checked={data.gender === "female"}
                  onChange={(e) => handleChange(e)}
                  id="gender"
                />{" "}
                Female
              </div>
              <div>
                <input
                  type="radio"
                  value="transgender"
                  checked={data.gender === "transgender"}
                  onChange={(e) => handleChange(e)}
                  id="gender"
                />{" "}
                Transgender
              </div> */}
              <select
                className="form-control form-control-sm"
                value={data.gender}
                onChange={(e) => handleChange(e)}
                id="gender"
              >
                <option selsected>select</option>
                <option value="male" selsected>
                  Male
                </option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>
              <span className="text-danger">{genderErrorMessage}</span>
              <br />
              <lable>DOB</lable>
              <input
                id="dob"
                type="date"
                className="form-control form-control-sm text-box single-line"
                value={data.dob?.slice(0, 10)}
                onChange={(e) => handleChange(e)}
                autoComplete="off"
              />{" "}
              <br />
              <lable>Email</lable>
              <input
                id="email"
                type="text"
                className="form-control form-control-sm"
                value={data.email}
                placeholder="Enter Email address"
                onChange={(e) => handleChange(e)}
                autoComplete="off"
              />
              <span className="text-danger">{emailErrorMessage}</span>
              <br />
              <lable>Parent Name</lable>
              <input
                id="parentName"
                type="text"
                className="form-control form-control-sm"
                value={data.parentName}
                placeholder="Enter Email address"
                onChange={(e) => handleChange(e)}
                autoComplete="off"
              />
              <span className="text-danger">{parentNameErr}</span>
              <br />
              <lable>Parent Contact</lable>
              <input
                id="parentContact"
                type="number"
                value={data.parentContact}
                onChange={(e) => handleChange(e)}
                className="form-control form-control-sm"
                placeholder="Enter Parent Contact No."
                autoComplete="off"
              />
              <span className="text-danger">{parentContacterr}</span>
              <br />
              {/* Social links */}
              {/* <lable>Linked In</lable>
              <input
                id="linkedinUrl"
                type="text"
                value={data.linkedinUrl}
                onChange={(e) => handleChange(e)}
                className="form-control"
                placeholder="Enter Phone No."
                autoComplete="off"
              />
              <br />
              <lable>Youtube In</lable>
              <input
                id="youtubeUrl"
                type="text"
                value={data.youtubeUrl}
                onChange={(e) => handleChange(e)}
                className="form-control"
                placeholder="Enter Phone No."
                autoComplete="off"
              />
              <br />
              <lable>Instagram Url</lable>
              <input
                id="instagramUrl"
                type="text"
                value={data.instagramUrl}
                onChange={(e) => handleChange(e)}
                className="form-control"
                placeholder="Enter Phone No."
                autoComplete="off"
              />
              <br />
              <lable>Facebook Url</lable>
              <input
                id="facebookUrl"
                type="text"
                value={data.facebookUrl}
                onChange={(e) => handleChange(e)}
                className="form-control"
                placeholder="Enter Phone No."
                autoComplete="off"
              />
              <br /> */}
              {/* About */}
              <label>About</label>
              <textarea
                id="about"
                value={data.about}
                onChange={(e) => {
                  handleChange(e);
                }}
                className="form-control form-control-sm"
              ></textarea>{" "}
              <br />
              {/* Password is out of data due to encryption */}
              <lable>Password</lable>
              <input
                id="password"
                type="password"
                className="form-control form-control-sm"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                placeholder="Enter Password"
                autoComplete="off"
              />
              <span className="text-danger">{passwordErrorMessage}</span>
              <br />
              <lable>Confirm Password</lable>
              <input
                id="confirmpasswod"
                type="password"
                className="form-control form-control-sm"
                value={confirmPassword}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                }}
                placeholder="Enter confirm Password"
                autocomplete="off"
              />
              <span className="text-danger">{cpasswordErrorMessage}</span>
              <br />
              <lable>Profile Pic</lable>&nbsp;
              <br />
              <br />
              <img
                src={profilePic}
                alt={profilePic}
                style={{ width: "150px", height: "150px" }}
              />
              <br />
              <br />
              {/* Profile Pic */}
              <input
                id="profilePic"
                onChange={setImage}
                type="file"
                placeholder="choose file"
                accept="image/*"
              />
              <br />
              <span className="text-danger">{imageErrorMessage}</span>
              {/* submit */}
              <div className="text-center mb-3">
                <input
                  id=""
                  type="button"
                  className="btn btn-primary text-center"
                  onClick={(e) => {
                    onUpdate(e);
                  }}
                  value="Update"
                />
                <ToastContainer />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentEditProfile;
