import { axiosPrivate, axiosPublic } from "./axios";

export const getAllSyllabus = async (courseId) => {
	const res = await axiosPrivate.get("/syllabus/list?courseId=" + courseId);
	return res.data;
};

export const getSyllabus = async (id) => {
	const res = await axiosPrivate.get(`/syllabus/${id}`);
	return res.data;
};

export const deleteSyllabus = async (id) => {
	const res = await axiosPrivate.delete(`/syllabus/remove/${id}`);
	return res.data;
};

export const updateSyllabus = async (updateData) => {
	const res = await axiosPrivate.put(`/syllabus/update/${updateData.syllabusId}`, { updateData });
	return res.data;
};

export const AddSyllabus = async (insertData) => {
	const res = await axiosPrivate.post(`/syllabus/create`, insertData);
	return res.data;
};
