import { axiosPrivate } from "./axios";

export const fetchcourseTutor = async () => {
  let result = await axiosPrivate.get(`/admin/coursetutor/fetchcoursetutor`);
  return result;
};

export const createCourseTutor = async (data) => {
  let result = await axiosPrivate.post(`/admin/coursetutor/create`, data);
  return result;
};

export const updateCourseTutor = async (data) => {
  let result = await axiosPrivate.post(`/admin/coursetutor/update`, data);
  return result;
};

export const deleteCourseTutor = async (_id) => {
  let result = await axiosPrivate.post(`/admin/coursetutor/delete`, { _id });
  return result;
};

export const filterCourseTutor = async () => {
  let result = await axiosPrivate.get(`/student/coursetutor/filterdata`);
  return result.data;
};

export const fetchallcoursedetails = async (courseId, data) => {
  let result = await axiosPrivate.post(`/student/coursetutor/fetchdata/` + courseId, data);
  return result.data;
}

export const fetchTutorByCourseId = async (courseId) => {
  let result = await axiosPrivate.post(`/student/coursetutor/fetchtutor`, courseId);
  return result.data;
}
