import React from "react";
import HeaderWithLogo from "../components/HeaderWithLogo";
function UnautherizedPage() {
	return (
		<div className="container">
			<HeaderWithLogo />
			<main className="my-5">
				<h1 className="text-center fs-1 fw-normal">Unautherized</h1>
			</main>
		</div>
	);
}

export default UnautherizedPage;
