import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Card, Form, Row, Col } from "react-bootstrap";
import { FaCircleNotch } from "react-icons/fa";
import AlertBox from "../utils/AlertBox";
import { Api_updatePassword } from "../networkcalls/update";
import PageBreadcrumb from "../components/PageBreadcrumb";

const pwdRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;

const Signup = () => {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();

	const [loading, setLoading] = useState(false);
	const [validPasswords, setValidPasswords] = useState("");
	const [show, setShow] = useState(false);
	const [objAlert, setObjAlert] = useState({
		alertVariant: "",
		title: "",
		text: "",
	});

	const onSubmit = async (data) => {
		if (data.newPassword !== data.conPassword) {
			setValidPasswords("Password wont match. Please enter correct passwords");
			return;
		} else {
			setValidPasswords("");
		}
		setLoading(true);
		data.userId = localStorage.getItem("id");

		console.log(data);
		await Api_updatePassword(data).then((res) => {
			setShow(true);
			console.log("This is the response", res);
			if (res.err === 200) {
				const msg = `<small className='text-center'> Password Updated Sucessfully </small>`;
				setObjAlert({
					title: "Success",
					text: msg,
					alertVariant: "success",
				});
			} else if (res.err === 300) {
				setObjAlert({
					title: "Error",
					text: res.message,
					alertVariant: "danger",
				});
			} else {
				const msg = `<small className='text-center'> ${res.message} </small>`;
				setObjAlert({
					title: "Error",
					text: msg,
					alertVariant: "danger",
				});
			}
			setLoading(false);
		});
	};

	return (
		<>
			<PageBreadcrumb actionButtons={[]} pageTitle="Change Password" />
			<Row className="g-3 justify-content-center">
				<Col md={6} lg={4} sm={8}>
					<Form className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
						{show ? <AlertBox alertVariant={objAlert.alertVariant} alertTitle={objAlert.title} alertText={objAlert.text} /> : <></>}
						<Card className="border-0 bg-light">
							<Card.Body>
								<h5>Update Password</h5>
								<Row>
									<Form.Group className="mb-2" controlId="oldPassword">
										<Form.Label>
											Old Password <span className="text-danger">*</span>
										</Form.Label>
										<Form.Control
											type="password"
											{...register("oldPassword", {
												required: "Password is required",
												minLength: {
													value: 8,
													message: "Minimum 8 characters are required",
												},
												pattern: { value: pwdRegex, message: "Password must contain a capital letter , a small letter , number and special character" },
											})}
										/>
										<small className="text-danger">{errors.oldPassword?.message}</small>
									</Form.Group>
									<Form.Group className="mb-2" controlId="newPassword">
										<Form.Label>
											New Password <span className="text-danger">*</span>
										</Form.Label>
										<Form.Control
											type="password"
											{...register("newPassword", {
												required: "Password is required",
												minLength: {
													value: 8,
													message: "Minimum 8 characters are required",
												},
												pattern: { value: pwdRegex, message: "Password must contain a capital letter , a small letter , number and special character" },
											})}
										/>
										<small className="text-danger">{errors.newPassword?.message}</small>
									</Form.Group>

									<Form.Group className="mb-2" controlId="confirmPassword">
										<Form.Label>
											Confirm New Password <span className="text-danger">*</span>
										</Form.Label>
										<Form.Control
											type="password"
											{...register("conPassword", {
												required: "Password is required",
												minLength: {
													value: 8,
													message: "Minimum 8 characters are required",
												},
												pattern: { value: pwdRegex, message: "Password must contain a capital letter , a small letter , number and special character" },
											})}
										/>
										<small className="text-danger">{errors.conPassword?.message ?? validPasswords}</small>
									</Form.Group>
									<div className="mb-2">
										<Form.Text className="text-muted">Passwords must be combination of upper-case letter, lower-case letter, numbers and special characters. Minimum 8 chraters long.</Form.Text>
									</div>
								</Row>
							</Card.Body>
							<Card.Footer>
								<Button disabled={loading ? true : false} type="submit">
									{loading ? <FaCircleNotch className="icon_pulse mx-1" /> : ""}
									Submit
								</Button>
							</Card.Footer>
						</Card>
					</Form>
				</Col>
			</Row>
		</>
	);
};

export default Signup;
