import React, { Fragment, useState, useEffect, useRef } from "react";
import { createRoot, hydrateRoot } from "react-dom/client";
import { Row, Col, Card, Modal, Form, Button, Table } from "react-bootstrap";
import { FaAlignCenter, FaCircleNotch } from "react-icons/fa";
import PageBreadcrumb from "../../components/PageBreadcrumb";
import {
  createTimeSlot,
  fetchTutorSlots,
} from "../../networkcalls/WeekScheduleApi";
import { timeSlots, weekDays } from "../../utils/timeslots";
import { ToastContainer, toast } from "react-toastify";
import { sentenceCase } from "change-case";
import TimeSlotBlock from "./TimeSlotBlock";
import { Provider, useSelector } from "react-redux";
import store from "../../Store/store";

const actionButtons = [];

function TutorSchedule() {
  let userData = useSelector((state) => state.userData.userinfo);
  const tutorid = userData._id;

  const [isLoading, setIsLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [dayNumber, setDayNumber] = useState(0);
  const [dayName, setDayName] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [count, setCount] = useState();
  const isMounted = useRef();

  const mdlTitle = "Remove Time-Slot";
  const mdlText = "Do you want to remove the selected time slot?";
  useEffect(() => {
    //stops two callbacks
    if (isMounted.current) return;
    isMounted.current = true;

    setIsLoading(true);
    fetchDataFillCalendar();
  }, [isMounted]);

  const fetchDataFillCalendar = async () => {
    const eventresult = await fetchTutorSlots({ tutorId: tutorid })
      .then((res) => {
        return res.data;
      })
      .catch((ex) => {
        console.log(ex.message);
      });
    if (eventresult.length > 0) {
      for (var k = 0; k < eventresult.length; k++) {
        const event = eventresult[k];
        const dno = event.dayNumber.toString();
        const dnm = event.dayName.toString();
        const ts = event.timeStart;
        const te = event.timeEnd;
        const eventId = event._id;
        const element = document.querySelector(
          "td[data-day-number='" +
            dno +
            "'][data-day-name='" +
            dnm +
            "'][data-start-time='" +
            ts +
            "'][data-end-time='" +
            te +
            "']"
        );
        if (element) {
          element.innerHTML = "";
          element.setAttribute("data-click", 1);
          //element.render(<TimeSlotBlock dataid={eventId} timeStart={ts} timeEnd={te} />);
          const elementId = element.getAttribute("id");
          const root = createRoot(document.getElementById(elementId));
          root.render(
            <>
              <Provider store={store}>
                <TimeSlotBlock dataId={eventId} timeStart={ts} timeEnd={te} />
              </Provider>
            </>
          );
        }
      }
    }
    setIsLoading(false);
  };

  const Loader = ({ loadingState }) => {
    return loadingState ? (
      <span className="m-2">
        <FaCircleNotch
          className="spin text-primary"
          style={{ fontSize: "1.2rem" }}
        />{" "}
        Loading...
      </span>
    ) : (
      <div></div>
    );
  };

  const handleClick = () => {
    setIsLoading(true);
    fetchDataFillCalendar();
  };

  const Capitalized = ({ word }) => {
    const w = sentenceCase(word);
    return <span>{w}</span>;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (tutorid !== null) {
      const insertData = {
        tutorId: tutorid,
        dayNumber: dayNumber,
        dayName: dayName.toUpperCase(),
        timeStart: startTime,
        timeEnd: endTime,
      };

      const res = await createTimeSlot(insertData)
        .then((result) => {
          return result;
        })
        .catch((err) => {
          toast.error(err.message);
        });

      if (res) {
        if (res.err === 200) {
          toast.success(res.message);
        } else {
          toast.warning(res.message);
        }
        setModalShow(false);
        setTimeout(() => {
          setDayName("");
          setDayNumber("");
          setEndTime("");
          setStartTime("");
          handleClick();
        }, 300);
      }
    }
  };

  const handleTableCellClick = (e) => {
    e.preventDefault();
    const target = e.target;
    const click = target.getAttribute("data-click");
    const dayNm = target.getAttribute("data-day-name");
    const dayNo = target.getAttribute("data-day-number");
    const startTime = target.getAttribute("data-start-time");
    const endTime = target.getAttribute("data-end-time");

    if (click === "0") {
      setDayName(dayNm);
      setDayNumber(dayNo);
      setStartTime(startTime);
      setEndTime(endTime);
      setModalShow(true);
    }
  };

  const handleModalClose = () => {
    setModalShow(false);
  };

  return (
    <Fragment>
      <ToastContainer />
      <PageBreadcrumb pageTitle={"Time Slot"} actionButtons={actionButtons} />
      <Row>
        <Col sm={12}>
          <Card>
            <Card.Body>
              <Button
                variant="primary"
                onClick={handleClick}
                style={{ display: "none" }}
              >
                Click
              </Button>
              <div className="text-center">
                <Loader loadingState={isLoading} />
              </div>
              <div className="table-responsive">
                <Table className="table table-bordered table-responsive">
                  <thead>
                    <tr>
                      <th width="9%"></th>
                      {weekDays.map((weekDay) => (
                        <th
                          width="13%"
                          className="text-center"
                          key={weekDay.key}
                        >
                          <Capitalized word={weekDay.text} />
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {timeSlots.map((timeSlot) => (
                      <tr key={"tr_" + timeSlot.key}>
                        <td
                          style={{
                            verticalAlign: "middle",
                            height: "80px",
                            textAlign: "center",
                          }}
                          key={timeSlot.key}
                        >
                          {timeSlot.ftime}
                        </td>
                        {weekDays.map((weekDay) => (
                          <td
                            style={{ height: "80px" }}
                            key={`${weekDay.key}_${timeSlot.key}`}
                            id={`${weekDay.key}_${timeSlot.key}`}
                            className="time-slot-block"
                            data-day-number={weekDay.key}
                            data-day-name={weekDay.text}
                            data-start-time={timeSlot.ftime}
                            data-end-time={timeSlot.ttime}
                            data-click="0"
                            onClick={(e) => handleTableCellClick(e)}
                          ></td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Modal
        show={modalShow}
        onHide={handleModalClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h4 className="pageTitle">Open Slot</h4>
        </Modal.Header>
        <Modal.Body>
          <Form className="p-3">
            <Row>
              <Col sm={12} className="mb-2 ">
                <Form.Label htmlFor="day">Day</Form.Label>
                <Form.Control
                  readOnly
                  type="text"
                  id="dayName"
                  value={dayName}
                  onChange={() => console.log()}
                />

                <Form.Control
                  readOnly
                  type="hidden"
                  id="dayNumber"
                  value={dayNumber}
                />
              </Col>
              <Col sm={5} className="mb-2 mr-3">
                <Form.Label htmlFor="fromTime">From Time</Form.Label>
                <Form.Control
                  readOnly
                  type="text"
                  id="fromTime"
                  value={startTime}
                />
              </Col>

              <Col sm={5} className="mb-2">
                <Form.Label htmlFor="toTime">To Time</Form.Label>
                <Form.Control
                  readOnly
                  type="text"
                  id="toTime"
                  value={endTime}
                  onChange={() => console.log()}
                />
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={handleModalClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
}

export default TutorSchedule;
