import React from "react";

function MainBody({ children }) {
  return (
    <div className="main-content" style={{ backgroundColor: "#faf8ff" }}>
      {children}
    </div>
  );
}

export default MainBody;
