import React, { useState } from 'react'
import { useEffect } from 'react';
import { FcClock } from 'react-icons/fc'

function MiniCalender({ today = [], tomorrow = [] }) {

    const [isToday, setIsToday] = useState(true);
    const [isTomorrow, setIsTomorrow] = useState(false);
    const handleToday = () => {
        setIsToday(true);
        setIsTomorrow(false);
    }
    const handleTomorrow = () => {
        setIsToday(false);
        setIsTomorrow(true);
    }
    const topics = isToday ? today : tomorrow;

    return (
        <div className="mini-calender bg-blue p-3 rounded-3">
            <div className="d-flex align-items-center justify-content-between">
                <h5 className="fs-5">Mini calender for 20th march, Sunday</h5>
                <div class="btn-group" role="group" aria-label="Basic mixed styles example">
                    <button type="button" onClick={handleToday} class={`btn-sm btn btn-success ${isToday ? "active" : ""}`}>Today</button>
                    <button type="button" onClick={handleTomorrow} class={`btn-sm btn btn-success ${isTomorrow ? "active" : ""}`}>Tomorrow</button>
                </div>
            </div>
            <div className="classes my-3">

                {topics.map((topic, key) => {
                    return <div className="p-2 bg-white mb-3 rounded-3 d-flex align-items-center md-shadow" key={key} >
                        <div className="class-datetime mr-3">
                            <h6 className="my-1 fs-6  text-sm d-flex gap-2 align-items-center"><FcClock className="d-none d-md-block fs-4" /><span >{topic?.tutor_datetime}</span></h6>
                        </div>
                        <div className="grid class-detail w-100">
                            <div className="">
                                <h6 className="fs-6  p-2 text-dark rounded-2">{topic?.student.name}</h6>
                            </div>
                            <div className="">
                                <h6 className="fs-6  p-2 text-dark rounded-2">{topic?.topicname}</h6>
                            </div>
                            <div className="">
                                <h6 className="fs-6  p-2 text-dark rounded-2">SDF45fd</h6>
                            </div>
                            <div className="">
                                <h6 className="fs-6  p-2 text-dark rounded-2">{topic?.booking} booked</h6>
                            </div>
                        </div>
                    </div>
                })}
            </div>
        </div>
    )
}

export default MiniCalender