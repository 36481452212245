import React, { useState, Fragment } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PageBreadcrumb from "../../../components/PageBreadcrumb";
import { useForm } from "react-hook-form";
import { AddCourse } from "../../../networkcalls/CourseApi";
import { InputGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const actionButtons = [
  {
    key: "backButton",
    variant: "outline-dark",
    text: "Back",
    navigateLink: "/dashboard/admin/course/list",
    isLink: true,
  },
];

const CourseAdd = () => {
  let navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [type, setType] = useState("");
  const [coverimage, setImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [price, setPrice] = useState(0);

  //err
  const [titleErr, setTitleErr] = useState();
  const [shortDescriptionErr, setshortDescriptionErr] = useState();
  const [descriptionErr, setDescriptionErr] = useState();
  const [priceErr, setPriceErr] = useState();
  const [typeErr, setTypeErr] = useState();

  const {
    formState: { errors },
  } = useForm();

  const setShortData = (e) => {
    const { value } = e.target;
    setShortDescription(value);
  };

  const setdata = (e) => {
    const { value } = e.target;
    setTitle(value);
  };
  const setdata1 = (e) => {
    const { value } = e.target;
    setDescription(value);
  };

  const setdata2 = (e) => {
    const { value } = e.target;
    setType(value);
  };

  const setdata3 = (e) => {
    setImage(e.target.files[0]);
  };

  const updatePrice = (e) => {
    setPrice(e.target.value);
  };

  const addCourseData = async (e) => {
    e.preventDefault();
    let validate = true;
    if (title?.trim() === undefined || title?.trim() === "") {
      setTitleErr("Please enter title of course");
      validate = false;
    }
    if (
      shortDescription?.trim() === undefined ||
      shortDescription?.trim() === ""
    ) {
      setshortDescriptionErr("Please enter short description of course");
      validate = false;
    }
    if (description?.trim() === undefined || description?.trim() === "") {
      setDescriptionErr("Please enter description of course");
      validate = false;
    }
    if (price === undefined || price === 0) {
      setPriceErr("Please enter course price");
      validate = false;
    }
    if (type === "" || type === undefined) {
      setTypeErr("Please select type of course");
      validate = false;
    }

    if (validate) {
      setTitleErr("");
      setshortDescriptionErr("");
      setDescriptionErr("");
      setPriceErr("");
      setTypeErr("");
      var formData = new FormData();
      formData.append("title", title);
      formData.append("description", description);
      formData.append("shortDescription", shortDescription);
      formData.append("type", type);
      formData.append("coverimage", coverimage);
      formData.append("price", price);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      AddCourse(formData, config)
        .then((result) => {
          if (result.err === 200) {
            setTitle("");
            setDescription("");
            setShortDescription("");
            setPrice("");
            setType("");
            setImage("");
            toast.success(`"${result.data.title}" is added successfully`);
            setLoading(false);
            setTimeout(() => {
              navigate("/dashboard/admin/course/list");
            }, 3000);
          } else {
            toast.error(`Course already created`);
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  };

  const courseForm = () => (
    <div className="row">
      <form className="col-md-6 offset-md-3">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">Add Course</h5>
            <div className="col-12">
              <div className="col-sm-12 mb-3">
                <label>
                  Course Title <b className="text-danger">*</b>
                </label>
                <input
                  required
                  type="text"
                  className="form-control"
                  name="title"
                  onChange={setdata}
                />

                <span className="text-danger">{titleErr}</span>
              </div>
              <div className="col-sm-12 mb-3">
                <label>
                  Short Description <b className="text-danger">*</b>
                </label>
                <textarea
                  required
                  className="form-control"
                  rows="1"
                  name="shortDescription"
                  onChange={setShortData}
                ></textarea>

                <span className="text-danger">{shortDescriptionErr}</span>
              </div>
              <div className="col-sm-12 mb-3">
                <label>
                  Detailed Description <b className="text-danger">*</b>
                </label>
                <textarea
                  required
                  className="form-control"
                  rows="3"
                  name="description"
                  onChange={setdata1}
                ></textarea>

                <span className="text-danger">{descriptionErr}</span>
              </div>
              <div className="col-sm-12 mb-3">
                <label>
                  Course Price <b className="text-danger">*</b>
                </label>
                <InputGroup className="col-sm-8">
                  <InputGroup.Text>₹</InputGroup.Text>
                  <input
                    required
                    type="number"
                    className="form-control"
                    rows="3"
                    name="price"
                    onChange={updatePrice}
                  />
                </InputGroup>
                <span className="text-danger">{priceErr}</span>
              </div>
              <div className="col-sm-12 mb-3">
                <label>
                  Course Type <b className="text-danger">*</b>
                </label>
                <select className="form-select" name="type" onChange={setdata2}>
                  <option value="">Select Type</option>
                  <option value="Basic">Basic</option>
                  <option value="Intermidiate">Intermidiate</option>
                  <option value="Expert">Expert</option>
                  <option value="All">All</option>
                </select>

                <span className="text-danger">{typeErr}</span>
              </div>
              <div className="col-sm-12 mb-3">
                <label>Cover Image</label>
                <input
                  required
                  type="file"
                  className="form-control"
                  name="coverimage"
                  onChange={setdata3}
                />
              </div>
            </div>
          </div>
          <div className="card-footer text-right">
            <button
              className="btn btn-success"
              type="submit"
              onClick={addCourseData}
            >
              {loading ? "Please wait..." : "Submit"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );

  return (
    <Fragment>
      <ToastContainer />
      <PageBreadcrumb pageTitle={"Course"} actionButtons={actionButtons} />
      {courseForm()}
    </Fragment>
  );
};

export default CourseAdd;
