import React, { Fragment } from "react";
import { useState } from "react";
import { FcClock } from "react-icons/fc";
import MiniCalender from "./MiniCalender";

const classes = {
	upcoming: {
		tutor_datetime: "30Aug, Sunday 3:30 AM - 4:30 AM",
		topic_name: "Mathmatics",
		student: {
			name: "Harsh chavan",
			country: "India",
			contact_no: "+91-9878765665",
			datetime: "Aug 3:30AM - 4:30AM",
		},
		booking: "Paid",
	},
	today: [
		{
			tutor_datetime: "30Aug, Sunday 3:30 AM - 4:30 AM",
			student: {
				name: "Harsh chavan",
				country: "India",
				contact_no: "+91-9878765665",
				datetime: "Aug 3:30AM - 4:30AM",
			},
			topicname: "100 days of code",
			booking: "Paid",
		},
		{
			tutor_datetime: "30Aug, Sunday 3:30 AM - 4:30 AM",
			student: {
				name: "Harsh chavan",
				country: "India",
				contact_no: "+91-9878765665",
				datetime: "Aug 3:30AM - 4:30AM",
			},

			topicname: "Express Js",
			booking: "Trial",
		}
	],
	tomorrow: [
		{
			tutor_datetime: "30Aug, Sunday 3:30 AM - 4:30 AM",
			student: {
				name: "John doe",
				country: "India",
				contact_no: "+91-9878765665",
				datetime: "Aug 3:30AM - 4:30AM",
			},
			topicname: "Complete Laravel",

			booking: "Paid",
		},
		{
			tutor_datetime: "30Aug, Sunday 3:30 AM - 4:30 AM",
			student: {
				name: "Harsh chavan",
				country: "India",
				contact_no: "+91-9878765665",
				datetime: "Aug 3:30AM - 4:30AM",
			},

			topicname: "Science",
			booking: "Trial",
		}
	]
}
function TutorDashboard() {


	return (
		<div className="tutor">
			<div className="d-flex-2 upcomingclass-notifications my-5">
				<div className="first-col p-3 rounded-3 bg-blue position-relative">
					<h5 className="fs-5 mb-3">Upcoming class</h5>
					<div className="position-absolute booking-status fw-bold">
						{classes.upcoming?.booking}
					</div>
					<div className="bg-white p-2 mb-3 rounded-3">
						<h6 className="fs-6">{classes.upcoming?.topic_name}</h6>
						<h6 className="my-1 fs-6 text-muted-blue text-sm d-flex gap-2 align-items-center"><FcClock /><span >{classes.upcoming?.tutor_datetime}</span></h6>
					</div>
					<div className="pt-2 pl-2 pr-2 row">
						<div className="col">
							<h6 className="fs-6">{classes.upcoming?.student.name}</h6>
							<h6 className=" text-sm text-muted-blue">{classes.upcoming?.student.country}</h6>
							<h6 className=" text-sm text-muted-blue">{classes.upcoming?.student.contact_no}</h6>
						</div>
						<div className="col text-right">
							<h6 className="fs-6">Time for student</h6>
							<h6 className="text-sm text-muted-blue">{classes.upcoming?.student.datetime}</h6>
						</div>
						<div className="mt-3 p-2 rounded-3">
							<h6 className="fs-6 text-sm text-center">You can enter class at {classes.upcoming.tutor_datetime
							} </h6>
						</div>
						<button className="card-blue-btn btn">START CLASS</button>
					</div>
				</div>
				<div className="p-3 second-col rounded-3 bg-green">
					<h5 className="fs-5 mb-2">Notifications</h5>
				</div>
			</div>
			<MiniCalender today={classes.today} tomorrow={classes.tomorrow} />
		</div>
	);
}

export default TutorDashboard;
