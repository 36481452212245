import React from "react";
import { useParams, Link, useLocation, useNavigate } from "react-router-dom";
import { BiRupee } from "react-icons/bi";
import { courses } from "./courses";
import HeadText from "../HeadText";
import { FcClock } from "react-icons/fc";
import { getCourse } from "../../networkcalls/CourseApi";
import { useState } from "react";
import { useEffect } from "react";
import { fetchallcoursedetails } from "../../networkcalls/courseTutorApi";
import strings from "../../utils/strings";
import swal from "sweetalert";
import {
  coursePurchased,
  fetchCoursePurchasedByStudent,
} from "../../networkcalls/purchasedCourse";
import { useSelector } from "react-redux";

function StudentCourseDetail() {
  const [data, setData] = useState();
  const { state } = useLocation();
  const { id } = state;

  const [selectedTutor, setSelectedTutor] = useState(false);

  let userData = useSelector((state) => state.userData.userinfo);

  let navigate = useNavigate();
  useEffect(() => {
    let postData = { studentId: userData._id, paymentStatus: "success" };
    fetchallcoursedetails(id, postData).then(
      (res) => {
        setData(res.data);
      },
      (err) => {
        console.log(err);
      }
    );
  }, [id]);

  const clickedTutor = (tutorId) => {
    console.log(tutorId);
    localStorage.setItem("selectedTutor", tutorId);
    const boxes = document.querySelectorAll("button.trbtn");

    boxes.forEach((box) => {
      box.classList.remove("btn-selected");
    });

    document
      .querySelector("button[id='" + tutorId + "']")
      .classList.add("btn-selected");
  };

  let syllabusId = data?.syllabus.map((sdata) => {
    return sdata._id;
  });
  let syllabusTitle = data?.syllabus.map((sdata) => {
    return sdata.topicName;
  });
  let syllabusDesc = data?.syllabus.map((sdata) => {
    return sdata.description;
  });

  const handelBuy = () => {
    let tutorId = localStorage.getItem("selectedTutor");
    if (tutorId === null) {
      swal({
        title: "Please select Tutor!",
        text: "Please select tutor!",
        icon: "warning",
        button: "ok!",
      });
    } else {
      let coursePurchaseDetails = {
        courseId: data.courseId,
        tutorId: tutorId,
        studentId: userData._id,
        coursePrice: data.price,
      };

      coursePurchased(coursePurchaseDetails).then(
        (res) => {
          console.log(res);
          if (res.err === 200) {
            swal({
              title: "Course Purchased Successfully!",
              text: "Course Purchased Successfully",
              icon: "success",
              button: "ok!",
            }).then(window.location.reload());
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 mt-3">
            <div className="card " style={{ border: "none" }}>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-7">
                    <h5 className="text-start">{data?.title}</h5>
                    <br />
                    <div className="mb-2">
                      <div className="row">
                        <div className="col-md-6">
                          <img
                            className="rounded me-auto img-fluid border"
                            src={
                              data?.courseImage
                                ? strings.IMG_URL +
                                "courses/" +
                                data?.courseImage
                                : "https://leverageedublog.s3.ap-south-1.amazonaws.com/blog/wp-content/uploads/2020/04/01170800/Free-Online-Courses-with-Certificates.jpg"
                            }
                            alt="courseimage"
                          />
                        </div>
                        <div className="col-md-6"></div>
                      </div>

                      <br />
                    </div>
                    <div className="mt-4">
                      <h6 className="text-start">Information</h6>
                      <p className="text-start text-sm mt-2">
                        {data?.shortdescription}.
                        <br />
                        <br />
                      </p>
                      <div className="row">
                        <div className="col-md-6 col-6">
                          <h4>Rs {data?.price}/-</h4>
                        </div>
                        <div className="col-md-6 col-6 text-right">
                          {data?.alreadyBought === 0 ? (
                            <button
                              className="btn btn-primary"
                              onClick={(e) => handelBuy(e)}
                            >
                              Buy
                            </button>
                          ) : (
                            <p>You Have Already bought</p>
                          )}{" "}
                        </div>
                      </div>
                    </div>
                    <h6 className="text-start mt-4">Details</h6>
                    <p className="text-start text-sm">{data?.description}</p>
                  </div>
                  <div className="col-md-5 ">
                    {data?.alreadyBought === 0 ? (
                      <>
                        <h5 className="text-start mt-2 mb-2">Tutors</h5>
                        <div>
                          {data?.tutor.map((tdata) => {
                            return (
                              <div className="d-flex bg-dim-grey p-2">
                                <img
                                  className="rnd-img-sm ml-3"
                                  src={
                                    strings.IMG_URL +
                                    "users/" +
                                    tdata?.tutorPicture
                                  }
                                  alt={tdata?.tutorName}
                                />

                                <div className="ml-3">
                                  <b className="text-secondary">
                                    {tdata.tutorName}
                                  </b>

                                  <p>{tdata.tutorEmail}</p>
                                  <button
                                    type="button"
                                    id={tdata.tutorId}
                                    className={
                                      "trbtn btn-outline-primary btn btn-sm mt-2 mb-2"
                                    }
                                    onClick={(e) => clickedTutor(tdata.tutorId)}
                                  >
                                    select
                                  </button>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    <h5 className="mt-4">Syllabus</h5>
                    <ul className="list-group mt-3 ">
                      {data?.syllabus.map((sdata) => {
                        return (
                          <li className="list-group-item back-color mb-2">
                            <h6 className="mt-3">
                              {" "}
                              <b>{sdata.topicName}</b>
                            </h6>
                            <p className="text-sm">
                              {sdata.description
                                ? sdata.description
                                : "description"}
                            </p>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
}

export default StudentCourseDetail;
