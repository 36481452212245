import React, { Fragment, useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useCookies } from "react-cookie";
import DataTable from "react-data-table-component";
import { FaEdit, FaEye, FaTrash } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import swal from "sweetalert";
import {
  deleteCourseTutor,
  fetchcourseTutor,
} from "../../../networkcalls/courseTutorApi";
import PageBreadcrumb from "../../PageBreadcrumb";

const CourseTutorList = () => {
  const actionButtons = [];
  const [cookie, setCookie] = useCookies(["user"]);
  const [data, setData] = useState();
  let navigate = useNavigate();

  const role = "tutor";

  const fetchlist = () => {
    fetchcourseTutor()
      .then((res) => {
        setData(res.data.data);
      })
      .catch((err) => {
        // console.log(err.message);
      });
  };

  useEffect(() => {
    fetchlist();
  }, []);

  const handleDelete = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteCourseTutor(id).then(
          (res) => {
            if (res.data.err === 200) {
              toast.success("deleted successful");
              fetchlist();
            }
          },
          (err) => {
            toast.error("Error occured");
          }
        );
      } else {
        toast.success("Your rescord is safe!");
      }
    });
  };

  const columns = [
    {
      name: "Course Title",
      selector: (row) => row.courseTitle,
      sortable: true,
    },
    {
      name: "Tutor Name",
      selector: (row) => row.tutorName,
    },
    {
      name: "Email",
      selector: (row) => row.tutorEmail,
    },
    {
      name: "Mobile",
      selector: (row) => row.tutorMobile,
    },
    {
      name: "IsActive",
      cell: (row) => [
        row.isActive === 0 ? (
          <span className="badge p-2 bg-danger">inActive</span>
        ) : (
          <span className="badge p-2 bg-success">Active</span>
        ),
      ],
    },
    {
      name: "Action",
      cell: (row) => [
        <button
          className="btn btn-outline-primary mx-1 btn-sm"
          onClick={(e) =>
            navigate(`/dashboard/admin/coursetutor/edit`, {
              state: {
                id: row._id,
                tutorId: row.tutorId,
                courseId: row.courseId,
                tutorName: row.tutorName,
                courseTitle: row.courseTitle,
                isActive: row.isActive,
              },
            })
          }
        >
          <FaEdit />
        </button>,
        <button
          className="btn btn-outline-danger mx-1 btn-sm"
          onClick={() => handleDelete(row._id)}
        >
          <FaTrash />
        </button>,
      ],
    },
  ];

  return (
    <div>
      <div className="container-fluid mt-3">
        <ToastContainer />
        <div className="row">
          <div className="col-lg-6">
            <h4>Course Tutor List</h4>
          </div>
          <div className="col-lg-6 text-right">
            <Link to={`/dashboard/admin/coursetutor/add`}>
              <button className="btn btn-primary btn-sm">Add</button>
            </Link>
          </div>
        </div>
      </div>

      <Fragment>
        <PageBreadcrumb
          // pageTitle={"Course Tutor List"}
          actionButtons={actionButtons}
        />

        <Row>
          <Col sm={12}>
            <Card>
              <Card.Body>
                <DataTable
                  columns={columns}
                  data={data}
                  pagination
                  fixedHeader
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Fragment>
    </div>
  );
};

export default CourseTutorList;
