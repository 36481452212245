import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Card, Stack } from "react-bootstrap";
import PageBreadcrumb from "../../../components/PageBreadcrumb";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import { useParams } from "react-router-dom";
import {
  getAllSyllabus,
  getSyllabus,
  deleteSyllabus,
  AddSyllabus,
  updateSyllabus,
} from "../../../networkcalls/SyllabusApi";
import { FaEdit, FaTrash } from "react-icons/fa";
import { set, useForm } from "react-hook-form";
import swal from "sweetalert";

const actionButtons = [
  {
    key: "backButton",
    variant: "outline-dark",
    text: "Back",
    navigateLink: "/dashboard/admin/course/list",
    isLink: true,
  },
];

function SyllabusList() {
  const params = useParams();
  const courseId = params.id;
  const [loading, setLoading] = useState(false);
  const [formHeader, setFormHeader] = useState("New Topic");
  const [syllabus, setSyllabus] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  useEffect(() => {
    fetchSyllabus();
  }, [loading]);

  const handleRemove = async (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this syllabus!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setLoading(true);
        deleteSyllabus(id)
          .then((result) => {
            setLoading(false);
            toast.success(
              `"${result.syllabus.topicName}" is deleted successfully`
            );
          })
          .catch((err) => {
            console.log(err.message);
          });
      }
    });
  };

  const fetchSyllabus = async () => {
    await getAllSyllabus(courseId)
      .then((result) => {
        setSyllabus(result.data);
      })
      .catch((ex) => {
        console.log(ex);
      });
  };

  const onSubmit = (data) => {
    setLoading(true);

    if (data.syllabusId !== "" && data.syllabusId !== undefined) {
      updateSyllabus(data)
        .then((result) => {
          setSyllabus([]);
          toast.success(`${result.message}`);
          setTimeout(() => {
            reset();
            setLoading(false);
            setFormHeader("New Topic");
            fetchSyllabus();
          }, 300);
        })
        .catch((err) => {
          console.log(err.message);
          toast.error("Failed to update syllabus topic");
          setFormHeader("New Topic");
        });
    } else {
      AddSyllabus(data)
        .then((result) => {
          console.log(result);
          reset();
          toast.success(`${result.message}`);
          setTimeout(() => {
            setLoading(false);
            setFormHeader("New Topic");
            setSyllabus([]);
            fetchSyllabus();
          }, 300);
        })
        .catch((err) => {
          toast.error("Failed to add syllabus topic");
          setFormHeader("New Topic");
        });
    }
  };

  const handleSyllabus = async (syllabusId) => {
    await getSyllabus(syllabusId)
      .then((result) => {
        if (result.data) {
          const data = result.data;
          setValue("topicName", data.topicName);
          setValue("syllabusId", data._id);
          setValue("description", data.description);
          setFormHeader("Update Topic");
        }
      })
      .catch((ex) => {
        console.log(ex.message);
      });
  };

  const columns = [
    {
      name: "Class Code",
      selector: (row) => row.syllabusId,
    },
    {
      name: "Topic Name",
      selector: (row) => row.topicName,
    },
    {
      name: "Actions",
      cell: (row) => [
        <button
          type="button"
          className="btn btn-outline-primary mx-1 btn-sm"
          onClick={() => handleSyllabus(row._id)}
          key={"edit" + row._id}
        >
          <FaEdit />
        </button>,
        <button
          type="button"
          className="btn btn-outline-danger mx-1 btn-sm"
          onClick={() => handleRemove(row._id)}
          key={"delete" + row._id}
        >
          <FaTrash />
        </button>,
      ],
    },
  ];

  return (
    <Fragment>
      <PageBreadcrumb pageTitle={"Syllabus"} actionButtons={actionButtons} />
      <Row>
        <Col lg={5} xl={4}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Card>
              <Card.Header>
                <h5>{formHeader}</h5>
              </Card.Header>
              <Card.Body>
                <Stack gap={3}>
                  <div>
                    <label>
                      Topic Name <b className="text-danger">*</b>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="topicName"
                      {...register("topicName", {
                        required: "Topic Name is required",
                      })}
                    />
                    <br />
                    <input
                      hidden={true}
                      type="text"
                      {...register("courseId", {
                        required: "Course is required",
                        value: courseId,
                      })}
                    />
                    <input
                      hidden={true}
                      type="text"
                      name="syllabusId"
                      {...register("syllabusId")}
                    />
                    {errors.course && (
                      <span className="text-danger">
                        {errors.course.message}
                      </span>
                    )}
                  </div>
                  <div>
                    <label>
                      Description <b className="text-danger">*</b>
                    </label>
                    <textarea
                      type="text"
                      className="form-control"
                      name="description"
                      {...register("description", {
                        required: "Topic description is required",
                      })}
                    />
                  </div>
                </Stack>
              </Card.Body>
              <Card.Footer>
                <button className="btn btn-success w-100">
                  {loading ? "Please wait..." : "Submit"}
                </button>
              </Card.Footer>
            </Card>
          </form>
        </Col>

        <Col lg={7} xl={8}>
          <Card>
            <Card.Body>
              <DataTable
                columns={columns}
                data={syllabus}
                pagination
                fixedHeader
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <ToastContainer />
    </Fragment>
  );
}

export default SyllabusList;
