import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fetchTutor } from "../../../networkcalls/UsersApi";
import userImage from "../../../images/R.jfif";
import strings from "../../../utils/strings";
import { UpdateTutorProfile } from "../../../networkcalls/UsersApi";
import { useNavigate, useParams } from "react-router-dom";

function TutorEdit() {
  const [cookie, setCookie] = useCookies(["user"]);
  const [fnameErrorMessage, setFnameErrorMessage] = useState();
  const [lnameErrorMessage, setLnameErrorMessage] = useState();
  const [genderErrorMessage, setGenderErrorMessage] = useState();
  const [emailErrorMessage, setEmailErrorMessage] = useState();
  const [phoneErrorMessage, setPhoneErrorMessage] = useState();
  const [passwordErrorMessage, setPasswordErrorMessage] = useState();
  const [cpasswordErrorMessage, setCpasswordErrorMessage] = useState();
  const [imageErrorMessage, setImageErrorMessage] = useState();
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  //profilePic hook to change state of image
  const [profilePic, setProfilePic] = useState(userImage);
  //profileImage hook to store image in database
  const [profileImage, setProfileImage] = useState();
  const navigate = useNavigate();

  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    gender: "",
    dob: "",
    email: "",
    phone: "",
    linkedinUrl: "",
    youtubeUrl: "",
    instagramUrl: "",
    facebookUrl: "",
    about: "",
  });
  const { tutorid } = useParams();

  const setImage = (e) => {
    const file = e.target.files[0];
    if ((file.type.slice(6) === "png") | "jpg" | "jpeg") {
      setImageErrorMessage("");
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (reader.result != null) {
          setProfilePic(reader.result.toString());
        }
      };
      setProfileImage(e.target.files[0]);
    } else {
      setImageErrorMessage("Please select image type of pn, jp, jpeg");
    }
  };

  function handleChange(e) {
    e.preventDefault();
    e.preventDefault();
    if (!/^[a-zA-Z ]*$/.test(data.firstName)) {
      setFnameErrorMessage("Only alphabets are allowed!");
    }
    if (!/^[a-zA-Z ]*$/.test(data.lastName)) {
      setLnameErrorMessage("Only alphabets are allowed!");
    }
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
  }

  function onUpdate(e) {
    e.preventDefault();

    let validate = true;

    if (data.firstName.trim() === "") {
      setFnameErrorMessage("Please Enter First Name");
    }

    if (data.lastName.trim() === "") {
      setLnameErrorMessage("Please Enter Last Name");
    }
    if (data.gender === "") {
      setGenderErrorMessage("Please select Gender");
    }
    if (data.phone === "") {
      setPhoneErrorMessage("Please Enter Phone No.");
    }
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(data.email)) {
      setEmailErrorMessage("Please Enter valid Email");
      validate = false;
    }

    if (
      !/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(data.phone)
    ) {
      setPhoneErrorMessage("Please Enter Valid Phone No!");
    }

    if (validate) {
      var formData = new FormData();
      formData.append("firstName", data.firstName);
      formData.append("lastName", data.lastName);
      formData.append("gender", data.gender);
      formData.append("dob", data.dob);
      formData.append("email", data.email);
      formData.append("phone", data.phone);
      formData.append("profilePic", profileImage);
      formData.append("linkedinUrl", data.linkedinUrl);
      formData.append("youtubeUrl", data.youtubeUrl);
      formData.append("instagramUrl", data.instagramUrl);
      formData.append("facebookUrl", data.facebookUrl);
      formData.append("about", data.about);

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      UpdateTutorProfile(tutorid, formData, config).then((res) => {
        if (res.err === 200) {
          toast.success("Profile Updated Successfully!");
          setTimeout(() => {
            navigate("/dashboard/admin/tutor/list");
          }, 3000);
        } else {
          toast.error("Network Error");
        }
      });
    }
  }

  useEffect(() => {
    fetchTutor(tutorid).then(
      (res) => {
        setData(res.data);
        console.log(res.data);
        if (res.data.profilePic !== null) {
          setProfilePic(strings.IMG_URL + "users/" + res.data.profilePic);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }, [cookie["user"]]);
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 card mt-5">
            <h5 className="text-center mt-5">
              {data?.firstName} {data?.lastName}
            </h5>
            <form action="" className="form-group">
              <lable>First Name</lable>
              <input
                id="firstName"
                type="text"
                value={data.firstName}
                className="form-control"
                onChange={(e) => handleChange(e)}
                placeholder="Enter First Name"
                autoComplete="off"
              />
              <span className="text-danger">{fnameErrorMessage}</span>
              <br />
              <lable>Last Name</lable>
              <input
                id="lastName"
                type="text"
                value={data.lastName}
                className="form-control"
                onChange={(e) => handleChange(e)}
                placeholder="EnterLast Name"
                autoComplete="off"
              />
              <span className="text-danger">{lnameErrorMessage}</span>
              <br />
              <lable>Gender</lable>
              {/* <div>
                <input
                  type="radio"
                  value="male"
                  checked={data.gender === "male"}
                  onChange={(e) => handleChange(e)}
                  id="gender"
                />
                Male
              </div>
              <div>
                <input
                  type="radio"
                  value="female"
                  checked={data.gender === "female"}
                  onChange={(e) => handleChange(e)}
                  id="gender"
                />{" "}
                Female
              </div>
              <div>
                <input
                  type="radio"
                  value="transgender"
                  checked={data.gender === "transgender"}
                  onChange={(e) => handleChange(e)}
                  id="gender"
                />{" "}
                Transgender
              </div> */}
              <select
                className="form-control form-control-sm"
                value={data.gender}
                onChange={(e) => handleChange(e)}
                id="gender"
              >
                <option selsected>select</option>
                <option value="male" selsected>
                  Male
                </option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>
              <span className="text-danger">{genderErrorMessage}</span>
              <br />
              <lable>DOB</lable>
              <input
                id="dob"
                type="date"
                className="form-control text-box single-line"
                value={data.dob?.slice(0, 10)}
                onChange={(e) => handleChange(e)}
                autoComplete="off"
              />{" "}
              <br />
              <lable>Email</lable>
              <input
                id="email"
                type="text"
                className="form-control"
                value={data.email}
                placeholder="Enter Email address"
                onChange={(e) => handleChange(e)}
                autoComplete="off"
              />
              <span className="text-danger">{emailErrorMessage}</span>
              <br />
              <lable>Phone</lable>
              <input
                id="phone"
                type="number"
                value={data.phone}
                onChange={(e) => handleChange(e)}
                className="form-control"
                placeholder="Enter Phone No."
                autoComplete="off"
              />
              <span className="text-danger">{phoneErrorMessage}</span>
              <br />
              {/* Social links */}
              <lable>Linked In</lable>
              <input
                id="linkedinUrl"
                type="text"
                value={data.linkedinUrl}
                onChange={(e) => handleChange(e)}
                className="form-control"
                placeholder="Enter Linkdin url"
                autoComplete="off"
              />
              <br />
              <lable>Youtube In</lable>
              <input
                id="youtubeUrl"
                type="text"
                value={data.youtubeUrl}
                onChange={(e) => handleChange(e)}
                className="form-control"
                placeholder="Enter Youtube url"
                autoComplete="off"
              />
              <br />
              <lable>Instagram Url</lable>
              <input
                id="instagramUrl"
                type="text"
                value={data.instagramUrl}
                onChange={(e) => handleChange(e)}
                className="form-control"
                placeholder="Enter Insta url"
                autoComplete="off"
              />
              <br />
              <lable>Facebook Url</lable>
              <input
                id="facebookUrl"
                type="text"
                value={data.facebookUrl}
                onChange={(e) => handleChange(e)}
                className="form-control"
                placeholder="Enter Facebook url"
                autoComplete="off"
              />
              <br />
              {/* About */}
              <label>About</label>
              <textarea
                id="about"
                value={data.about}
                onChange={(e) => {
                  handleChange(e);
                }}
                className="form-control"
              ></textarea>{" "}
              <br />
              <lable>Profile Pic</lable>&nbsp;
              <br />
              <br />
              <img
                src={profilePic}
                alt="profile pic"
                style={{ width: "150px", height: "150px" }}
              />
              <br />
              <br />
              {/* Profile Pic */}
              <input
                id="profilePic"
                onChange={setImage}
                type="file"
                className="form-control"
                placeholder="choose file"
                accept="image/*"
              />
              <span className="text-danger">{imageErrorMessage}</span>
              {/* submit */}
              <div className=" mb-5 mt-5">
                <input
                  id=""
                  type="button"
                  className="btn btn-primary text-center"
                  onClick={(e) => {
                    onUpdate(e);
                  }}
                  value="Update"
                />
                <ToastContainer />
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default TutorEdit;
