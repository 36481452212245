import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Card } from "react-bootstrap";
import PageBreadcrumb from "../../../components/PageBreadcrumb";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useParams, Link } from "react-router-dom";
import { getAllCourse, deleteCourse } from "../../../networkcalls/CourseApi";
import { FaSearch, FaEdit, FaEye, FaTrash, FaImage } from "react-icons/fa";
import swal from "sweetalert";

const actionButtons = [
  {
    key: "newCourseBtn",
    variant: "primary",
    text: "New Course",
    navigateLink: "/dashboard/admin/course/add",
    isLink: true,
  },
];

function CourseList() {
  const [loading, setLoading] = useState(false);
  const [course, setCourse] = useState([]);
  const [filterCourse, setFilterCourse] = useState([]);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();

  const handleRemove = async (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this course!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteCourse(id)
          .then((result) => {
            swal("Your Course has been deleted!", "success", "success");
            setLoading(!loading);
            navigate("/dashboard/admin/course/list");
          })
          .catch((err) => {
            console.log(err.message);
          });
      } else {
        swal("Your Course is Safe!");
      }
    });
  };

  useEffect(() => {
    getAllCourse()
      .then((c) => {
        console.log(c);
        setCourse(c.data);
        setFilterCourse(c.data);
      })
      .catch((err) => {});
  }, [loading]);

  const columns = [
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
    },

    {
      name: "Cover Image",
      selector: (row) => {
        return row.image !== "" ? (
          <div className="tblimg">
            <img
              src={row.image}
              style={{ height: "50px", width: "50px" }}
              alt={row.title}
            />
          </div>
        ) : (
          <FaImage />
        );
      },
    },
    {
      name: "Type",
      selector: (row) => row.type,
    },
    {
      name: "Syllabus",
      selector: (row) => (
        <Link
          to={`/dashboard/admin/course/${row._id}/syllabus`}
          className="d-inline-block w-100"
        >
          <button
            type="button"
            title="Visit Syllabus"
            className="btn btn-primary btn-sm"
          >
            Visit
          </button>
        </Link>
      ),
    },
    {
      name: "Action",
      cell: (row) => [
        <Link to={`/dashboard/admin/course/view/${row._id}`}>
          <button className="btn btn-outline-success mx-1 btn-sm">
            <FaEye />
          </button>
        </Link>,
        <Link to={`/dashboard/admin/course/edit/${row._id}`}>
          <button className="btn btn-outline-primary mx-1 btn-sm">
            <FaEdit />
          </button>
        </Link>,
        <button
          className="btn btn-outline-danger mx-1 btn-sm"
          onClick={() => handleRemove(row._id)}
        >
          <FaTrash />
        </button>,
      ],
    },
  ];

  useEffect(() => {
    const result = course.filter((c) => {
      return c.title.toLowerCase().match(search.toLowerCase());
    });
    setFilterCourse(result);
  }, [search, course]);

  return (
    <Fragment>
      <PageBreadcrumb pageTitle={"Courses"} actionButtons={actionButtons} />
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-body">
              <DataTable
                columns={columns}
                data={filterCourse}
                pagination
                highlightOnHover
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default CourseList;
